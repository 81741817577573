import React, {KeyboardEvent, KeyboardEventHandler, useCallback, useImperativeHandle, useMemo, useState} from 'react';
import {SearchOutlined} from '@ant-design/icons';
import {Button, Input, DatePicker, Select} from 'antd';
import {SizeType} from 'antd/lib/config-provider/SizeContext';
import {filterOption, getRandomId} from 'utils/utils';
import {FilterFieldsConfig, IFilterFieldConfig} from './types';

import styles from './styles.module.scss';
import {type} from "@testing-library/user-event/dist/type";
import {NUMBER_REGEX} from "../../../utils/constants";
import {Dayjs} from "dayjs";

export interface FilterValuesType {
    [key: string]: string | boolean | string[] | Dayjs | [Dayjs, Dayjs];
}

interface IFilterFormProps {
    defaultFilterValues?: Record<string, string | string[] | number[]>;
    filters: FilterFieldsConfig;
    extraControls?: React.ReactNode[];
    onChangeFilters: (selectedFilters: any) => void;
    onSearchBtnClick: (selectedFilters: any) => void
    size?: 'small' | 'default';
}

const clearEmptyValues = (v: FilterValuesType) => {
    const nonEmptyFilters: FilterValuesType = {};
    Object.entries(v).forEach(([filterName, filterValue]) => {
        const valueString = String(filterValue);
        if (typeof filterValue === 'boolean'
            // @ts-ignore
            || (Array.isArray(filterValue) && filterValue.filter((item) => !!item).length)
            || !!filterValue) {
            nonEmptyFilters[filterName as string] = filterValue;
        }
    });

    return nonEmptyFilters;
}


const FilterForm = React.forwardRef((props: IFilterFormProps, ref): JSX.Element => {
    const {
        onChangeFilters,
        filters,
        onSearchBtnClick,
        size = 'default',
        extraControls = []
    } = props;

    // @ts-ignore
    const [filterValues, setFilterValues] = useState<FilterValuesType>(() => {
        return ({
            ...(props.defaultFilterValues || {})
        })
    });

    const clearValues = () => {
        setFilterValues({});
        onChangeFilters({});
    }

    const setExternalFilterValues = (newFilters: any) => {
        setFilterValues({...newFilters});
        if (onChangeFilters) {
            onChangeFilters({...newFilters});
        }

    }

    const getSelectedFilters = () => {
        return clearEmptyValues(filterValues);
    }

    const filtersChangeId = useMemo(() => getRandomId(), [JSON.stringify(filterValues)] );

    const onSearchBtnClickFn = useCallback(() => {
        const nonEmptyFilters = clearEmptyValues(filterValues);
        onSearchBtnClick(nonEmptyFilters);
    }, [filtersChangeId, onSearchBtnClick]);

    const onChangeFilter = useCallback((changedObj: any) => {
        const newFilters = {
            ...filterValues,
            ...changedObj
        };

        setFilterValues(newFilters);

        const nonEmptyFilters = clearEmptyValues(newFilters);
        onChangeFilters(nonEmptyFilters);
    }, [filtersChangeId, setFilterValues, onChangeFilters]);

    const onKeyUp = useCallback((ev: any) => {
        ev.stopPropagation();
        const pressedKeyCode = String(ev.key || ev.code);

        const isEnterKeyPressed =
            pressedKeyCode === '13' || pressedKeyCode.toLowerCase() === 'enter';

        if (isEnterKeyPressed) {
            onSearchBtnClickFn();
        }
    }, [onSearchBtnClickFn]);

    const renderField = useCallback((props: IFilterFieldConfig) => {
        const {name, title, type = 'input', options = [], getChangeAdditionalParams, mode, placeholder} = props;
        let input = null;
        if (type === 'date-range') {
            input = <DatePicker.RangePicker
                size={size as SizeType}
                allowClear
                placeholder={['c', 'по']}
                value={filterValues[name] as [Dayjs, Dayjs]}
                onChange={(dates) => {
                    onChangeFilter({[name]: dates});
                }}
            />
        } else if (type === 'date') {
            input = <DatePicker
                allowClear
                size={size as SizeType}
                placeholder={title}
                value={filterValues[name] as Dayjs}
                onChange={(dates) => {
                    onChangeFilter({[name]: dates});
                }}
            />
        } else if (type === 'select') {
            input = <Select
                size={size as SizeType}
                // @ts-ignore
                mode={mode}
                showArrow
                allowClear
                popupMatchSelectWidth={false}
                placeholder={placeholder || title}
                filterOption={filterOption}
                value={filterValues[name]}
                onChange={(selectedOption, options) => {
                    let changeObject = {
                        [name]: selectedOption
                    }
                    if (getChangeAdditionalParams) {
                        changeObject = {
                            ...changeObject,
                            ...(getChangeAdditionalParams(options) || {})
                        }
                    }

                    onChangeFilter(changeObject);
                }}
            >{options}</Select>
        } else if (type === 'input') {
            input = <Input
                size={size as SizeType}
                placeholder={title}
                // className={styles.full_name}
                allowClear
                value={filterValues[name] as string}
                onChange={({target: {value}}) => {
                    onChangeFilter({[name]: value});
                }}
            />
        }

        return (
            <div
                key={name}
                className={`${styles.field} ${name} filter-field`}
            >
                {/*<div className={styles.field_label}>{title}</div>*/}
                {input}
            </div>
        )
    }, [JSON.stringify(filterValues)]);

    useImperativeHandle(ref, () => ({
        clearValues,
        getSelectedFilters,
        setFilterValues: setExternalFilterValues,
    }), [clearValues, getSelectedFilters, setExternalFilterValues]);

    return (
        <div className={`${styles.filter_form} filter-form`} onKeyUp={onKeyUp}
        >
            <div className='search-icon'><SearchOutlined/></div>
            {/*{filters.map(rowFields => {*/}
            {/*	const key = rowFields.map(({ name }) => name).join('-');*/}
            {/*	return <div*/}
            {/*		className='row'*/}
            {/*		key={key}*/}
            {/*	>*/}
            {/*		{rowFields.map(renderField)}*/}
            {/*	</div>*/}
            {/*})}*/}
            <div className='filter-fields'>{filters.map(renderField)}</div>
            <div className='buttons'>
                <Button
                    size={size as SizeType}
                    key='search'
                    // className={styles.search_btn}
                    // disabled={loading}
                    // type='primary'
                    onClick={onSearchBtnClickFn}
                >
                    <SearchOutlined/> Найти
                </Button>
                {!!extraControls.length && (
                    <div className={`${styles.extra_controls} extra-controls`}>
                        {extraControls}
                    </div>
                )}
            </div>

        </div>
    );
});

export default FilterForm;
