import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Alert, Typography } from 'antd';
import { Project, ProjectService } from 'backend/services/printdown';
import { useLoading } from 'hooks/use-loading';
import { showError } from 'utils/notifications';
import { ContractorsOfWorkspaceList } from 'views/contractors/list';
import './styles.scss';
import { dateRenderer } from 'utils/utils';
import Loading from 'components/loading';
import { ContractorsInProjectList } from 'views/projects/details/project-contractors';


const ProjectDetails = () => {
	let { projectId: projectIdString = '' } = useParams();
	const navigate = useNavigate();
	const { state } = useLocation();
	const projectId = parseInt(projectIdString, 10);
	const [loading, showLoading, hideLoading] = useLoading();
	const [project, setProject] = useState<Project>({ id: projectId });

	const prevRoute = state?.openedFrom || '/projects';

	useEffect(() => {
		// if (!projectId) {
		// 	navigate('/projects');
		// }
		// else {
			showLoading();
			ProjectService.getProjectById({ projectId })
				.then(data => {
					hideLoading();
					setProject(data);
				})
				.catch(e => {
					hideLoading();
					showError('Не удалось загрузить детали проекта', e);
				})
		// }

	}, []);

	return (
		<div className='project-details'>
			<div className='page-header'>
				<Link to={prevRoute}><ArrowLeftOutlined /> Назад</Link>
				{!loading && <Typography.Title level={4}>{project.name || `Проект ${projectId}`}</Typography.Title>}
			</div>
			<div className='content'>
				<Typography.Title
					level={4}
					className='sub-header'
				>Подрядчики</Typography.Title>
				<ContractorsInProjectList projectId={projectId} projectName={project?.name || ''} />
			</div>
		</div>
	)
}
export default ProjectDetails;
