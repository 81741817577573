import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useParams} from "react-router";
import {Link, useLocation} from "react-router-dom";
import {Tabs, Typography} from "antd";
import {ArrowLeftOutlined, ExclamationCircleOutlined, HighlightOutlined, UserOutlined} from "@ant-design/icons";
import {ContractorResponse, DocumentResponse, DocumentsRequest,} from "backend/services/printdown";
import {useLoading} from "hooks/use-loading";
import {ContractorMainInfo} from "./main-info";
import {createDocument, loadDocumentsByContractorId} from "./services";
import './styles.scss';
import {DocumentForm} from "./documents-form";
import {dateTimeRenderer} from "../../../utils/utils";
import {DocumentsEnum} from "../../../utils/constants";
import {getDocumentIcon} from "../../../utils/renderers";

const DocTypesWithData = [DocumentsEnum.PASSPORT, DocumentsEnum.PASSPORT_CHILD, DocumentsEnum.INN, DocumentsEnum.SNILS, DocumentsEnum.BANK_DETAILS, DocumentsEnum.BIRTH_CERTIFICATE, DocumentsEnum.OGRNIP];

const ContractorDetails = () => {
    const [loading, showLoading, hideLoading] = useLoading();
    const {contractorId: contractorIdStr} = useParams();
    const {state = {}} = useLocation();
    const [documents, setDocuments] = useState<DocumentResponse[]>([]);
    const [contractorInfo, setContractorInfo] = useState<ContractorResponse>({userId: -1});
    const passportFileListRef = useRef(null);
    const childPassportFileListRef = useRef(null);

    const {openedFrom = '/contractors', contractorName = '', role = '', project = ''} = state || {};
    // @ts-ignore
    const contractorId = useMemo<number>(() => parseInt(String(contractorIdStr), 10), [contractorIdStr]);

    const loadDocuments = useCallback((props: { loadPassportFiles?: 'CHILD' | 'MAIN' } = {}) => {
        showLoading();
        loadDocumentsByContractorId(contractorId, (isSuccess, loadedDocs) => {
            hideLoading();
            setDocuments(loadedDocs);
            // if (isSuccess) {
            //     if ( props.loadPassportFiles === 'CHILD' && childPassportFileListRef.current) {
            //         // @ts-ignore
            //         childPassportFileListRef.current?.loadFiles();
            //     }
            //     else if (props.loadPassportFiles === 'MAIN' && passportFileListRef.current) {
            //         // @ts-ignore
            //         passportFileListRef.current?.loadFiles();
            //     }
            // }
        });
    }, [passportFileListRef.current, childPassportFileListRef.current]);

    const createDoc = useCallback((documentTypeCode: string, documentTypeId: number, onFinish: (props: {
        isSuccess: boolean
        documentId: number
    }) => void) => {
        showLoading();
        const docData: DocumentsRequest = {
            documentTypeId
        };

        if (documentTypeCode === 'USN') {
            docData.usnRequest = {}
        } else if (documentTypeCode === 'NPD') {
            docData.npdRequest = {}
        } else if (documentTypeCode === 'BIRTH_CERTIFICATE') {
            docData.birthCertificateRequest = {}
        }
        createDocument(contractorId, docData, (isSuccess, createdDoc) => {
            hideLoading();
            onFinish({
                isSuccess,
                documentId: createdDoc?.documentId || 0
            });
        });
    }, [contractorId]);

    useEffect(() => {
        loadDocuments({});
    }, []);

    return (
        <div className='contractor-details'>
            <div className='page-header'>
                <Link to={openedFrom}><ArrowLeftOutlined/> Назад</Link>
                {!loading && (<>
                    <div className='contractor-name'>
                        <Typography.Title level={4}>{contractorName || contractorId}</Typography.Title>
                        {(role || project) && <Typography.Title level={4} className='project-and-role'>
                            ({role} {project ? `, ${project}` : ''})
                        </Typography.Title>}
                    </div>
                    <div className='contractor-author'>
                        <HighlightOutlined/>
                        <span
                            style={{margin: '0 6px'}}>{contractorInfo.createDate ? dateTimeRenderer(contractorInfo.createDate) : ''}</span>
                        {contractorInfo?.userId === 0 && 'Бот'}
                        {(contractorInfo?.userId || 0) > 0 && contractorInfo.userName}
                    </div>
                </>)}
            </div>

            <Tabs type='card' items={
                [
                    {
                        forceRender: true,
                        key: 'main-info',
                        label: <div className='tab-title'><UserOutlined style={{fontSize: '16px'}}/>Основные данные
                        </div>,
                        children: <ContractorMainInfo contractorId={contractorId} allDocs={documents}
                                                      onLoadContractor={setContractorInfo}/>
                    }
                ].concat(documents.map((doc) => {
                    const {documentTypeCode = '', documentTypeName = ''} = doc;
                    // const docWithOnlyPhotos = !DocTypesWithData.includes(documentTypeCode as DocumentsEnum);
                    const isValidDocument = !!doc.documentId;
                    // const isPassport = documentTypeCode === 'PASSPORT' || documentTypeCode === 'PASSPORT_CHILD';

                    return ({
                        forceRender: true,
                        key: documentTypeCode,
                        label: <div className={`tab-title ${!isValidDocument ? 'not-filled' : ''}`}>
                            {getDocumentIcon(documentTypeCode, documentTypeName)}
                            <span className='document-name'>{doc.documentTypeName}</span>

                            {/*{!isValidDocument &&*/}
                            {/*<ExclamationCircleOutlined/>}*/}
                        </div>,
                        children: <DocumentForm document={doc} loadDocuments={loadDocuments}
                                                createDocumentBeforeUpload={createDoc}
                                                contractorId={contractorId}/>
                    })
                }))}
            />
        </div>
    );
}

export default ContractorDetails;
