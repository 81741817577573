import React, {  useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getDictionary } from 'store/reducers/dictionaries/selectors';
import { createDictionaryOptions } from 'utils/utils';
import {DictionaryItem} from "../utils/types";

export function useEmploymentOptions(): [React.ReactNode[], Record<string, string>] {
	const employmentTypes = useSelector(getDictionary('employmentType'));

	// @ts-ignore
	const employmentTypesMap = useMemo(() => employmentTypes.reduce((accum: Record<string, string>,item: DictionaryItem ) => {
		return {
			...accum,
			[item.name] : item.description
		}
	}, {}), [employmentTypes.length]);

	const employmentTypeOptions = useMemo(() => createDictionaryOptions(employmentTypes as DictionaryItem[]), [employmentTypes.length]);


	return [employmentTypeOptions, employmentTypesMap];
}
