import Icon from '@ant-design/icons';
import React from 'react';


const BirthCertificateSVG = () => <svg width="1em" height="1em" viewBox="0 0 1000 1000" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
	<path fillRule="evenodd" clipRule="evenodd" d="M559.186 688.85L559.723 964.569L682.464 898.179L806.113 964.448L805.577 689.276C842.218 655.874 865.229 607.758 865.229 554.31C865.229 453.523 783.402 371.696 682.615 371.696C581.827 371.696 500 453.523 500 554.31C500 607.526 522.812 655.456 559.186 688.85ZM623.261 727.047L623.514 857.302L682.325 825.492L741.904 857.423L741.651 727.156C723.121 733.49 703.266 736.925 682.615 736.925C661.842 736.925 641.875 733.449 623.261 727.047ZM896.593 148H208.825L0 344.76V748.593C0 805.703 46.297 852 103.407 852C237.378 852 513.05 852 513.05 852V788C513.05 788 237.378 788 103.407 788C81.643 788 64 770.357 64 748.593V418.892H173.882C230.992 418.892 277.289 372.595 277.289 315.485V212H896.593C918.357 212 936 229.643 936 251.407V748.593C936 770.357 918.357 788 896.593 788H851.777V852H896.593C953.703 852 1000 805.703 1000 748.593V251.407C1000 194.297 953.703 148 896.593 148ZM117.885 705.721H424.123V641.721H117.885V705.721ZM682.615 435.696C748.08 435.696 801.229 488.845 801.229 554.31C801.229 619.776 748.08 672.925 682.615 672.925C617.149 672.925 564 619.776 564 554.31C564 488.845 617.149 435.696 682.615 435.696ZM118.45 576.741H354.112V512.741H118.45V576.741ZM213.289 231.727L82.573 354.892H173.882C195.646 354.892 213.289 337.249 213.289 315.485V231.727Z" fill="currentColor"/>
</svg>


export const BirthCertificateIcon = (props: any) => <Icon className='inn-icon' component={BirthCertificateSVG} {...props} />;


