import React, {useCallback, useEffect, useMemo, useRef} from "react";
import {useSelector} from "react-redux";
import {PlusOutlined} from "@ant-design/icons";
import {Button} from "antd";
import {UserResponse, UsersService} from "backend/services/printdown";
import {RemoteTable} from "components/table/remote-table";
import {useLoading} from "hooks/use-loading";
import {StoreState} from "store";
import {showError, showMessage} from "utils/notifications";

import {userColumns} from "./columns";
import {useProjectOptions, useUserRoleOptions} from "./hooks";
import {userFilters} from "./filters";
import {sendInvitationToUser} from "./services";
import {showUserModal} from "./user-modal";
import './style.scss';


export const Users = () => {
    const {user: {isAdmin, workspaceId, isSuperAdmin,}, companyId, companyName} = useSelector((store: StoreState) => store.auth);
    const tableRef = useRef(null);
    const [roleOptions] = useUserRoleOptions();
    const [projectOptions] = useProjectOptions();
    const [loading, showLoading, hideLoading] = useLoading();


    const reloadTable = useCallback(() => {
        // @ts-ignore
        tableRef.current?.reloadTable();
    }, [tableRef.current]);

    const onAddClick = useCallback(() => {
        showUserModal({
            user: {id: 0},
            roleOptions,
            projectOptions,
            onFinish: (isSuccess) => {
                if (isSuccess) {
                    reloadTable();
                }
            }
        });
    }, [reloadTable, roleOptions.length, projectOptions.length]);

    const onEditClick = useCallback((user: UserResponse) => {
        showUserModal({
            user,
            roleOptions,
            projectOptions,
            onFinish: (isSuccess) => {
                if (isSuccess) {
                    reloadTable();
                }
            }
        });
    }, [reloadTable, roleOptions.length, projectOptions.length]);

    const onDeleteClick = useCallback((user: UserResponse, hardDelete = false) => {
        showLoading();
        const promise = hardDelete ? (isSuperAdmin ?UsersService.deleteUser({userId: user.id || 0})   : null) :  UsersService.removeUserFromWorkspace({workspaceId, userId: user.id || 0});
        if (promise) {
            promise
                .then(() => {
                    hideLoading();
                    showMessage(`Пользователь ${user.name} удалён!`);
                    reloadTable();
                })
                .catch((e) => {
                    hideLoading();
                    showError(`Не удалось удалить пользователя ${user.name}`, e)
                })
        }

    }, [reloadTable, workspaceId,isSuperAdmin]);

    const sendInvitation = useCallback((user: UserResponse) => {
        showLoading();
        sendInvitationToUser(user, hideLoading);
    }, []);

    const tableColumns = useMemo(() => {
        return userColumns({
            onEditClick,
            onDeleteClick,
            isSuperAdmin,
            sendInvitation
        })
    }, [onEditClick, onDeleteClick, isSuperAdmin]);


    const convertRequestParams = useCallback(({pageNum, pageSize, ...params}: any) => {
        return ({
            ...params,
            page: pageNum,
            size: pageSize,
            workspaceId,
            active: true
        })
    }, [workspaceId]);

    useEffect(() => {
        reloadTable();
    }, [workspaceId])

    return (
        <div className='users-list'>
            <RemoteTable
                ref={tableRef}
                columns={tableColumns}
                filters={userFilters}
                loadDataFn={UsersService.getAllUsers}
                requestParamsConverter={convertRequestParams}
                toolbar={<Button
                    type='link'
                    style={{padding: 0}}
                    onClick={onAddClick}
                >
                    <PlusOutlined/>
                    Добавить пользователя
                </Button>}
            />
        </div>
    )
}
