import {Avatar, Menu} from "antd";
import {
    EnumUserRequestRole,
    RoleResponse,
    Workspace
} from "backend/services/printdown";

import {modal} from 'global/NotificationsProvider';
import store from "store";
import {loginError, loginSuccess} from "store/reducers/auth";
import {IUserData} from "utils/types";
import {loadUserProfile} from "./services";


const showWorkspaceSelectModal = ({grantedWorkspaces, onOk}: {
    grantedWorkspaces: Workspace[],
    onOk: (selectedWorkspace: Workspace) => void
}) => {
    const modalCmp = modal.info({
        className: 'workspace-selector',
        title: 'Выберите пространство для работы',
        content: <Menu
            onClick={({item, key, keyPath, domEvent}) => {
                const [workspaceIdStr, workspaceName = ''] = key.split(' - ');
                const workspaceId = parseInt(workspaceIdStr, 10);
                if (workspaceId) {
                    modalCmp.destroy();
                    onOk({id: workspaceId, name: workspaceName || workspaceIdStr});
                }

            }}
            // @ts-ignore
            items={grantedWorkspaces.map((workspace) => ({
                key: `${workspace.id} - ${workspace.name}`,
                label: <div className='workspace-item'>
                    <Avatar style={{
                        backgroundColor: workspace.color || 'gray',
                        color: 'white'
                    }}>{workspace.name ? workspace.name[0].toUpperCase() : '?'}</Avatar>
                    {workspace.name}
                </div>
            }))}

        />
    });

}

export const getUserData = (authData: IUserData) => {
    loadUserProfile({
        userId: authData.id,
        token: authData.token,
        onFinish: (isSuccess, loadedUser = {}) => {
            if (!isSuccess) {
                // @ts-ignore
                store.dispatch(loginError());
            } else {
                const userRoles: RoleResponse[] = Array.isArray(loadedUser.role) ? loadedUser.role : (loadedUser.role?.roleCode ? [loadedUser.role] : []);
                const isAdmin = userRoles.some(({roleCode}) => roleCode === EnumUserRequestRole.ADMIN);
                const isSuperAdmin = userRoles.some(({roleCode}) => roleCode === EnumUserRequestRole.SUPER_ADMIN);
                const resultUser: IUserData = {
                    ...authData,
                    ...loadedUser,
                    roles: userRoles,
                    isAdmin,
                    isSuperAdmin
                };

                if (resultUser.workspaces.length > 1 && !authData.workspaceId) {
                    showWorkspaceSelectModal({
                        grantedWorkspaces: resultUser.workspaces,
                        onOk: (selectedWorkspace) => {
                            resultUser.workspaceId = selectedWorkspace.id || 0;
                            resultUser.workspaceName = selectedWorkspace.name || '';
                            store.dispatch(loginSuccess(resultUser));
                        }
                    })
                } else {
                    if (authData.workspaceId) {
                        resultUser.workspaceId = authData.workspaceId;
                        resultUser.workspaceName = authData.workspaceName;
                    }
                    else {
                        const grantedWorkspace = resultUser.workspaces.length? resultUser.workspaces[0] : {id: 0, name: ''};
                        resultUser.workspaceId = grantedWorkspace.id || 0;
                        resultUser.workspaceName = grantedWorkspace.name || ''
                    }

                    store.dispatch(loginSuccess(resultUser));
                }
            }
        }
    });
}
