import {UserResponse, UsersService} from "backend/services/printdown";
import {showError} from "utils/notifications";
import {ActionCallbackWithData} from "utils/types";
import {stringToColor} from "utils/utils";

export const loadUserProfile = ({userId, token = '', onFinish}: {
    userId: number,
    token?: string,
    onFinish: ActionCallbackWithData<UserResponse>
}) => {
    if (!userId) {
        onFinish(false, {});
        return;
    }

    const authOptions = token ? {headers: {Authorization: `Bearer ${token}`}} : {};
    UsersService.getUser({userId}, authOptions)
        .then((loadedUser: UserResponse) => {
            (loadedUser.workspaces || []).forEach((workspace) => {
                const color = stringToColor(workspace.name);
                console.log(`For [${workspace.name}] color: [${color}]`)
                workspace.color = color;
            });

            onFinish(true, loadedUser);
        })
        .catch(e => {
            showError('Не удалось загрузить данные пользователя', e);
            // @ts-ignore
            onFinish(false);
        });
}


// export const getUserPermissions = (authData: IUserData) => {
//     loadUserProfile({userId: authData.id, token: authData.token, onFinish: (isSuccess, loadedUser: UserResponse)})
//     const authOptions = {headers: {Authorization: `Bearer ${authData.token}`}};
//     UsersService.getUser({userId: authData.id}, authOptions)
//         .then((loadedUser: UserResponse) => {
//             const userRoles: RoleResponse[] = Array.isArray(loadedUser.role) ? loadedUser.role : (loadedUser.role?.roleCode ? [loadedUser.role] : []);
//             const isAdmin = userRoles.some(({roleCode}) => roleCode === EnumUserRequestRole.ADMIN);
//             const isSuperAdmin = userRoles.some(({roleCode}) => roleCode === EnumUserRequestRole.SUPER_ADMIN);
//
//             const resultUser: IUserData = {
//                 ...authData,
//                 ...loadedUser,
//                 roles: userRoles,
//                 isAdmin,
//                 isSuperAdmin
//             };
//
//             //получаем workspace
//             WorkspaceService.getWorkspaceById({
//                 workspaceId: loadedUser.workspaceId || 0
//             }, authOptions)
//                 .then((workspaceInfo: Workspace) => {
//                     resultUser.workspaceName = workspaceInfo.name || '';
//                     store.dispatch(loginSuccess(resultUser))
//                 })
//                 .catch(e => {
//                     console.error(e);
//                     store.dispatch(loginSuccess(resultUser))
//                 })
//         })
//         .catch(e => {
//             showError('Не удалось загрузить данные пользователя', e);
//             // @ts-ignore
//             store.dispatch(loginError());
//         })
//
//
// }
