import {useEffect, useMemo, useState} from "react";
import {Menu, Layout} from 'antd';
import {useSelector} from "react-redux";
import {useLocation} from 'react-router-dom';
import {filterNavigationByUserRoles, SIDEBAR_NAVIGATION} from 'navigation';
import {getUserPermissions} from "store/reducers/auth/selectors";
import './style.scss';


export const Sider = () => {
    const location = useLocation();
    const {roles} = useSelector(getUserPermissions);
    let defaultSelectedKey = location.pathname;

    if (/projects\/\d+/.test(location.pathname)) {
        defaultSelectedKey = '/projects';
    }

    const [selectedMenuItem, setSelectedMenuItem] = useState(defaultSelectedKey);

    const permittedNavigation = useMemo(() => {
        return filterNavigationByUserRoles(SIDEBAR_NAVIGATION, roles);
    }, [roles.length]);

    useEffect(() => {
        const selectedRoute = permittedNavigation.find(({key}) => key === location.pathname);
        if (selectedRoute) {
            setSelectedMenuItem(selectedRoute.key);
        }
    }, [location.pathname]);

    return <Layout.Sider collapsible width={320}>
        <Menu
            theme='dark'
            // defaultSelectedKeys={[defaultSelectedKey]}
            mode='inline'
            selectedKeys={[selectedMenuItem]}
            onClick={({item, key}) => {
                setSelectedMenuItem(key);
            }}
            items={permittedNavigation}
        />
    </Layout.Sider>
}
