import React, {useEffect} from 'react';
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {App as AppContext, ConfigProvider, Layout} from 'antd';

import 'backend/axios';
import NotificationsProvider from "global/NotificationsProvider";
import Header from 'layout/header';
import PageHeader from 'layout/page-header';
import {Sider} from 'layout/sider';
import {ROUTES} from 'navigation/routes';

import {ContractorsOfWorkspaceList} from 'views/contractors/list';

import {ProjectsList} from 'views/projects/list';
import ProjectDetails from 'views/projects/details';
import PrivatePage from 'navigation/private-route';
import ContractorDetails from "./views/contractors/details";
import {StoreState} from "./store";
import {IS_DEBUG} from "./utils/constants";
import {getUserData} from "./views/auth/login/helpers";
import {AppConfig} from "./utils/theme";
import './App.scss';
import AccessDeniedPage from "./views/auth/access-denied-page";
import {logout} from "./store/reducers/auth";


if (!IS_DEBUG) {
    console.log = () => {
    }
}

function App() {
    const dispatch = useDispatch();
    const {user, isUserLoggedIn} = useSelector((state: StoreState) => state.auth);

    useEffect(() => {
        if (isUserLoggedIn) {
            getUserData(user);
        }
        else {
            // @ts-ignore
            dispatch(logout());
        }

    }, []);

    return (

        <div className='App'>
            <ConfigProvider {...AppConfig}>
                <AppContext>
                    <NotificationsProvider/>
                    <BrowserRouter>
                        <Layout>
                            <Header/>
                            <Layout>
                                {isUserLoggedIn && <Sider/>}
                                <Layout.Content>
                                    {isUserLoggedIn && <PageHeader/>}

                                    <Routes>
                                        {
                                            ROUTES.map(({key, component, permissions = []}) => <Route
                                                    key={key}
                                                    path={key}
                                                    element={<PrivatePage path={key} permissions={permissions}>{component}</PrivatePage>}
                                                />
                                            )}
                                        <Route path='/projects'>
                                            <Route
                                                index
                                                element={<PrivatePage><ProjectsList/></PrivatePage>}
                                            />
                                            <Route
                                                path='/projects/:projectId'
                                                element={<PrivatePage><ProjectDetails/></PrivatePage>}
                                            />

                                        </Route>
                                        <Route path='/contractors'>
                                            <Route
                                                path='/contractors/:contractorId'
                                                element={<PrivatePage><ContractorDetails/></PrivatePage>}
                                            />
                                            <Route
                                                index
                                                element={<PrivatePage><ContractorsOfWorkspaceList/></PrivatePage>}
                                            />
                                        </Route>
                                        <Route
                                            path="/no-access"
                                            element={<AccessDeniedPage />}
                                        />
                                        <Route
                                            path="*"
                                            element={<Navigate to="/projects" replace/>}
                                        />
                                    </Routes>
                                </Layout.Content>
                            </Layout>
                        </Layout>
                    </BrowserRouter>
                </AppContext>
            </ConfigProvider>
        </div>
        // </AuthContext.Provider>
    );
}

export default App;
