import React from 'react';
import { IFilterFieldConfig } from 'components/table/filter-form/types';

export const userFilters :IFilterFieldConfig[]  = [
	{
		name: 'name',
		title: 'ФИО '
	},
	{
		name: 'email',
		title: 'Email '
	},
]
