import Icon from '@ant-design/icons';
import React from 'react';


const OgrnipSVG = () => <svg width="1em" height="1em" viewBox="0 0 1000 1000" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
	<path fillRule="evenodd" clipRule="evenodd" d="M802.671 855.85H870.109V788.412H802.671V855.85ZM130.166 855.85H720.167V788.412H130.166V855.85ZM280.108 560.288H197.604V467.04H130.166V720.974H197.604V627.726H280.108V720.974H347.546V467.04H280.108V560.288ZM870.109 467.04H652.729V720.974H720.167V534.478H802.671V720.974H870.109V467.04ZM537.653 467.079H533.551L455.965 597.619V467.04H388.527V720.974H455.965V720.935H461.123L537.653 592.171V720.974H605.091V467.04H537.653V467.079ZM250.075 144.148C177.336 144.148 118.281 203.203 118.281 275.942C118.281 348.682 177.336 407.737 250.075 407.737C322.815 407.737 381.869 348.682 381.869 275.942C381.869 203.203 322.815 144.148 250.075 144.148ZM605.091 148.976H411.35V402.909H478.788V216.414H605.091V148.976ZM788.563 148.976H652.729V402.909H720.167V335.284H788.563C813.27 335.284 836.964 325.47 854.432 308.001C871.902 290.531 881.716 266.837 881.716 242.131V242.129C881.716 217.423 871.902 193.729 854.432 176.259C836.964 158.79 813.27 148.976 788.563 148.976ZM250.075 211.587C285.594 211.587 314.431 240.424 314.431 275.942C314.431 311.461 285.594 340.298 250.075 340.298C214.556 340.298 185.719 311.461 185.719 275.942C185.719 240.424 214.556 211.587 250.075 211.587ZM720.167 216.414V267.846H788.563C795.384 267.846 801.924 265.137 806.747 260.315C811.569 255.492 814.278 248.951 814.278 242.131V242.129C814.278 235.308 811.569 228.767 806.747 223.946C801.924 219.123 795.384 216.414 788.563 216.414H720.167Z" fill="currentColor"/>
</svg>



export const OgrnipIcon = (props: any) => <Icon className='inn-icon' component={OgrnipSVG} {...props} />;


