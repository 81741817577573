import {Typography} from "antd";
import PassportForm from "../../../../components/documents/passport-form";
import {BankRequisites} from "../../../../components/documents/bank-requisites";
import {InnSnilsForm} from "../../../../components/documents/inn-snils-form";
import {BirthCertificateForm} from "../../../../components/documents/birth-certificate-form";
import {FileList} from "../../../../components/documents/file-list";
import {DocumentResponse} from "../../../../backend/services/printdown";
import './styles.scss';
import {contractorChanged} from "../../../../store/reducers/contractors";
import {useDispatch} from "react-redux";

const DocTypesWithData = ['PASSPORT', 'PASSPORT_CHILD', "INN", 'SNILS', 'BANK_DETAILS', 'BIRTH_CERTIFICATE', 'OGRNIP'];
const DocTypesWithoutPhoto = ['BANK_DETAILS'];


export const DocumentForm = ({document, loadDocuments, contractorId, createDocumentBeforeUpload}: {
    document: DocumentResponse,
    loadDocuments: any,
    createDocumentBeforeUpload: any,
    contractorId: number
}) => {
    const dispatch = useDispatch();
    const {documentTypeCode = ''} = document;
    const docWithOnlyPhotos = !DocTypesWithData.includes(documentTypeCode);
    const isValidDocument = !!document.documentId;
    const isPassport = documentTypeCode === 'PASSPORT' || documentTypeCode === 'PASSPORT_CHILD';


    const fieldProps = {
        values: document,
        contractorId,
        onSuccessSave: () => {
            // @ts-ignore
            dispatch(contractorChanged());
            loadDocuments();
        }
    }

    //todo рефы - загрузить список файлов
    return <div className={`document ${documentTypeCode}`}>
        {DocTypesWithData.includes(documentTypeCode || '') && (
            <div className='document-form'>
                {(isPassport || documentTypeCode === 'BANK_DETAILS') &&
                    <div><Typography.Text strong>
                        {isPassport ? 'Данные паспорта' : 'Банковские реквизиты'}
                    </Typography.Text></div>
                }
                {isPassport && <PassportForm{...fieldProps}/>}
                { /*@ts-ignore*/}
                {documentTypeCode === 'BANK_DETAILS' && <BankRequisites {...fieldProps}/>}
                {(documentTypeCode === 'INN' || documentTypeCode === 'SNILS' || documentTypeCode === 'OGRNIP') && (
                    <div className='document-tab'><InnSnilsForm{...fieldProps}/></div>
                )}
                {(documentTypeCode === 'BIRTH_CERTIFICATE') && (
                    <div className='document-tab'><BirthCertificateForm{...fieldProps}/></div>
                )}
            </div>
        )}

        {/*для паспорта, ИНН и снилс обязательно сначала заполнить данные*/}
        {/*для остальных доков - только фотки*/}
        {(!DocTypesWithoutPhoto.includes(documentTypeCode) && ((document.documentId || 0) > 0 || docWithOnlyPhotos)) && (
            <FileList
                // ref={documentTypeCode === 'PASSPORT' ? passportFileListRef : (
                //     documentTypeCode === 'PASSPORT_CHILD' ? childPassportFileListRef : null
                // )}
                documentId={document.documentId}
                documentTypeCode={documentTypeCode}
                // @ts-ignore
                beforeUpload={docWithOnlyPhotos ? (onFinish) => {
                    createDocumentBeforeUpload(documentTypeCode, document.documentTypeId || 0, onFinish);
                } : null}
            />
        )}

    </div>
}
