import { createSlice, PayloadAction, SliceCaseReducers, } from '@reduxjs/toolkit';
import {Company, ContractorRole, PageContractorRole, Project} from 'backend/services/printdown';
import { TableState } from 'store/types';
import {ALL_ROLES_PAGE_SIZE, loadRolesThunk, RolesRequestParams} from 'store/reducers/roles/thunks';




const rolesSlice = createSlice<TableState<ContractorRole>, SliceCaseReducers<TableState<ContractorRole>>>({
	name: 'roles',
	initialState: {
		isLoading: false,
		search: '',
		total: 0,
		page: 0,
		pageSize: 10,
		data: [],
		all: []
	},
	reducers: {
		roleChanged: () => {
		},
		roleSearchChanged: (state, {payload} : PayloadAction<string>) => {
			state.search  = payload;
			state.page = 0;
		},
		rolePaginationChanged: (state, {payload: {pageNum, pageSize}}:PayloadAction<RolesRequestParams>) => {
			state.page = pageNum;
			state.pageSize = pageSize;
		}
	},
	extraReducers: (builder) => {
		// @ts-ignore
		builder
			.addCase(loadRolesThunk.pending, (state ) => {
				state.isLoading = true;
			})
			.addCase(loadRolesThunk.fulfilled, (state, { payload }: PayloadAction<PageContractorRole>) => {
				const isAllRolesLoaded = payload.pageable?.pageSize === ALL_ROLES_PAGE_SIZE;
				state.isLoading = false;
				if (isAllRolesLoaded) {
					state.all = payload.content || [];
				}
				else {
					state.data = payload.content || [];
					state.total = payload.totalElements || 0;
				}
			})
			.addCase(loadRolesThunk.rejected, (state, action) => {
				state.isLoading = false;
			})
	}
})


export const {
	roleChanged, rolePaginationChanged,roleSearchChanged
} = rolesSlice.actions;
export default rolesSlice.reducer;
