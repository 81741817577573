import {
    BankDetailsRequest,
    ContractorResponse,
    DocumentResponse,
     EnumContractorType,
} from "../../../../../backend/services/printdown";
import {Typography} from "antd";
import {abbreviateFullName, dateRenderer} from "../../../../../utils/utils";
import React, {useMemo} from "react";
import {useSelector} from "react-redux";
import {getDictionary} from "../../../../../store/reducers/dictionaries/selectors";

type InfoBlock = Record<'main' | 'bank' | 'contacts', string[]>;

interface PrintRequisitesProps {
    contractor: ContractorResponse,
    documents: DocumentResponse[]
}


export const ContractorPrintRequisites = ({contractor, documents}: PrintRequisitesProps) => {
    // @ts-ignore
    const requisites = useMemo<{ infoBlocks: InfoBlock, copyText: string }>(() => {
        // @ts-ignore
        const result: Record<string, DocumentResponse> = documents.reduce((accum, doc) => ({
            ...accum,
            [doc.documentTypeCode || '']: doc || {}
        }), {});

        let employmentTypeName = '';
        switch(contractor.type) {
            case EnumContractorType.INDIVIDUAL: {
                employmentTypeName = 'Индивидуальный предприниматель';
                break;
            }
            case EnumContractorType.SELF_EMPLOYED: {
                employmentTypeName = 'Гражданин Российской Федерации';
                break;
            }
            case EnumContractorType.PHYSICAL: {
                employmentTypeName = 'Гражданин Российской Федерации';
                break;
            }
            default: {
                break;
            }
        }

        const infoBlocks: InfoBlock = {
            main: [],
            bank: [],
            contacts: []
        };

        infoBlocks.main = [
            employmentTypeName || '',
            contractor.name || '',
            result.PASSPORT?.birthDate ?  `Дата рождения: ${dateRenderer(result.PASSPORT?.birthDate || '')} г.` : 'undefined',
            `Паспорт: ${result.PASSPORT?.number}, выдан: ${result.PASSPORT?.issue}, дата выдачи: ${dateRenderer(result.PASSPORT?.issueDate || '')} г., код подразделения: ${result.PASSPORT?.unitCode}`,
            `Адрес регистрации: ${result.PASSPORT?.address}`,
            `ИНН: ${result.INN?.number}`,
            `СНИЛС: ${result.SNILS?.number}`,
        ].filter(str => !str.includes('undefined'));

        infoBlocks.bank = [
            `р/с ${(result.BANK_DETAILS as BankDetailsRequest)?.accountNumber}`,
            `БИК ${(result.BANK_DETAILS as BankDetailsRequest)?.bic}`,
            `Банк-получатель: ${(result.BANK_DETAILS as BankDetailsRequest)?.recipientBank}`,
            `к/с  ${(result.BANK_DETAILS as BankDetailsRequest)?.correspondentAccount}`
        ].filter(str => !str.includes('undefined'));

        infoBlocks.contacts = [
            `тел.: ${contractor.phone || ''}`,
            `email: ${contractor.email || ''}`,
        ].filter(str => !str.includes('undefined'));

        const copyText = [
            infoBlocks.main.join('\n'),
            (infoBlocks.bank.length ? [`Банковские реквизиты:`] : []).concat(infoBlocks.bank).join('\n'),
            infoBlocks.contacts.join('\n'),
            `__________________ / ${abbreviateFullName(contractor.name || '')}`
        ].join('\n\n');

        return {
            infoBlocks,
            copyText
        };
    }, [JSON.stringify(documents), contractor.email, contractor.phone, contractor.name]);

    return <div className='print-requisites'>
        <Typography.Title level={4} copyable={{text: requisites.copyText}}>Реквизиты для печати</Typography.Title>
        <Typography.Paragraph>
            {Object.entries(requisites.infoBlocks).map(([blockName, strings]) => <div key={blockName}>
                {blockName === 'bank' && strings.length > 0 && <span style={{fontWeight: 600}}>Банковские реквизиты:<br/></span>}
                {strings.map(str => <span key={str}>
                    {str}<br/>
                </span>)}
                <br/>
            </div>)}
        </Typography.Paragraph>
    </div>
}
