import React, {useCallback, useMemo, useState} from "react";
import {Button, Input, Tooltip, Typography} from "antd";
import {CheckOutlined, CloseOutlined, EditOutlined, LoadingOutlined,} from "@ant-design/icons";
import {Document, DocumentService, DocumentsRequest,} from "backend/services/printdown";
import {useLoading} from "hooks/use-loading";
import {showError} from "utils/notifications";
import {ActionCallback} from "utils/types";

import './styles.scss';

const RequestFieldNames: Record<string, {
    saveFieldName: keyof DocumentsRequest,
    documentName: string,
    maxLength: number
}> = {
    INN: {
        saveFieldName: 'innRequest',
        documentName: 'ИНН',
        maxLength: 12
    },
    SNILS: {
        saveFieldName: 'snilsRequest',
        documentName: 'СНИЛС',
        maxLength: 11
    },
    OGRNIP: {
        saveFieldName: 'ogrnipRequest',
        documentName: 'СНИЛС',
        maxLength: 15
    }
}

export const InnSnilsForm = ({contractorId, values, onSuccessSave}: {
    contractorId: number,
    mask?: string,
    values: Document & { number?: string, documentTypeCode?: string },
    onSuccessSave: ActionCallback
}) => {
    const [isEdit, startEdit, cancelEdit] = useLoading();
    const [loading, showLoading, hideLoading] = useLoading();
    const [docNumber, setDocNumber] = useState(values.number || '');
    const [prevNumber, setPrevNumber] = useState(values.number || '');

    const isEditExistedDocument = useMemo(() => !!values.documentId, [values?.documentId]);
    // @ts-ignore
    const documentConfig = useMemo(() => RequestFieldNames[values.documentTypeCode] || {}, [values.documentTypeCode]);

    const onChangeNumber = useCallback(({target: {value}}: React.ChangeEvent<HTMLInputElement>) => {
        setDocNumber(value);
    }, []);

    const cancelEditing = useCallback(() => {
        setDocNumber(prevNumber)
        cancelEdit();
    }, [prevNumber]);

    const saveChanges = useCallback(() => {
        showLoading();
        const valuesToSave: DocumentsRequest = {
            documentTypeId: values.documentTypeId,
            [documentConfig.saveFieldName]: {number: docNumber}
        };

        const promise = isEditExistedDocument ? DocumentService.updateDocument({
            documentId: values?.documentId || 0,
            body: valuesToSave
        }) : DocumentService.addDocument({
            contractorId,
            body: valuesToSave
        });

        promise.then(() => {
            hideLoading();
            cancelEdit();
            onSuccessSave();
        }).catch(e => {
            hideLoading();
            showError(`Не удалось сохранить данные ${documentConfig.documentName}`, e);
            cancelEditing();
        })
    }, [docNumber, values?.documentId, values?.documentTypeCode, isEditExistedDocument, documentConfig.documentName]);


    return (
        <div className={`number-form ${!docNumber ? 'invalid' : ''} ${!isEdit ? 'readonly' : ''}`}>
            <div className='field'>
                <div className='label'>Номер документа:</div>
                {isEdit ? <Input
                        onChange={onChangeNumber}
                        value={docNumber}
                        maxLength={documentConfig.maxLength > 0 ? documentConfig.maxLength : 250}
                    />
                    : <Typography.Text
                        className={`readonly ${!docNumber ? 'empty' : ''}`}
                        copyable={docNumber ? {text: docNumber} : false}
                    >
                        {docNumber || 'не указано'}
                    </Typography.Text>
                }
                {!isEdit && <Tooltip title='Редактировать'>
                    <Button size='small' className='edit-btn' onClick={startEdit}><EditOutlined/></Button>
                </Tooltip>}
                {isEdit && <>
                    <Button type='link' style={{marginRight: 10}} onClick={saveChanges}>
                        {loading ? <LoadingOutlined/> : <CheckOutlined/>} Сохранить
                    </Button>
                    <Button type='link' onClick={cancelEditing}>
                        <CloseOutlined/> Отмена
                    </Button>
                </>}
            </div>
            {isEdit && documentConfig.maxLength > 0 && <div className='hint'>{documentConfig.maxLength} цифр</div>}
        </div>

    )
}
