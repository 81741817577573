import {PassportFormValues, PassportRequest} from "../../../backend/services/printdown";
import {SERVER_DATE_FORMAT} from "../../../utils/constants";
import dayjs from "dayjs";
import PassportForm from "./index";
import {RcFile} from "antd/es/upload";

const PassportRequiredFields: Array<keyof PassportRequest> = [
    'number', 'firstName', 'lastName', 'issue', 'issueDate', 'unitCode', 'address'
];

export const isValidPassport = (values: PassportRequest) => PassportRequiredFields.every(fieldName => !!values[fieldName]);

export const convertPassportDatesToServer = (values: PassportFormValues): PassportRequest => {
    return {
        ...values,
        birthDate: values.birthDate ? values.birthDate.format(SERVER_DATE_FORMAT) : '',
        issueDate: values.issueDate ? values.issueDate.format(SERVER_DATE_FORMAT) : ''
    }
}

export const convertPassportDatesToMoment = (values: PassportRequest): PassportFormValues => ({
    ...values,
    // @ts-ignore
    birthDate: values.birthDate ? dayjs(values.birthDate) : null,
    // @ts-ignore
    issueDate: values.issueDate ? dayjs(values.issueDate) : null
})

// @ts-ignore
export const EmptyRcFile: RcFile = {
    name: '',
    size: 0,
    uid: '',
    lastModifiedDate: new Date(),
};
