import {DocumentService, PassportFormValues, PassportRequest} from "../../../backend/services/printdown";
import {showError} from "../../../utils/notifications";
import {convertPassportDatesToMoment, convertPassportDatesToServer} from "./utils";
import {PassportDocRequest, PassportDocValues} from "./types";
import {useMemo} from "react";
import {RcFile} from "antd/es/upload";
import axios from "axios";
import dayjs from "dayjs";

const TEST_PASSPORT: PassportRequest = {
    "number": "3333 444444",
    "firstName": "sdfsfsdfsf",
    "lastName": "sdfsfsdsdf",
    "issue": "sfsdsf",
    "issueDate": '2020-12-09',
    birthDate: '2014-01-25',
    "unitCode": "324234",
    "address": "xcvxvxvxcvcxvcxv",
}

export const savePassport = (formValues: PassportDocValues, contractorId: number, onFinish: (isSuccess: boolean, doc?: PassportDocRequest) => void) => {
    const passportToSave: PassportRequest = convertPassportDatesToServer(formValues);

    const requestBody = {
        documentTypeId: formValues.documentTypeId || 0,
        [formValues.documentTypeCode === 'PASSPORT' ? 'passportRequest' : 'passportChildRequest']: passportToSave
    };

    const isFilledPassport =formValues?.documentId && formValues.documentId > 0;

    const promise = isFilledPassport ? DocumentService.updateDocument({
        documentId: formValues?.documentId || 0,
        body: requestBody
    }) : DocumentService.addDocument({
        contractorId,
        body: requestBody
    });

    promise.then((updatedDoc) => {
        onFinish(true,updatedDoc)
    }).catch(e => {
        showError('Не удалось сохранить данные паспорта', e);
        onFinish(false);
    })
}

export const recognizePassport = (photo: RcFile, onFinish: (isSuccess: boolean, recognizedValues?: PassportFormValues) => void) => {
    const formData = new FormData();
    formData.append('file', photo);
    axios.post('https://receiver.otr.ru/api/recognizer/passport', formData)
        .then(({data = {}}) => {
            const result: PassportRequest = {
                number: `${data.series || ''} ${data.number || ''}`,
                firstName: data.firstName,
                lastName: data.lastName,
                middleName:data.patronymicName,
                issue: data.issuedBy,
                issueDate: data.issueDate,
                unitCode: data.unitCode,
                sex: data.gender,
                birthDate: data.birthDate,
                birthPlace: data.birthPlace,
                address: data.address
            };

            Object.keys(result).forEach((fieldName) => {
                if (!result[fieldName as keyof PassportRequest]) {
                    result[fieldName as keyof PassportRequest] = ''
                }
            });

            const convertedValues= convertPassportDatesToMoment(result);
            onFinish(true, convertedValues);
        })
        .catch(e => {
            showError('Не удалось распознать пасспорт', e);
            onFinish(false);
        })
}
