import {createListenerMiddleware, isAnyOf} from "@reduxjs/toolkit";
import {companyChanged, loginError, loginSuccess, workspaceChanged} from "../reducers/auth";
import {projectChanged, projectFiltersChanged, projectSortChanged} from "../reducers/projects";
import {organizationChanged} from "../reducers/organizations";
import {roleChanged, rolePaginationChanged, roleSearchChanged} from "../reducers/roles";
import {contractorChanged, contractorFiltersChanged, contractorPaginationChanged} from "../reducers/contractors";
import printdownBackend, {axiosNotAuthorizedInterceptor} from "../../backend/axios";
import {loadDictionaries} from "../reducers/dictionaries";
import {redirect} from "react-router";
import {
    loadAllRolesInStore,
    loadWorkspaceData
} from "../loaders";

export const projectsUpdateMiddleware = createListenerMiddleware();
export  const organizationsUpdateMiddleware = createListenerMiddleware();
export  const rolesMiddleware = createListenerMiddleware();
export  const contractorsMiddleware = createListenerMiddleware();
export const authMiddleware = createListenerMiddleware();


rolesMiddleware.startListening({
    matcher: isAnyOf(roleChanged),
    effect: async (action: any, listenerApi: any) => {
        console.log(' Load all roles');
        loadAllRolesInStore(listenerApi.getState(), listenerApi.dispatch);
    }
})
authMiddleware.startListening({
    matcher: isAnyOf(loginSuccess),
    effect: async (action: any, listenerApi: any) => {
        const token = action.payload.token;
        if (action.payload.workspaces?.length) {
            printdownBackend.defaults.headers.Authorization = `Bearer ${token}`;
            printdownBackend.interceptors.response.use((response) => response, axiosNotAuthorizedInterceptor);
            loadWorkspaceData(listenerApi);
        } else {
            redirect('/no-access');
        }

    }
})

authMiddleware.startListening({
    matcher: isAnyOf(workspaceChanged),
    effect: async (action: any, listenerApi: any) => {
        const {id: newWorkspaceId} = action.payload;
        console.log(`%c Load all data for workspace [${newWorkspaceId}]`, 'color: blue; background: lightgreen');
        loadWorkspaceData(listenerApi);
    }
})


authMiddleware.startListening({
    matcher: isAnyOf(loginError),
    effect: async (action: any, listenerApi: any) => {
        delete printdownBackend.defaults.headers.Authorization;
    }

})
