import React from 'react';
import {Avatar, Button} from 'antd';
import {ColumnsType} from 'antd/lib/table';
import {EditOutlined, UserOutlined, DownloadOutlined} from '@ant-design/icons';
import {Project, ProjectResponse} from 'backend/services/printdown';
import {dateRenderer} from "utils/utils";


export const projectColumns = (props: any): ColumnsType<ProjectResponse> => {
    const {onEditClick, onDeleteClick, onDownloadClick, showButtons = false} = props;
    const result: ColumnsType<ProjectResponse> = [
        {
            title: 'Дата создания',
            dataIndex: 'createDate',
            // ...sortableColumnConfig,
            // defaultSortOrder: 'descend' as SortOrder,
            render: (date: string) => dateRenderer(date)
        },
        {
            title: 'Наименование',
            dataIndex: 'name',
            // ...sortableColumnConfig,
            render: (name: string, {logoLetter, logoColor}: ProjectResponse) => {
                return <>
                    <Avatar size='small' style={{backgroundColor: logoColor, marginRight: 8}}>{logoLetter}</Avatar>
                    {name}
                </>
            }
        },
        {
            title: 'Организация',
            dataIndex: 'companyName',
        },
        {
            title: '',
            dataIndex: 'numberOfContractor',
            render: (count: number) => <div className={count ? '' : 'empty'}>
                <UserOutlined/>{count}
            </div>
        }
    ].map(column => ({
        ...column,
        // sorter: column.sorter || false,
        className: column.dataIndex,
    }));

    if (showButtons) {
        result.push({
            className: "actions",
            title: '',
            dataIndex: 'actions',
            width: 90,
            render: (value: any, record: Project) => (
                <>
                    <Button
                        size='small'
                        onClick={(ev: React.MouseEvent<HTMLElement> | undefined) => {
                            ev?.stopPropagation();
                            onDownloadClick(record);
                        }}
                    ><DownloadOutlined/></Button>
                    <Button
                        size='small'
                        onClick={(ev: React.MouseEvent<HTMLElement> | undefined) => {
                            ev?.stopPropagation();
                            onEditClick(record);
                        }}
                    ><EditOutlined/></Button>
                </>
            )
        });
    }

    return result;
}
