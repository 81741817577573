import {createSlice, PayloadAction, SliceCaseReducers,} from '@reduxjs/toolkit';
import {ContractorResponse, Project} from 'backend/services/printdown';
import {TableFilters, TableState} from 'store/types';
import {loadProjectsThunk} from 'store/reducers/projects/thunks';
import {SortState} from "../../../utils/types";
import {SortOrder} from "antd/lib/table/interface";
import {DefaultPageSize} from "../../../components/table/constants";
import {sortDates, sortStrings} from "../../../utils/sorters";


export type ProjectsStoreState = TableState<Project>;

export const sortProjectsBy = (projects: Project[], sortState: SortState) => {
    const {sortBy, sortDirection} = sortState;
    let result = [...projects];
    if (sortBy === 'createDate' || !sortBy) {
        const direction = sortBy === 'createDate' ? sortDirection : 'descend';
        result = result.sort(({createDate: date1 = ''}: Project, {createDate: date2 = ''}: Project) => {
            const ascResult = sortDates(date1, date2);
            return direction === 'ascend' ? ascResult : (-1 * ascResult);
        });
    } else if (sortBy === 'name') {
        result = result.sort(({name: name1 = ''}: Project, {name: name2 = ''}: Project) => {
            const ascResult = sortStrings(name1, name2);
            return sortDirection === 'ascend' ? ascResult : (-1 * ascResult);
        });
    }

    return result;
}

const projectsSlice = createSlice<ProjectsStoreState, SliceCaseReducers<ProjectsStoreState>>({
    name: 'projects',
    initialState: {
        isLoading: false,
        total: 0,
        page: 0,
        pageSize: DefaultPageSize,
        data: [],
        sort: undefined,
        filters: {}
    },
    reducers: {
        paginationChanged: (state: ProjectsStoreState, {payload: {pageNum, pageSize}}: PayloadAction<{
            pageNum: number,
            pageSize: number
        }>) => {
            state.page = pageNum;
            state.pageSize = pageSize || state.pageSize;
        },
        projectFiltersChanged: (state: ProjectsStoreState, {payload: newFilters}: PayloadAction<TableFilters>) => {
            state.filters = {...newFilters};

            state.page = 0;
        },
        projectSortChanged: (state: ProjectsStoreState, {payload: newSort}: PayloadAction<SortState>) => {
            state.sort = newSort;
            let sortedData = sortProjectsBy(state.data, !!newSort?.sortBy ? newSort : {
                sortBy: 'createDate',
                sortDirection: 'descend'
            });
            console.log(`%c PROJECTS LOADED AND SORTED`, 'color: red');
            console.log(sortedData);
            state.data = sortedData;
        },
        projectChanged: () => {
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadProjectsThunk.pending, (state, action) => {
                state.isLoading = true;
                state.page = 0;
            })
            .addCase(loadProjectsThunk.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                let sortedData = sortProjectsBy(payload.content, !!state.sort?.sortBy ? state.sort : {
                    sortBy: 'createDate',
                    sortDirection: 'descend'
                });
                console.log(`%c PROJECTS LOADED AND SORTED`, 'color: red');
                console.log(sortedData);
                state.data = sortedData;
                state.total = payload.totalElements;
            })
            .addCase(loadProjectsThunk.rejected, (state, action) => {
                state.isLoading = false;
            })
    }
})


export const {
    paginationChanged,
    projectChanged,
    projectFiltersChanged,
    projectSortChanged
} = projectsSlice.actions;
export default projectsSlice.reducer;
