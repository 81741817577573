import React from "react";
import {LoginService} from "backend/services/printdown";
import {RemoteTable} from "components/table/remote-table";
import {logColumns} from "./columns";
import './style.scss';



export const LoginLogs = () => (
        <div className='login-logs-list'>
            <RemoteTable
                columns={logColumns}
                loadDataFn={LoginService.getAllUsers1}
                requestParamsConverter={({pageNum, pageSize}) => ({
                    page: pageNum,
                    size: pageSize
                })}

            />
        </div>
    )
