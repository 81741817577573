
import { createAsyncThunk } from '@reduxjs/toolkit';
import { TableState } from 'store/types';
import {
	ContractorRole, ContractorRoleService, PageContractorRole,
} from 'backend/services/printdown';

export type RolesRequestParams = {pageNum: number, pageSize: number, search?: string, workspaceId: number, all?: boolean};
export const ALL_ROLES_PAGE_SIZE = 1111;

export const loadRolesThunk = createAsyncThunk<PageContractorRole, RolesRequestParams & {workspaceId: number}, {
	state: { roles: TableState<ContractorRole> }
}>(
	'roles/loadByParams',
	async (params ,thunkAPI) => {
		const {workspaceId, pageNum, pageSize, search = ''} = params;
		const isLoadingAllRoles = pageSize === ALL_ROLES_PAGE_SIZE;
		console.log(`%c Load roles for params workspaceId=[${workspaceId}], page=${pageNum}, pageSize=${pageSize}, search=${search}`, isLoadingAllRoles ? 'color: red; background: yellow' :  'color: magenta');
		return ContractorRoleService.getContractorRoleByCompanyId({ workspaceId, pageNum, pageSize, name: search});
	}
);
