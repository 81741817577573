import {ThemeConfig} from "antd";
import locale from "dayjs/locale/*";
import ruRU from "antd/locale/ru_RU";
import {Locale} from "antd/es/locale";

export const AppConfig: {locale: Locale, theme: ThemeConfig} = {
    locale: ruRU,
    theme: {
        token: {
            colorPrimary: '#0169cd',
            colorLink: '#0b59a2',
            // colorLink: 'rgb(53,58,216)',
            colorLinkHover: '#4097ea'
            // colorLink: 'rgb(42, 8, 255)',
            // colorLinkHover: 'rgb(78,92,255)'
        }
    }

}
