import {useCallback, useMemo, useState} from 'react';
import {Button, Input} from 'antd';
import {LoadingOutlined, SaveOutlined} from '@ant-design/icons';

import {
    ContractorRole, ContractorRoleRequest, ContractorRoleService
} from 'backend/services/printdown';
import Loading from 'components/loading';
import {modal} from "global/NotificationsProvider";
import {useLoading} from 'hooks/use-loading';
import {showError, showMessage} from 'utils/notifications';
import {getRandomId} from 'utils/utils';
import styles from './styles.module.scss';

interface RoleModalProps {
    role?: ContractorRole;
    workspaceId: number,
    onClose: () => void;
    onFinish: (isSuccess: boolean) => void;
}

const ROLE_FIELDS: Array<{ dataIndex: keyof ContractorRole, title: string }> = [
    {dataIndex: 'name', title: 'Наименование'}
]

const RoleEditForm = ({onFinish, onClose, workspaceId, role = {id: 0}}: RoleModalProps) => {
    const [values, setValues] = useState<ContractorRole>(role);
    const [loading, showLoading, hideLoading] = useLoading();

    const isEdit = !!role.id;

    const valuesChangeId = useMemo(() => getRandomId(), [
        values.name
    ])

    const isValidForm = useMemo(() => {
        return ROLE_FIELDS.every(({dataIndex}) => !!values[dataIndex])
    }, [valuesChangeId]);


    const editRole = useCallback(() => {
        showLoading();
        const {id, ...newRole} = values;

        newRole.name = newRole.name?.trim();
        ContractorRoleService.updateContractorRole({
            workspaceId,
            roleId: role?.id || 0,
            body: newRole as ContractorRoleRequest
        })
            .then(() => {
                hideLoading();
                onClose();
                showMessage('Роль сохранена');
                onFinish(true);
            })
            .catch(e => {
                hideLoading();
                showError('Не удалось сохранить изменения роли', e);
                onFinish(false);
            })
    }, [valuesChangeId]);

    const createNewRole = useCallback(() => {
        showLoading();
        const {id, ...newRole} = values;
        newRole.name = newRole.name?.trim();
        ContractorRoleService.createContractorRole({
            workspaceId,
            body: newRole as ContractorRoleRequest
        })
            .then(() => {
                hideLoading();
                onClose();
                showMessage('Роль создана');
                onFinish(true);
            })
            .catch(e => {
                hideLoading();
                showError('Не удалось создать роль', e);
                onFinish(false);
            })
    }, [valuesChangeId]);


    return <div className={`company-form ${!isValidForm ? 'invalid' : ''}`}>
        {loading && <Loading/>}
        {ROLE_FIELDS.map(({dataIndex, title}) =>
            <div className='field' key={dataIndex}>
                <div className='field-label'>{title}</div>
                <Input
                    value={(values[dataIndex] || '') as string}
                    onChange={({target: {value}}) => {
                        setValues(prev => ({
                            ...prev,
                            [dataIndex]: value
                        }))
                    }}
                />
            </div>)}
        <div className='modal-buttons'>
            <Button
                type='primary'
                disabled={!isValidForm}
                onClick={isEdit ? editRole : createNewRole}
            >
                {loading ? <LoadingOutlined/> : <SaveOutlined/>}
                {isEdit ? 'Сохранить изменения' : 'Создать роль'}</Button>
            <Button onClick={onClose}>Отмена</Button>
        </div>
    </div>
}

export const showRoleEditModal = ({onFinish, workspaceId, role = {id: 0}}: {
    onFinish: (isSuccess: boolean) => void,
    role?: ContractorRole,
    workspaceId: number
}) => {
    let modalCmp: any = null;

    const closeModal = () => {
        modalCmp?.destroy();
    }

    modalCmp = modal.confirm({
        width: 600,
        closable: true,
        className: `${styles.company_edit_modal} modal-no-btns`,
        title: role.id ? role.name : 'Новая роль',
        content:
            <RoleEditForm
                role={role}
                workspaceId={workspaceId}
                onFinish={onFinish}
                onClose={closeModal}
            />

    });
};
