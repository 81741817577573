import React from 'react';
import {Link} from 'react-router-dom';
import {AndroidOutlined, FileDoneOutlined, SettingOutlined, UserOutlined,DeploymentUnitOutlined} from '@ant-design/icons';
import {NavigationItemType, NavigationSubmenuItemType} from 'navigation/types';
import {OrganizationsList} from 'views/admin/organizations';
import {RolesList} from 'views/admin/roles';
import {Users} from "../views/admin/users";
import {LoginLogs} from "../views/admin/super-admin/logs";
import {EnumUserRequestRole, RoleResponse} from "../backend/services/printdown";
import {userHasPermissions} from "./utils";
import {WorkspacesList} from "../views/admin/super-admin/workspaces";
import {MagicIcon} from "../icons/magic";

export const NavigationItems: Array<NavigationItemType | NavigationSubmenuItemType> = [
    {
        key: '/projects',
        icon: <FileDoneOutlined/>,
        title: 'Проекты'
    },
    {
        key: '/contractors',
        icon: <UserOutlined/>,
        title: 'Подрядчики',
        // component: <ContractorsOfWorkspaceList />
    },
    {
        key: '/roles',
        title: 'Роли подрядчиков',
        icon: <AndroidOutlined/>,
        label: 'Роли подрядчиков',
        component: <RolesList/>
    },
    {

        key: '/admin',
        icon: <SettingOutlined/>,
        label: 'Админка',
        children: [
            {
                key: '/organizations',
                title: 'Организации',
                 permissions: {OR: [EnumUserRequestRole.ADMIN, EnumUserRequestRole.SUPER_ADMIN]},
                component: <OrganizationsList/>
            },
            {
                key: '/users',
                title: 'Пользователи',
                component: <Users/>,
                permissions: {OR: [EnumUserRequestRole.ADMIN, EnumUserRequestRole.SUPER_ADMIN]},
            }
        ]
    },
    {
        key: '/super-admin',
        icon: <MagicIcon />,
        label: 'Супер-админка',
        children: [
            {
                key: '/workspaces',
                title: 'Пространства',
                component: <WorkspacesList/>,
                permissions: [EnumUserRequestRole.SUPER_ADMIN]
            },
            {
                key: '/login-logs',
                title: 'Логи',
                component: <LoginLogs/>,
                permissions: [EnumUserRequestRole.SUPER_ADMIN]
            }
        ]
    }
];

NavigationItems.forEach(item => {
    // @ts-ignore
    if (item.children) {
        // @ts-ignore
        const visibleChildren = item.children.filter(({hidden}) => !hidden);
        // @ts-ignore
        item.children = visibleChildren.map((child: NavigationItem) => ({
            ...child,
            label: <Link to={child.key}>{child.title}</Link>
        }));
    } else {
        item.label = <Link to={item.key}>{(item as NavigationItemType).title}</Link>
    }
});


export const filterNavigationByUserRoles = (navigationItems:Array<NavigationItemType | NavigationSubmenuItemType>, userRoles: RoleResponse[]) => {
    const result: Array<NavigationItemType | NavigationSubmenuItemType> = [];
    navigationItems.forEach(item => {
        let isGrantedItem = false;
        // @ts-ignore
        if (item.children) {
            // @ts-ignore
            const grantedChildren = item.children.filter(({permissions = []}) => userHasPermissions(permissions, userRoles));
           if (grantedChildren.length) {
               result.push({
                   ...item,
                   children: grantedChildren
               })
           }
        } else {
            if (userHasPermissions(item.permissions || [], userRoles)) {
                result.push(item);
            }
        }
    });

    console.log('%c Navigation by permissions', 'color: blue; background: yellow');
    console.log(navigationItems);
    return result;
}

// @ts-ignore
export const SIDEBAR_NAVIGATION = NavigationItems.filter(item => !!(item.children || !item.hidden));
