import React from 'react';
import { IFilterFieldConfig } from 'components/table/filter-form/types';

export const projectContractorFilters = (employmentTypes: React.ReactNode[]): IFilterFieldConfig[] =>  [
	{
		name: 'name',
		title: 'ФИО '
	},
	{
		name: 'type',
		title: 'Тип трудоустройства',
		type: 'select',
		options: employmentTypes
	}
]
