import React, {useCallback, useMemo, useState} from 'react';
import {Provider, useSelector} from 'react-redux';
import {Button, Input, Select} from 'antd';
import {LoadingOutlined, SaveOutlined} from '@ant-design/icons';
import {Company, CompanyService, Project, ProjectService} from 'backend/services/printdown';

import Loading from 'components/loading';
import {modal} from "global/NotificationsProvider";
import {useLoading} from 'hooks/use-loading';
import store, {StoreState} from 'store';
import {showError, showMessage} from 'utils/notifications';
import {getRandomId} from 'utils/utils';
import './styles.scss';
import {useRemoteData} from "../../../../hooks/use-remote-data";
import {getWorkspaceId} from "../../../../store/reducers/auth/selectors";

interface ProjectModalProps {
    project: Project;
    onClose: () => void;
    onFinish: (isSuccess: boolean) => void;
}


const PROJECT_FIELDS: Array<{ dataIndex: keyof Project, title: string }> = [
    {dataIndex: 'name', title: 'Наименование'}
]

const ProjectEditForm = ({onFinish, onClose, project = {id: 0, companyId: 0}}: ProjectModalProps) => {
    const workspaceId = useSelector(getWorkspaceId);
    const {
        user: {isAdmin, isSuperAdmin, id: userId}
    } = useSelector((store: StoreState) => store.auth);

    const {data: organizations, loading: isLoadingOrganizations} = useRemoteData<Company>({
        // @ts-ignore
        loader: CompanyService.getAllCompanies,
        searchParams: {workspaceId}
    })
// @ts-ignore
    const [projectCompanyId, setProjectCompanyId] = useState<number>(project?.companyId || undefined);
    const [values, setValues] = useState<Project>(project);
    const [loading, showLoading, hideLoading] = useLoading();

    const isEdit = !!project.id;

    const valuesChangeId = useMemo(() => getRandomId(), [values.name]);

    const isValidForm = useMemo(() => {
        return PROJECT_FIELDS.every(({dataIndex}) => !!values[dataIndex])
    }, [valuesChangeId]);


    const editProject = useCallback(() => {
        showLoading();
        const {id, ...newProject} = values;
        newProject.name = newProject.name?.trim();
        newProject.userId = userId;
        ProjectService.updateProject({
            companyId: projectCompanyId,
            projectId: project.id || 0,
            body: newProject
        })
            .then(() => {
                hideLoading();
                onClose();
                showMessage('Изменения сохранены');
                onFinish(true);
            })
            .catch(e => {
                hideLoading();
                showError('Не удалось сохранить изменения проекта', e);
                onFinish(false);
            })
    }, [valuesChangeId, projectCompanyId, userId]);

    const createNewProject = useCallback(() => {
        showLoading();
        const {id, ...newProject} = values;

        newProject.name = newProject.name?.trim();
        newProject.userId = userId;
        ProjectService.createProject({
            companyId: projectCompanyId,
            workspaceId: workspaceId || 0,
            body: newProject
        })
            .then(() => {
                hideLoading();
                onClose();
                showMessage('Проект создан');
                onFinish(true);
            })
            .catch(e => {
                hideLoading();
                showError('Не удалось создать проект', e);
                onFinish(false);
            })
    }, [valuesChangeId, projectCompanyId, userId]);

    console.log(organizations);

    return <div className={`company-form ${!isValidForm ? 'invalid' : ''}`}>
        {loading && <Loading/>}
        <div
            className='field'
            key='company'
        >
            <div className='field-label'>Организация</div>
            <Select
                value={projectCompanyId || undefined}
                onChange={setProjectCompanyId}
            >
                {organizations.map(({id, name}: Company) => <Select.Option
                    key={id}
                    value={id}
                >
                    {name}
                </Select.Option>)}
            </Select>

        </div>
        {PROJECT_FIELDS.map(({dataIndex, title}) =>
            <div
                className='field'
                key={dataIndex}
            >
                <div className='field-label'>{title}</div>
                <Input
                    value={(values[dataIndex] || '') as string}
                    onChange={({target: {value}}) => {
                        setValues(prev => ({
                            ...prev,
                            [dataIndex]: value
                        }))
                    }}
                />
            </div>)}
        <div className='modal-buttons'>
            <Button
                type='primary'
                disabled={!isValidForm}
                onClick={isEdit ? editProject : createNewProject}
            >
                {loading ? <LoadingOutlined/> : <SaveOutlined/>}
                {isEdit ? 'Сохранить изменения' : 'Создать проект'}</Button>
            <Button onClick={onClose}>Отмена</Button>
        </div>
    </div>
}

export const showProjectEditModal = ({onFinish, project = {id: 0}}: {
    onFinish: (isSuccess: boolean) => void,
    project?: Project,
    companyId?: number,
}) => {
    let modalCmp: any = null;

    const closeModal = () => {
        modalCmp?.destroy();
    }

    modalCmp = modal.confirm({
        width: 600,
        closable: true,
        className: `project-edit-modal modal-no-btns`,
        title: project.id ? project.name : 'Новый проект',
        content: <Provider store={store}>
            <ProjectEditForm
                project={project}
                onFinish={onFinish}
                onClose={closeModal}
            />
        </Provider>
    });
};
