import Icon from '@ant-design/icons';
import React from 'react';


const MagicSvg = () => <svg viewBox="0 0 32 32"  width='1em' height='1em' xmlns="http://www.w3.org/2000/svg">
    <g data-name="Layer 2" id="Layer_2">
        <path
            fill='currentColor'
            d="M18,11a1,1,0,0,1-1,1,5,5,0,0,0-5,5,1,1,0,0,1-2,0,5,5,0,0,0-5-5,1,1,0,0,1,0-2,5,5,0,0,0,5-5,1,1,0,0,1,2,0,5,5,0,0,0,5,5A1,1,0,0,1,18,11Z"/>
        <path
            fill='currentColor'
            d="M19,24a1,1,0,0,1-1,1,2,2,0,0,0-2,2,1,1,0,0,1-2,0,2,2,0,0,0-2-2,1,1,0,0,1,0-2,2,2,0,0,0,2-2,1,1,0,0,1,2,0,2,2,0,0,0,2,2A1,1,0,0,1,19,24Z"/>
        <path
            fill='currentColor'
            d="M28,17a1,1,0,0,1-1,1,4,4,0,0,0-4,4,1,1,0,0,1-2,0,4,4,0,0,0-4-4,1,1,0,0,1,0-2,4,4,0,0,0,4-4,1,1,0,0,1,2,0,4,4,0,0,0,4,4A1,1,0,0,1,28,17Z"/>
    </g>
</svg>

export const MagicIcon = (props: any) => <Icon className='admin-icon' component={MagicSvg} {...props} />;


