import Icon from '@ant-design/icons';
import React from 'react';


const UsnSVG = () => <svg width="1em" height="1em" viewBox="0 0 1000 1000" fill="currentColor">
    <g >
        <rect width="1em" height="1em" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M982.789 629.384C999.003 568.875 990.515 504.405 959.193 450.155C927.873 395.905 876.283 356.32 815.775 340.107L306.312 203.596C180.311 169.834 50.7967 244.609 17.0345 370.611C0.821309 431.119 9.3093 495.59 40.6306 549.84C71.9519 604.09 123.54 643.675 184.049 659.888L693.512 796.398C754.02 812.612 818.491 804.125 872.74 772.803C926.99 741.482 966.576 689.892 982.789 629.384ZM920.97 612.82C909.15 656.932 880.29 694.543 840.741 717.377C801.19 740.212 754.189 746.399 710.076 734.579L200.613 598.069C156.5 586.249 118.89 557.39 96.0555 517.84C73.2218 478.289 67.0338 431.288 78.8538 387.175C103.467 295.316 197.888 240.802 289.747 265.416C440.784 305.886 648.175 361.456 799.211 401.926C843.323 413.746 880.934 442.604 903.768 482.155C926.602 521.706 932.79 568.707 920.97 612.82ZM536.235 553.523C523.058 561.289 506.954 564.002 491.096 559.753C459.448 551.273 440.85 518.385 449.403 486.464C457.956 454.543 490.506 435.36 522.155 443.84C538.013 448.089 550.603 458.49 558.131 471.804L608.747 443.185C593.786 416.725 568.718 396.119 537.204 387.675C474.691 370.925 410.133 408.362 393.238 471.414C376.344 534.466 413.533 599.167 476.047 615.917C507.56 624.361 539.574 619.05 565.76 603.616L536.235 553.523ZM356.905 348.343L297.197 436.238L276.385 430.662L270.257 325.126L211.092 309.273L220.772 475.958L262.602 487.167L226.638 540.11L286.107 556.044L416.374 364.278L356.905 348.343ZM752.022 537.451L683.309 519.04L704.118 441.378L647.954 426.329L591.286 637.817L647.45 652.866L668.26 575.205L736.973 593.616L716.163 671.278L772.329 686.327L828.997 474.84L772.831 459.79L752.022 537.451Z" fill="currentColor"/>
    </g>
</svg>


export const UsnIcon = (props: any) => <Icon className='inn-icon' component={UsnSVG} {...props} />;


