
import { createAsyncThunk } from '@reduxjs/toolkit';
import {TableFilters, TableState} from 'store/types';
import {
	 ContractorResponse,
	ContractorRole, ContractorService,
} from 'backend/services/printdown';


export const loadContractorsThunk = createAsyncThunk<ContractorResponse[], {workspaceId: number, pageNum: number, pageSize: number, filters?: TableFilters}, {
	state: { roles: TableState<ContractorRole> }
}>(
	'contractors/loadByParams',
	async ({workspaceId, pageNum, pageSize, filters},thunkAPI) => {
		return ContractorService.findBy1({ ...filters, workspaceId, page: pageNum,size: pageSize});
	}
);
