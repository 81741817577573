import {createSlice, PayloadAction, SliceCaseReducers} from '@reduxjs/toolkit';
import {
    Company,
    ContractorRole,
    ContractorService,
    DocumentService,
    DocumentsSetService,
    Project
} from 'backend/services/printdown';
import {showError} from 'utils/notifications';
import {DictionaryState, DictionaryType, Dictionary} from 'store/reducers/dictionaries/types';
import {DictionaryItem} from "../../../utils/types";


const dictionarySlice = createSlice<DictionaryState, SliceCaseReducers<DictionaryState>>({
    name: 'dictionary',
    initialState: {
        employmentType: [],
        documentTypes: {}
    },
    reducers: {
        dictionaryLoaded: (state: DictionaryState, {payload}: PayloadAction<{
            dictionaryName: DictionaryType,
            data: Dictionary
        }>) => {
            state[payload.dictionaryName] = payload.data;
        }
    }
})


export const {
    dictionaryLoaded
} = dictionarySlice.actions;


export const loadDictionaries = (dispatch: any) => {
    ContractorService.getFileTypes()
        .then((data: DictionaryItem[]) => {
            dispatch(dictionaryLoaded({dictionaryName: 'employmentType', data}));

            const employmentTypesPromises = data.map(({name}) => DocumentsSetService.getAllDocuments1({contractorType: name}));
            Promise.all(employmentTypesPromises)
                .then(results => {
                    const map: Record<string, DictionaryItem[]> = {};
                    results.forEach(loadedFileTypes => {
                        if (loadedFileTypes.length) {
                            map[loadedFileTypes[0].contractorType] = loadedFileTypes;
                        }
                    });

                    dispatch(dictionaryLoaded({dictionaryName: 'documentTypes', data: map}));
                })
                .catch(() => {

                });
        })
        .catch(e => {
            showError('Не удалось загрузить типы трудоустройства', e);
        })
}
export default dictionarySlice.reducer;
