import React from 'react';
import {Button, Popconfirm, Tooltip} from 'antd';
import {ColumnsType} from 'antd/lib/table';
import {EditOutlined, DeleteOutlined} from '@ant-design/icons';
import {
    ContractorResponse,
    DocumentResponseShort,
    Project,
    ProjectAndRole
} from 'backend/services/printdown';
import {Link} from 'react-router-dom';
import {renderContractorDocuments} from "../../../utils/renderers";
import {BotIcon} from "../../../icons/bot";
import {dateTimeRenderer} from "../../../utils/utils";


const PROJECTS_DISPLAY_COUNT = 2;
type ShortProjectInfo = {
    projectId: number | string,
    projectName: string
};
export const contractorColumns = ({ onDeleteClick = null}: any): ColumnsType<ContractorResponse> => {

    return [
        {
            dataIndex: 'id',
            title: 'ID'
        },
        {
            title: 'ФИО',
            dataIndex: 'name',
        },
        {
            title: 'Тип',
            dataIndex: 'typeName',
        },
        // {
        //     title: 'Телефон',
        //     dataIndex: 'phone',
        // },
        {
            title: 'Телеграм',
            dataIndex: 'telegram',
        },
        {
            title: 'Дата создания',
            dataIndex: 'createDate',
            render: (date: string) => dateTimeRenderer(date)
        },
        {
            title: 'Автор',
            dataIndex: 'userId',
            render: (userId: number, {userName = ''}: ContractorResponse) => {
                let authorName: string | React.ReactNode = '';

                if (userId === 0) {
                    authorName = <div className='bot-icon'><BotIcon /></div>
                } else if (userId > 0) {
                    const [lastName, firstName] = userName.split(' ');
                    authorName = userName ? `${lastName} ${firstName ? `${firstName[0].toUpperCase()}.` : ''}` : String(userId);
                }


                return <Tooltip title={`${userName} (${userId})`} mouseEnterDelay={0.3}>
                    <div className='contractor-author'>{authorName}</div>
                </Tooltip>
            }
        },
        {
            title: 'Проект',
            dataIndex: 'projectAndRoles',
            render: (projectAndRoles: ProjectAndRole[] = []) => {
                const uniqueProjectsMap = projectAndRoles.reduce((accum, project) => ({
                    ...accum,
                    [project.projectId || 0]: project.projectName
                }), {});

                const uniqueProjects: ShortProjectInfo[] = Object.entries(uniqueProjectsMap).map(([projectId, projectName]) => ({
                    projectId,
                    projectName
                }));

                const projectsToShow = uniqueProjects.slice(0, PROJECTS_DISPLAY_COUNT);
                const restProjects = Math.max(0, uniqueProjects.length - projectsToShow.length);

                return <div>
                    {
                        projectsToShow.map(({projectId, projectName}, index) => (
                                <Link
                                    to={`/projects/${projectId}`}
                                    key={projectId} style={{marginRight: 5, whiteSpace: 'nowrap'}}
                                    state={{openedFrom: '/contractors'}}
                                    onClick={(ev) => {
                                        ev.stopPropagation();
                                    }}>
                                    {projectName}{index < projectsToShow.length - 1 ? ', ' : ''}
                                </Link>
                            )
                        )}
                    {restProjects > 0 && <span>ещё {restProjects}</span>}
                </div>
            }

        },
        {
            title: 'Документы',
            dataIndex: 'documents',
            render: (docs: DocumentResponseShort[] = [], record: ContractorResponse) => {
                return renderContractorDocuments(docs)
            }
        }].concat(onDeleteClick ? [
        {
            title: '',
            dataIndex: 'actions',
            render: (value: any, record: ContractorResponse) => (
                <>
                    {/*<Button*/}
                    {/*    size='small'*/}
                    {/*    onClick={(ev) => {*/}
                    {/*        ev.stopPropagation();*/}
                    {/*        onEditClick(record);*/}
                    {/*    }}*/}
                    {/*><EditOutlined/></Button>*/}
                    <Popconfirm
                        title='Удалить подрядчика?'
                        onConfirm={(ev?) => {
                            ev?.stopPropagation();
                            onDeleteClick(record);
                        }}
                        onCancel={(ev?) => {
                            ev?.stopPropagation();
                        }}
                    >
                        <Button
                            style={{marginLeft: 10}}
                            size='small'
                            /*@ts-ignore*/
                            disabled={!!record.projectId}
                            onClick={(ev) => {
                                ev.stopPropagation();
                            }}
                        ><DeleteOutlined/></Button>
                    </Popconfirm>
                </>
            )
        }
    ] : []).map(column => ({
        ...column,
        className: column.dataIndex
    }));
}
