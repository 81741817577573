import {DocumentResponseShort} from "../backend/services/printdown";
import React from "react";
import {DocumentsEnum} from "./constants";
import {PassportIcon} from "../icons/documents/passport";
import {InnIcon} from "../icons/documents/inn";
import {OgrnipIcon} from "../icons/documents/ogrnip";
import {BirthCertificateIcon} from "../icons/documents/bith_cert";
import {SnilsIcon} from "../icons/documents/snils";
import {PassportChildIcon} from "../icons/documents/passport_child";
import {BankRequisitesIcon} from "../icons/documents/bank";
import {NpdIcon} from "../icons/documents/npd";
import {UsnIcon} from "../icons/documents/usn";
import {Tooltip} from "antd";

import {CheckCircleTwoTone, CheckCircleOutlined, CheckOutlined} from '@ant-design/icons';
const DocumentNamesShort: Record<string, string> = {
    OGRNIP: 'ОГРНИП',
    USN: 'Ув. о применении УСН',
    NPD: 'Справка о прим. НПД',
    BANK_DETAILS: 'Банк. реквизиты'

}

export const getDocumentIcon = (docType: string, docName: string, className?: string): React.ReactNode => {
    switch (docType) {
        case DocumentsEnum.PASSPORT: {
            return <PassportIcon className={className}/>;
        }
        case DocumentsEnum.INN: {
            return <InnIcon className={className}/>;
        }
        case DocumentsEnum.OGRNIP: {
            return <OgrnipIcon className={className}/>;
        }
        case DocumentsEnum.BIRTH_CERTIFICATE: {
            return <BirthCertificateIcon className={className}/>;
        }
        case DocumentsEnum.SNILS: {
            return <SnilsIcon className={className}/>;
        }
        case DocumentsEnum.PASSPORT_CHILD: {
            return <PassportChildIcon className={className}/>;
        }
        case DocumentsEnum.BANK_DETAILS: {
            return <BankRequisitesIcon className={className}/>;
        }
        case DocumentsEnum.NPD: {
            return <NpdIcon className={className}/>;
        }
        case DocumentsEnum.USN: {
            return <UsnIcon className={className}/>;
        }
        default : {
            return <span className={`doc-name ${className}`}>{docName[0].toUpperCase()}</span>
        }
    }
}

export const renderContractorDocuments = (documents: DocumentResponseShort[]) => {
    const notFilledDocs = documents.filter(({documentId = 0}) => !documentId);

    return <div className='contractor-documents-icons'>
        {notFilledDocs.length ? notFilledDocs.map(({
                                                       documentId = 0,
                                                       documentTypeName = '',
                                                       documentTypeCode = ''
                                                   }, index) => {
                const className = `${documentTypeCode} ${!documentId ? 'not-filled' : ''}`;
                return <Tooltip title={documentTypeName}>
                    {getDocumentIcon(documentTypeCode, documentTypeName, className)}
                </Tooltip>
            }
        ) : <CheckOutlined style={{color: 'rgb(51, 181, 12)', fontSize: '16px', marginLeft: 8}}/>}
    </div>
}
