import Icon from '@ant-design/icons';
import React from 'react';


const BotSvg = () =>
    <svg viewBox="0 0 640 512" width='1em' height='1em' fill='currentColor'>
        <path
            d="M9.375 233.4C3.375 239.4 0 247.5 0 256v128c0 8.5 3.375 16.62 9.375 22.62S23.5 416 32 416h32V224H32C23.5 224 15.38 227.4 9.375 233.4zM464 96H352V32c0-17.62-14.38-32-32-32S288 14.38 288 32v64H176C131.8 96 96 131.8 96 176V448c0 35.38 28.62 64 64 64h320c35.38 0 64-28.62 64-64V176C544 131.8 508.3 96 464 96zM256 416H192v-32h64V416zM224 296C201.9 296 184 278.1 184 256S201.9 216 224 216S264 233.9 264 256S246.1 296 224 296zM352 416H288v-32h64V416zM448 416h-64v-32h64V416zM416 296c-22.12 0-40-17.88-40-40S393.9 216 416 216S456 233.9 456 256S438.1 296 416 296zM630.6 233.4C624.6 227.4 616.5 224 608 224h-32v192h32c8.5 0 16.62-3.375 22.62-9.375S640 392.5 640 384V256C640 247.5 636.6 239.4 630.6 233.4z"/>
    </svg>
// <svg viewBox="0 0 35 35" width='1em' height='1em'>
//     <path
//         fill='currentColor'
//         d="M31.51,28h-28a3.17,3.17,0,0,1-2.36-1.09A3.63,3.63,0,0,1,.28,24.1C1.48,14.76,8.88,7.72,17.5,7.72s16,7,17.22,16.38a3.63,3.63,0,0,1-.85,2.84A3.17,3.17,0,0,1,31.51,28Zm-14-17.81c-7.37,0-13.7,6.11-14.74,14.2a1.12,1.12,0,0,0,.25.87.64.64,0,0,0,.48.24h28a.64.64,0,0,0,.48-.24,1.12,1.12,0,0,0,.25-.87C31.2,16.33,24.87,10.22,17.5,10.22Z"/>
//     <path
//         fill='currentColor'
//         d="M27.38,12.74a1.17,1.17,0,0,1-.6-.16,1.24,1.24,0,0,1-.5-1.69l1.77-3.27a1.25,1.25,0,1,1,2.2,1.2l-1.77,3.26A1.26,1.26,0,0,1,27.38,12.74Z"/>
//     <path
//         fill='currentColor'
//         d="M7.68,12.74a1.26,1.26,0,0,1-1.1-.66L4.81,8.82A1.25,1.25,0,1,1,7,7.62l1.77,3.27a1.25,1.25,0,0,1-.5,1.69A1.2,1.2,0,0,1,7.68,12.74Z"/>
//     <path fill='currentColor' d="M10.79,20.7a1.88,1.88,0,0,0,0-3.75,1.88,1.88,0,0,0,0,3.75Z"/>
//     <path fill='currentColor'  d="M24.05,20.78a1.88,1.88,0,0,0,0-3.75,1.88,1.88,0,0,0,0,3.75Z"/>
// </svg>

// <svg fill="currentColor" stroke="currentColor" strokeLinecap="round"
//      strokeLinejoin="round" strokeWidth="1" viewBox="0 0 24 24" width="1em" height='1em'>
//     <path d="M0 0h24v24H0z" fill="currentColor" stroke="none"/>
//     <path
//         d="M7 7h10a2 2 0 0 1 2 2v1l1 1v3l-1 1v3a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-3l-1 -1v-3l1 -1v-1a2 2 0 0 1 2 -2z"/>
//     <path d="M10 16h4"/>
//     <circle cx="8.5" cy="11.5" fill="currentColor" r=".5"/>
//     <circle cx="15.5" cy="11.5" fill="currentColor" r=".5"/>
//     <path d="M9 7l-1 -4"/>
//     <path d="M15 7l1 -4"/>
// </svg>

// <svg enableBackground='new 0 0 128 128' viewBox="0 0 128 128" width='1em' height='1em'>
//     <g>
//         <path
//             fill='currentColor'
//             d="M101.2,3.2l-9.6,9.6C84.3,6.1,74.6,2,64,2C53.4,2,43.7,6.1,36.4,12.7l-9.6-9.6l-5.7,5.7l9.8,9.8c-5,6.8-8,15.2-8,24.3h8   c0-18.2,14.8-33,33-33s33,14.8,33,33h8c0-9.1-3-17.5-8-24.3l9.8-9.8L101.2,3.2z"/>
//         <rect fill='currentColor'
//               height="8" width="8" x="50" y="27"/>
//         <rect height="8" width="8" x="70" y="27" fill='currentColor'/>
//         <rect fill='currentColor' height="45" width="8" x="7"
//               y="51"/>
//         <path
//             fill='currentColor'
//             d="M93,127v-19h12V51H23v57h12v19h24v-19h10v19H93z M51,100v19h-8v-19H31V59h66v41H85v19h-8v-19H51z"/>
//         <rect
//             fill='currentColor'
//             height="45" width="8" x="113" y="51"/>
//     </g>
// </svg>
//
// <svg
//     enableBackground="new 0 0 800 800"
//     height="1em"
//
//     viewBox="0 0 800 800"
//     width="1em">
//     <path
//         d="M675.898,407.053v-8.208c0-64.812-52.729-117.539-117.537-117.539H418.936v-50.221  c31.634-8.388,55.018-37.252,55.018-71.483c0-40.781-33.173-73.953-73.952-73.953c-40.778,0-73.956,33.172-73.956,73.953  c0,34.231,23.386,63.096,55.017,71.483v50.221H241.639c-64.811,0-117.539,52.728-117.539,117.539v8.208  c-45.9,8.871-80.675,49.351-80.675,97.797c0,48.509,34.849,89.014,80.819,97.831c3.067,62.093,54.55,111.671,117.395,111.671  h316.723c62.843,0,114.323-49.578,117.392-111.671c45.971-8.817,80.822-49.322,80.822-97.831  C756.575,456.402,721.801,415.924,675.898,407.053z M124.1,563.604c-24.803-8.013-42.797-31.315-42.797-58.755  c0-27.434,17.994-50.735,42.797-58.75V563.604z M363.922,159.602c0-19.893,16.183-36.078,36.078-36.078  c19.893,0,36.078,16.186,36.078,36.078S419.893,195.68,400,195.68C380.105,195.68,363.922,179.493,363.922,159.602z   M638.026,596.813L638.026,596.813c-0.001,43.927-35.74,79.661-79.665,79.661H241.639c-43.928,0-79.664-35.734-79.664-79.661  V398.845c0-43.925,35.736-79.661,79.664-79.661h316.723c43.925,0,79.664,35.736,79.664,79.661V596.813z M675.898,563.604V446.1  c24.802,8.015,42.799,31.316,42.799,58.75C718.697,532.288,700.7,555.592,675.898,563.604z"
//         fill="currentColor"/>
//     <path
//         d="M538.119,400.611c-30.806,0-55.865,25.063-55.865,55.865c0,30.806,25.061,55.866,55.865,55.866  c30.804,0,55.867-25.062,55.867-55.866C593.986,425.675,568.923,400.611,538.119,400.611z M538.119,474.466  c-9.92,0-17.99-8.065-17.99-17.988c0-9.92,8.07-17.993,17.99-17.993c9.921,0,17.992,8.073,17.992,17.993  C556.111,466.399,548.04,474.466,538.119,474.466z"
//         fill="currentColor"/>
//     <path
//         d="M261.881,400.611c-30.807,0-55.866,25.063-55.866,55.865c0,30.806,25.06,55.866,55.866,55.866  c30.803,0,55.866-25.062,55.866-55.866C317.747,425.675,292.684,400.611,261.881,400.611z M261.881,474.466  c-9.92,0-17.991-8.065-17.991-17.988c0-9.92,8.071-17.993,17.991-17.993c9.919,0,17.991,8.073,17.991,17.993  C279.872,466.399,271.8,474.466,261.881,474.466z"
//         fill="currentColor"/>
//     <path
//         d="M451.604,571.221H348.395c-10.46,0-18.939,8.479-18.939,18.934c0,10.461,8.479,18.938,18.939,18.938h103.211  c10.457,0,18.937-8.479,18.937-18.938C470.542,579.698,462.063,571.221,451.604,571.221z"
//         fill="currentColor"/>
// </svg>

export const BotIcon = (props: any) => <Icon className='bot-icon' component={BotSvg} {...props} />;


