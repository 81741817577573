import {TablePaginationConfig} from "antd";
import {IS_DEBUG} from "../../utils/constants";

export const DefaultPageSize = 50;

export const PaginationConfig: TablePaginationConfig = {
    size: 'small',
    position: ['topRight'],
    showSizeChanger: true,
    pageSizeOptions: IS_DEBUG ? [2, 5, 10, 20, 50] : [10, 20, 50, 100, 200],
    locale: {items_per_page: '/ стр'},
    showTotal: (total, range) => `${range[0]}-${range[1]} из ${total}`,
}
