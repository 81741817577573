import Icon from '@ant-design/icons';
import React from 'react';


const BankRequisitesSVG = () => <svg width="1em" height="1em" viewBox="0 0 1000 1000" version="1.1"   style={{fillRule:'evenodd',clipRule:'evenodd',strokeLinejoin:'round',strokeMiterlimit:2, fill: 'currentColor'}}>
    <path d="M790.146,915.482C820.141,915.482 848.909,903.567 870.119,882.356C891.329,861.146 903.245,832.379 903.245,802.383L903.245,197.617C903.245,167.621 891.329,138.854 870.119,117.644C848.909,96.433 820.141,84.518 790.146,84.518L209.854,84.518C179.859,84.518 151.091,96.433 129.881,117.644C108.671,138.854 96.755,167.621 96.755,197.617L96.755,802.383C96.755,832.379 108.671,861.146 129.881,882.356C151.091,903.567 179.859,915.482 209.854,915.482L790.146,915.482ZM790.146,851.482L209.854,851.482C196.832,851.482 184.344,846.309 175.136,837.102C165.928,827.894 160.755,815.405 160.755,802.383C160.755,802.383 160.755,197.617 160.755,197.617C160.755,184.595 165.928,172.106 175.136,162.898C184.344,153.691 196.832,148.518 209.854,148.518L790.146,148.518C803.168,148.518 815.656,153.691 824.864,162.898C834.072,172.106 839.245,184.595 839.245,197.617C839.245,197.617 839.245,802.383 839.245,802.383C839.245,815.405 834.072,827.894 824.864,837.102C815.656,846.309 803.168,851.482 790.146,851.482ZM237.024,778.501L495.004,778.501L495.004,714.501L237.024,714.501L237.024,778.501ZM237.024,657.558L589.16,657.558L589.16,593.558L237.024,593.558L237.024,657.558ZM279.36,325.764L237.024,325.764L237.024,384.915L279.36,384.915L279.36,406.375L237.024,406.375L237.024,465.526L279.36,465.526L279.36,507.389L338.512,507.389L338.512,465.526L443.71,465.526L443.71,406.375L338.512,406.375L338.512,384.915L398.504,384.915C420.174,384.915 440.957,376.307 456.28,360.984C471.603,345.661 480.211,324.878 480.211,303.208C480.211,303.208 480.211,303.207 480.211,303.206C480.211,281.536 471.603,260.753 456.28,245.43C440.957,230.107 420.174,221.499 398.504,221.499L279.36,221.499L279.36,325.764ZM338.512,280.651L338.512,325.764L398.504,325.764C404.486,325.764 410.223,323.387 414.453,319.157C418.683,314.927 421.059,309.19 421.059,303.208L421.059,303.206C421.059,297.224 418.683,291.487 414.453,287.257C410.223,283.027 404.486,280.651 398.504,280.651L338.512,280.651Z"/>
</svg>


export const BankRequisitesIcon = (props: any) => <Icon className='inn-icon' component={BankRequisitesSVG} {...props} />;


