import Icon from '@ant-design/icons';
import React from 'react';


const DuckSvg = () => <svg className="svg-icon"
    // width='1em'
    // height='1em'
                           style={{
                               width: '1em',
                               height: '1em',
                               verticalAlign: 'middle',
                               fill: 'currentColor',
                               overflow: 'hidden'
                           }}
                           viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M505.129245 708.684284c-22.056538 0-39.938031 17.881493-39.93803 39.943425v208.760359h-74.93775c-18.393934 0-33.305966 14.912032-33.305965 33.305966s14.912032 33.305966 33.305965 33.305966h121.510542c18.393934 0 33.305966-14.912032 33.305966-33.305966v-242.066325c0-22.059235-17.881493-39.943425-39.940728-39.943425z"
        fill="#B36F0F"/>
    <path
        d="M673.903027 708.684284c-22.056538 0-39.938031 17.881493-39.938031 39.943425v208.760359h-74.937749c-18.393934 0-33.305966 14.912032-33.305966 33.305966s14.912032 33.305966 33.305966 33.305966h121.510542c18.393934 0 33.305966-14.912032 33.305966-33.305966v-242.066325c0-22.059235-17.881493-39.943425-39.940728-39.943425z"
        fill="#D98712"/>
    <path
        d="M924.163014 355.124289c-21.304059 0-38.576017 17.271958-38.576017 38.576016v12.18261c-41.100463-36.893053-95.416508-59.356847-154.991816-59.356847h-161.060197v-106.129221c0-101.196303-73.478641-185.185378-169.979366-201.65631l3.411778-3.411778a17.800581 17.800581 0 0 0-25.17164-25.17164l-13.091518 13.088822v-5.448057a17.797884 17.797884 0 0 0-35.595768 0v21.14763c-95.942435 16.937523-168.846602 100.654195-168.846603 201.45403v271.353675c0 165.165118 133.898128 299.063247 299.063247 299.063247h271.26737c128.210033 0 232.14385-103.936514 232.14385-232.14385v-184.969614c0.002697-21.306756-17.269261-38.578714-38.57332-38.578713z"
        fill="#FFB648"/>
    <path
        d="M789.620289 465.943694a8.784317 8.784317 0 0 1 8.784317 8.78162l0.051244 0.002697c-4.62006 123.608853-106.288348 222.404775-231.027268 222.404774s-226.407208-98.795922-231.024571-222.404774l0.051244-0.002697a8.78162 8.78162 0 0 1 8.78162-8.78162h444.383414z"
        fill="#FF9E16"/>
    <path
        d="M285.453893 250.516207H122.716127c-33.939774 0-61.45246 27.512686-61.452461 61.455158 0 33.937077 27.512686 61.449763 61.452461 61.449763h162.737766c33.937077 0 61.455157-27.512686 61.455158-61.449763-0.002697-33.942472-27.51808-61.455157-61.455158-61.455158z"
        fill="#D98712"/>
    <path d="M355.151259 197.494749m-21.49555 0a21.49555 21.49555 0 1 0 42.9911 0 21.49555 21.49555 0 1 0-42.9911 0Z"
          fill="#333E48"/>
</svg>;

export const DuckIcon = (props: any) => <Icon className='admin-icon' component={DuckSvg} {...props} />;


