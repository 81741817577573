import React, {useCallback, useContext, useMemo, useState} from 'react';
import {Button, Card, Divider, Input, Typography} from 'antd';
import {LockOutlined, UserOutlined} from '@ant-design/icons';

import Loading from 'components/loading';
// import { login }IAuthData,  from 'backend/services/auth';
import {AuthData, ICredentials, ServerError} from 'utils/types';
import {showError} from 'utils/notifications';

import styles from 'views/auth/login/style.module.scss';
import {authBackend} from "../../../backend/axios";
import {useDispatch} from "react-redux";
import {loginStarted, loginSuccess} from "../../../store/reducers/auth";
import {getUserData} from "./helpers";

// @ts-ignore

const Login = (): JSX.Element => {
    // const { onGetToken } = useContext(AuthContext);
    const dispatch = useDispatch();
    const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false);
    const [credentials, setCredentials] = useState<ICredentials>({login: '', password: ''});

    const isValidForm = useMemo(() => {
        return !!(credentials.login && credentials.password);
    }, [credentials.login, credentials.password]);

    const doLogin = useCallback((credentials: any, onFinish: any) => {
        // @ts-ignore
        dispatch(loginStarted());
        //const formData = new FormData();
        //formData.append('client_id', credentials.login);
        //formData.append('client_secret', credentials.password);
        // formData.append('grant_type', 'client_credentials');

        authBackend.post('/login', credentials)
            .then((resp) => {
                onFinish(true, resp.data);
            }).catch(error => {

            onFinish(false, error);
        });
    }, []);

    const loginCallback = useCallback(() => {
        setIsLoggingIn(true);
        const mailIndex = credentials.login.indexOf('@');
        let loginWithoutDomain = mailIndex > -1 ? credentials.login.slice(0, mailIndex) : credentials.login;
        doLogin({
            email: credentials.login,
            password: credentials.password
        }, (isSuccess: boolean, loginData: AuthData | ServerError) => {
            setIsLoggingIn(false);
            if (isSuccess) {
                const loggedUser = loginData as AuthData;
                // @ts-ignore
                getUserData({
                    id: loggedUser.userId,
                    token: loggedUser.access_token,
                     workspaceId: loggedUser.workspaceId
                });
            } else {
                const serverError = loginData as ServerError;
                // В случае ошибки в data будет объект ошибки от бэка
                const {response: {data: error = ''} = {data: ''}} = serverError;
                showError('Не удалось авторизоваться', error as ServerError);
            }
        });
    }, [credentials.password, credentials.login]);


    return (
        <div className={styles.login_container}>

            <Card
                className={styles.card}
                title='Вход'
                onKeyDown={(ev) => {
                    const isEnterKeyPressed = ev.keyCode === 13 || String(ev.key).toLowerCase() === 'enter';
                    if (credentials.login && credentials.password && isEnterKeyPressed) {
                        loginCallback();
                    }
                }}
            >
                <div className={styles.content}>
                    {
                        isLoggingIn && <Loading text='Выполняем вход...'/>
                    }
                    <Input
                        style={{marginBottom: 16}}
                        className={!credentials.login ? 'invalid' : ''}
                        value={credentials.login}
                        placeholder='Email'
                        prefix={<UserOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}
                        onChange={({target: {value: newLogin}}) => {
                            setCredentials(prevCredentials => ({
                                ...prevCredentials,
                                login: newLogin
                            }))
                        }}
                    />
                    <Input
                        style={{marginBottom: 16}}
                        placeholder='Пароль'
                        className={!credentials.password ? 'invalid' : ''}
                        type='password'
                        value={credentials.password}
                        prefix={<LockOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}
                        onChange={({target: {value: newPassword}}) => {
                            setCredentials(prevCredentials => ({
                                ...prevCredentials,
                                password: newPassword
                            }));
                        }}
                    />
                    <div className={styles.button_container}>
                        <Button
                            className={styles.ok_button}
                            disabled={!isValidForm}
                            type='primary'
                            onClick={loginCallback}
                        >
                            Войти
                        </Button>
                    </div>

                    <div className='hint' style={{marginTop: 32, color: 'gray'}}>За помощью обращайтесь на &nbsp;
                        <Typography.Link
                            copyable={{tooltips: ['Скопировать', 'Скопировано']}}>docduckio@yandex.ru</Typography.Link>
                    </div>
                </div>
            </Card>
        </div>
    );
}

export default Login;
