import React, {useCallback, useMemo, useRef} from 'react';
import {Provider, useSelector} from 'react-redux';
import {useNavigate} from "react-router-dom";
import {Button} from 'antd';
import {PlusOutlined} from '@ant-design/icons';
import {ContractorResponse, ContractorService, Project} from 'backend/services/printdown';
import {RemoteTable} from 'components/table/remote-table';
import {modal} from "global/NotificationsProvider";
import {useEmploymentOptions} from 'hooks/use-employment-options';
import {useLoading} from 'hooks/use-loading';
import {getWorkspaceId} from 'store/reducers/auth/selectors';
import {showError, showMessage} from 'utils/notifications';
import {ServerListResponse} from 'utils/types';
import {ProjectContractorEditForm} from './edit-project-contractor-modal';
import {ProjectContractorAddForm} from './add-project-contractor-modal';
import {contractorColumns} from './columns';
import {projectContractorFilters} from './filters';
import './styles.scss';

export const ContractorsInProjectList = ({projectId, projectName}: { projectId: number, projectName: string }) => {
    const workspaceId = useSelector(getWorkspaceId);
    const [employmentTypes] = useEmploymentOptions();
    const [loading, showLoading, hideLoading] = useLoading();
    const tableRef = useRef(null);
    const navigate = useNavigate();

    const reloadTable = useCallback(() => {
        // @ts-ignore
        tableRef.current?.reloadTable();
    }, [tableRef.current]);

    const responseConverter = useCallback(({
                                               content = [],
                                               totalElements = 0
                                           }: ServerListResponse<ContractorResponse>) => ({
        data: content.map((item) => {
            const role = item.projectAndRoles?.length ? item.projectAndRoles[0] : {roleId: 0, roleName: ''};
            return ({
                ...item,
                key: `${item.id}-${role.roleId}`,
                roleId: role.roleId,
                roleName: role.roleName
            })
        }),
        total: totalElements
    }), []);

    const convertRequestParams = useCallback(({pageNum, pageSize, ...requestParams}: any) => ({
        ...requestParams,
        page: pageNum,
        size: pageSize,
        workspaceId,
        projectId
    }), [projectId, workspaceId]);

    const showContractorEditModal = useCallback((record: ContractorResponse = {id: 0}) => {
        //редактирование контрактора  доступно только из "списка"
        // из проектов можно менять только роль
        let modalInstance: any = null;

        const closeModal = () => {
            modalInstance?.destroy();
        }

        modalInstance = modal.confirm({
            width: 600,
            closable: true,
            className: `contractor-in-project-edit-modal modal-no-btns`,
            title: record.id ? record.name : 'Добавление подрядчика',
            content: record.id ? <ProjectContractorEditForm
                projectId={projectId}
                contractor={record}
                onFinish={reloadTable}
                onClose={closeModal}
            /> : <ProjectContractorAddForm
                onFinish={reloadTable}
                projectId={projectId}
                onClose={closeModal}
            />
        });
    }, [reloadTable]);


    const onAddClick = useCallback(() => {
        showContractorEditModal({id: 0});
    }, [showContractorEditModal]);

    const onEditClick = useCallback((record: ContractorResponse) => {
        showContractorEditModal(record);
    }, [showContractorEditModal]);

    const onDeleteClick = useCallback((record: ContractorResponse) => {
        showLoading();
        ContractorService.removeFromProject({
            projectId: projectId || 0,
            contractorId: record.id || 0,
            /*@ts-ignore*/
            roleId: record.roleId || 0
        })
            .then(() => {
                hideLoading();
                showMessage(`Подрядчик "${record.name}" удалён из проекта`);
                reloadTable();
            })
            .catch(e => {
                showError(`Не удалось удалить подрядчика "${record.name}"`, e);
                hideLoading();
            })
    }, [reloadTable]);

    const onRow = useCallback((record: ContractorResponse) => ({
        onClick: (ev: any) => {
            navigate(`/contractors/${record.id}`, {
                state: {
                    contractorName: record.name,
                    role: record.roleName,
                    project: projectName,
                    openedFrom: `/projects/${projectId}`,
                    contractorType: record.type
                }
            })
        }
    }), []);

    const tableColumns = useMemo(() => contractorColumns({
        onEditClick,
        onDeleteClick
    }), [onEditClick, onDeleteClick]);

    const filters = useMemo(() => projectContractorFilters(employmentTypes), [employmentTypes.length]);
    return (
        <div className='contractors-list'>
            <RemoteTable
                toolbar={<Button
                    type='link'
                    onClick={onAddClick}
                >
                    <PlusOutlined/>
                    Добавить подрядчика в проект
                </Button>}
                ref={tableRef}
                rowKey='key'
                loadDataFn={ContractorService.findBy}
                requestParamsConverter={convertRequestParams}
                columns={tableColumns}
                filters={filters}
                responseDataConverter={responseConverter}
                onRow={onRow}

            />
        </div>
    )
}
