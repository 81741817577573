import React from 'react';
import {Button, Popconfirm} from 'antd';
import {ColumnsType} from 'antd/lib/table';
import {EditOutlined, DeleteOutlined} from '@ant-design/icons';
import {ContractorResponse, DocumentResponseShort,} from 'backend/services/printdown';
import {renderContractorDocuments} from "../../../../utils/renderers";


export const contractorColumns = ({onEditClick, onDeleteClick}: any): ColumnsType<ContractorResponse> => [
    {
        title: 'ФИО',
        dataIndex: 'name',
    },

    {
        title: 'Роль',
        dataIndex: 'roleName',
    },
    {
        title: 'Тип',
        dataIndex: 'typeName',
    },
    {
        title: 'Телефон',
        dataIndex: 'phone',
    },
    {
        title: 'Телеграм',
        dataIndex: 'telegram',
    },
    {
        title: 'Документы',
        dataIndex: 'documents',
        render: (docs: DocumentResponseShort[] = [], record: ContractorResponse) => renderContractorDocuments(docs)
    },
    // {
    // 	title: 'Описание',
    // 	dataIndex: 'description',
    // },
    {
        dataIndex: 'actions',
        width: 100,
        render: (value: any, record: ContractorResponse) => (
            <>
                <Button
                    size='small'
                    onClick={(ev) => {
                        ev.stopPropagation();
                        onEditClick(record);
                    }}
                ><EditOutlined/></Button>
                <Popconfirm
                    title='Убрать подрядчика из проекта?'
                    onCancel={(ev) => {
                        ev?.stopPropagation();
                    }}
                    onConfirm={(ev) => {
                        ev?.stopPropagation();
                        onDeleteClick(record);
                    }}
                >
                    <Button
                        style={{marginLeft: 10}}
                        size='small'
                        onClick={(ev) => {
                            ev.stopPropagation();
                        }}
                    ><DeleteOutlined/></Button>
                </Popconfirm>
            </>
        )
    }
].map(column => ({
    ...column,
    className: column.dataIndex
}));
