import React, {useEffect, useMemo, useState} from 'react';
import {Select} from "antd";
import {useLoading} from "../../../hooks/use-loading";
import {
    EnumUserRequestRole,
    Project,
    ProjectService,
    ProjectsService,
    RoleResponse,
    UsersService
} from "../../../backend/services/printdown";
import {useSelector} from "react-redux";
import {StoreState} from "../../../store";
import {showError} from "../../../utils/notifications";
import {getWorkspaceId} from "../../../store/reducers/auth/selectors";

// interface UserRole {
//     roleCode: string,
//     roleName: string;
//     description: string
// }

export function useUserRoleOptions(): [React.ReactNode[], RoleResponse[], boolean] {
    const [loading, showLoading, hideLoading] = useLoading();
    const {user: {isSuperAdmin}} = useSelector((state: StoreState) => state.auth);
    const [userRoles, setRoles] = useState<RoleResponse[]>([]);

    // @ts-ignore
    const roleOptions = useMemo(() => userRoles.map(({roleCode, roleName}) => (
        <Select.Option
            key={roleCode}
            value={roleCode}
            disabled={roleCode === EnumUserRequestRole.SUPER_ADMIN && !isSuperAdmin}>
            {roleName}
        </Select.Option>
    )), [userRoles.length, isSuperAdmin]);

    useEffect(() => {
        showLoading();
        UsersService.getAllRoles()
            .then((data: RoleResponse[]) => {
                const roles = [...data];
                hideLoading();
                // const superAdminIndex = roles.findIndex(({roleCode}) => roleCode === EnumUserRequestRole.SUPER_ADMIN);
                // if (superAdminIndex > -1) {
                //     roles.splice(superAdminIndex, 1);
                // }
                setRoles(roles);
            })
            .catch(e => {
                showError('Не удалось загрузить список ролей для пользователей', e);
                hideLoading();
            })
    }, []);

    return [roleOptions, userRoles, loading];
}

export function useProjectOptions(): [React.ReactNode[]] {
    const [loading, showLoading, hideLoading] = useLoading();
    const workspaceId = useSelector(getWorkspaceId);
    const [projectsOfWorkspace, setProjectsOfWorkspace] = useState<Project[]>([]);

    // @ts-ignore
    const projectOptions = useMemo(() => projectsOfWorkspace.map(({id, name}) => <Select.Option key={id}
                                                                                                value={id}>{name}</Select.Option>), [projectsOfWorkspace.length]);

    useEffect(() => {
        showLoading();
        ProjectService.getAllProjects({workspaceId, size: 100, page: 0})
            .then((data) => {
                hideLoading();
                setProjectsOfWorkspace(data.content);
            })
            .catch(e => {
                showError('Не удалось загрузить список проектов для пользователей', e);
                hideLoading();
            })
    }, []);

    return [projectOptions];
}
