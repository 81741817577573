import React from 'react';
import { IFilterFieldConfig } from 'components/table/filter-form/types';

export const getContractorFilters = ({ projects, employmentTypeOptions}: { projects: React.ReactNode[],employmentTypeOptions:React.ReactNode[]}):IFilterFieldConfig[]  => [
	{
		name: 'name',
		title: 'ФИО '
	},
	{
		name: 'type',
		title: 'Тип трудоустройства',
		type: 'select',
		options: employmentTypeOptions
	},
	{
		name: 'projectId',
		title: 'Проект',
		type: 'select',
		options: projects
	},
]
