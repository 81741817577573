import React, {useCallback, useMemo, useState} from 'react';
import {CheckOutlined, CloseOutlined, EditOutlined, LoadingOutlined} from "@ant-design/icons";
import {Button, Form, Typography} from 'antd';
import {BankDetailsRequest, DocumentResponse, DocumentService} from 'backend/services/printdown';
import {useLoading} from "hooks/use-loading";
import {showError} from "utils/notifications";
import {getRandomId} from "utils/utils";
import FormField, {ValueType} from "../passport-form/form/form-field";
import styles from './style.module.scss';
import Loading from "../../loading";
import {ACCOUNT_REGEX} from "../../../utils/constants";

export const AccountFields: {
    label: string,
    name: keyof BankDetailsRequest,
    maxLength?: number,
    required?: boolean,
    regex?: RegExp
}[] = [
    {
        label: 'Получатель',
        name: 'recipientName',
    },
    {
        label: 'Расчётный счёт',
        name: 'accountNumber',
        maxLength: 20,
        regex: ACCOUNT_REGEX
    },
    {
        label: 'БИК',
        name: 'bic',
        maxLength: 9
    },
    {
        label: 'Банк-Получатель',
        name: 'recipientBank'
    },
    {
        label: 'Корр. счёт',
        name: 'correspondentAccount',
        maxLength: 20,
        regex: ACCOUNT_REGEX
    },
    {
        label: 'ИНН',
        name: 'inn',
        required: false
    },
    {
        label: 'КПП',
        name: 'kpp',
        required: false
    }
]


export interface BankDocumentData extends BankDetailsRequest {
    documentId?: number,
    documentTypeId?: number,
    documentTypeCode?: string,
    contractorId?: number,
}

export const isValidBankInfo = (document: BankDetailsRequest) => AccountFields.every(({
                                                                                          name,
                                                                                          required = true
                                                                                      }) => !required || !!document[name]);

export const BankRequisites = ({values: document, contractorId, onSuccessSave}: {
    values: BankDetailsRequest,
    contractorId: number,
    onSuccessSave: () => void,
}) => {
    const [isEdit, startEdit, cancelEdit] = useLoading();
    const [loading, showLoading, hideLoading] = useLoading();

    const [values, setValues] = useState<BankDocumentData>({...document});

    const [prevValues] = useState<BankDocumentData>({...document});

    const initialValuesChangeId = useMemo(() => getRandomId(), [
        document.recipientName, document.recipientBank, document.bic, document.kpp,
        document.inn, document.accountNumber, document.correspondentAccount
    ]);

    const valuesChangeId = useMemo(() => getRandomId(), [
        values.recipientName, values.recipientBank, values.bic, values.kpp,
        values.inn, values.accountNumber, values.correspondentAccount
    ]);

    const isValidForm = useMemo(() => isValidBankInfo(values), [valuesChangeId]);

    const isFilledInfo = useMemo(() => isValidBankInfo(document), [initialValuesChangeId]);

    const onChangeField = useCallback((fieldName: string, value: ValueType) => {
        setValues(prev => ({
            ...prev,
            [fieldName as keyof BankDocumentData]: value as string
        }))
    }, []);

    const cancelEditing = useCallback(() => {
        setValues(prevValues);
        cancelEdit();
    }, [initialValuesChangeId]);

    const saveChanges = useCallback(() => {
        showLoading();
        const requestBody = {
            documentTypeId: values.documentTypeId || 0,
            bankDetailsRequest: values as BankDetailsRequest
        };
        const promise = !!values?.documentId ? DocumentService.updateDocument({
            documentId: values?.documentId || 0,
            body: requestBody
        }) : DocumentService.addDocument({
            contractorId: contractorId || 0,
            body: requestBody
        });

        promise.then(() => {
            hideLoading();
            cancelEdit();
            onSuccessSave();
        }).catch(e => {
            hideLoading();
            showError('Не удалось сохранить данные паспорта', e);
            cancelEditing();
        })
    }, [valuesChangeId, values?.documentId, values?.documentTypeId, contractorId]);

    const fieldProps = {
        readOnly: !isEdit,
        onChange: onChangeField
    }
    return (
        <div className={styles.bank_form}>
            {loading && <Loading/>}
            <div className='buttons'>
                {!isEdit && <Button type='link'
                                    onClick={startEdit}><EditOutlined/> {isFilledInfo ? 'Редактировать' : 'Заполнить'}
                </Button>}
                {isEdit && <>
                    <Button
                        type='link'
                        style={{marginRight: 10}}
                        disabled={!isValidForm}
                        onClick={saveChanges}>
                        {loading ? <LoadingOutlined/> : <CheckOutlined/>}
                        Сохранить
                    </Button>
                    <Button type='link' onClick={cancelEditing}><CloseOutlined/> Отмена</Button>
                </>}
            </div>
            <div className={styles.account_info}>
                <div className='form'>
                    {
                        AccountFields.map(({label, name, maxLength = 100, required = true, regex = undefined}) => (
                                <FormField
                                    required={required}
                                    hint={maxLength < 100 ? `Не более ${maxLength} цифр` : ''}
                                    key={name}
                                    name={name}
                                    label={label}
                                    maxLength={maxLength}
                                    regex={regex}
                                    {...fieldProps}
                                    value={values[name] as string}
                                />
                            )
                        )}
                </div>
            </div>
        </div>
    );
}
