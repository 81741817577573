
import { createAsyncThunk } from '@reduxjs/toolkit';
import {ServerListResponse, SortState, UserWorkspaceParams} from 'utils/types';
import {TableFilters, TableState} from 'store/types';
import { CompanyService, Project, ProjectService, ProjectsService } from 'backend/services/printdown';

// @ts-ignore
const ProjectSortFieldNames: Record<keyof Project, string> = {
	name: "NAME",
	createDate: "CREATE_DATE"
}
export const loadProjectsThunk = createAsyncThunk<ServerListResponse<Project>, UserWorkspaceParams & {filters?: TableFilters, sort?: SortState}, {
	state: { projects: TableState<Project> }
}>(
	'projects/loadByParams',
	async ({companyId = 0,workspaceId, userId, filters, sort}: UserWorkspaceParams& {filters?: TableFilters,sort?: SortState}, thunkAPI) => {
		const requestParams: any = {
			workspaceId,
			userId,
			pageNum: 0, pageSize:100,
			...filters
		}

		if (companyId > 0) {
			requestParams.companyId = companyId;
		}

		if (sort?.sortBy) {
			requestParams.sortBy = ProjectSortFieldNames[sort.sortBy as keyof Project] || '';
			requestParams.orderBy = sort.sortDirection === 'ascend' ? 'ASC' : 'DESC';
		}
		return ProjectService.getAllProjects(requestParams);
	}
);
