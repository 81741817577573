import axios from 'axios';
import qs from 'qs';

import { serviceOptions } from 'backend/services/printdown';
import store from "../store";
import {logout} from "../store/reducers/auth";
import {showError} from "../utils/notifications";

const queryParamsConverter = (params: any) => qs.stringify(params, { arrayFormat: 'repeat' });

export const axiosNotAuthorizedInterceptor = (error: any) => {
	// Reject promise if usual error
	if (error.response && (error.response.status === 401 || error.response.status === 403)) {
		showError('Время сессии истекло. Пожалуйста, авторизуйтесь заново');
		/*@ts-ignore*/
		store.dispatch(logout());
	}
	return Promise.reject(error);
};


const printdownBackend = axios.create({
	baseURL: `${process.env.REACT_APP_BACKEND_URL}/api`,
	timeout: 5 * 60 * 1000,
	timeoutErrorMessage: 'Превышено время ожидания ответа сервера',
	paramsSerializer: queryParamsConverter,
});

// printdownBackend.interceptors.response.use((response) => response, axiosNotAuthorizedInterceptor);

serviceOptions.axios = printdownBackend;

export const authBackend = axios.create({
	baseURL: `${process.env.REACT_APP_AUTH_URL}/api`,
	timeout: 5 * 60 * 1000,
	timeoutErrorMessage: 'Превышено время ожидания ответа сервера',
	paramsSerializer: queryParamsConverter
});



export default printdownBackend;
