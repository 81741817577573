import React from 'react';
import { RouteConfig } from 'navigation/types';
import { NavigationItems } from 'navigation';



export const ROUTES: RouteConfig[] = NavigationItems.reduce((accum: RouteConfig[], parentNode: any) => {
	return parentNode.children ? accum.concat(parentNode.children) : accum.concat(parentNode);
}, [])
	//если в описании роута нет компонента, значит это сложный вложенный роут
	// и он будет описан отдельно уже в App.tsx
	.filter(({component}) => !!component);



console.log('App routes');
console.log(ROUTES);
