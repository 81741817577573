import {UserRequest, UserResponse, UsersService} from "../../../backend/services/printdown";
import {ActionFinishCallback} from "../../../utils/types";
import {showError, showMessage} from "../../../utils/notifications";

export const sendInvitationToUser = (user: UserResponse, onFinish: ActionFinishCallback ) => {
    UsersService.sendInvitation({userid: String(user.id || 0)})
        .then(() => {
            showMessage(`Приглашение для ${user.name} отправлено!`);
            onFinish(true);
        })
        .catch(e => {
            showError(`Не удалось отправить приглашение для ${user.name}!`, e);
            onFinish(false);
        });

}
