import {useCallback, useMemo, useState} from 'react';
import {Provider} from 'react-redux';
import {Button, ConfigProvider, Input} from 'antd';
import {LoadingOutlined, SaveOutlined} from '@ant-design/icons';

import {Workspace, WorkspaceRequest, WorkspaceService} from 'backend/services/printdown';
import Loading from 'components/loading';
import {modal} from "global/NotificationsProvider";
import {useLoading} from 'hooks/use-loading';
import store from 'store';
import {showError, showMessage} from 'utils/notifications';
import {getRandomId} from 'utils/utils';
import styles from './styles.module.scss';

interface WorkspaceModalProps {
    workspace: Workspace;
    onClose: () => void;
    onFinish: (isSuccess: boolean) => void;
}

const WORKSPACE_FIELDS: Array<{ dataIndex: keyof Workspace, title: string }> = [
    {dataIndex: 'name', title: 'Наименование'}
]


const WorkspaceEditForm = ({onFinish, onClose, workspace = {id: 0, name: ''}}: WorkspaceModalProps) => {
    // const { user: { workspaceId } } = useSelector((state: StoreState) => state.auth);
    const [values, setValues] = useState<Workspace>(workspace);
    const [loading, showLoading, hideLoading] = useLoading();

    const isEdit = !!workspace.id;

    const valuesChangeId = useMemo(() => getRandomId(), [values.name]);

    const editWorkspace = useCallback(() => {
        showLoading();
        const {id, ...newWorkspace} = values;
        const requestData = newWorkspace as WorkspaceRequest;

        WorkspaceService.updateWorkspace({
            workspaceId: workspace.id || 0,
            body: requestData
        })
            .then(() => {
                hideLoading();
                onClose();
                showMessage('Изменения сохранены');
                onFinish(true);
            })
            .catch(e => {
                hideLoading();
                showError('Не удалось сохранить изменения пространства', e);
                onFinish(false);
            })
    }, [valuesChangeId]);

    const createNewWorkspace = useCallback(() => {
        showLoading();
        const {id, ...newWorkspace} = values;
        const requestData = newWorkspace as WorkspaceRequest;

        WorkspaceService.createWorkspace({
            body: requestData
        })
            .then(() => {
                hideLoading();
                onClose();
                showMessage('Пространство создано');
                onFinish(true);
            })
            .catch(e => {
                hideLoading();
                showError('Не удалось создать пространство', e);
                onFinish(false);
            })
    }, [valuesChangeId]);


    return <div className={`company-form ${!values.name ? 'invalid' : ''}`}>
        {loading && <Loading/>}
        {WORKSPACE_FIELDS.map(({dataIndex, title}) =>
            <div
                className='field'
                key={dataIndex}
            >
                <div className='field-label'>{title}</div>
                <Input
                    value={(values[dataIndex] || '') as string}
                    onChange={({target: {value}}) => {
                        setValues(prev => ({
                            ...prev,
                            [dataIndex]: value
                        }))
                    }}
                />
            </div>)}
        <div className='modal-buttons'>
            <Button
                type='primary'
                disabled={!values.name}
                onClick={isEdit ? editWorkspace : createNewWorkspace}
            >
                {loading ? <LoadingOutlined/> : <SaveOutlined/>}
                {isEdit ? 'Сохранить изменения' : 'Создать пространство'}</Button>
            <Button onClick={onClose}>Отмена</Button>
        </div>
    </div>
}

export const showWorkspaceEditModal = ({onFinish, workspace = {id: 0, name: ''}}: {
    onFinish: (isSuccess: boolean) => void,

    workspace?: Workspace
}) => {
    let modalCmp: any = null;

    const closeModal = () => {
        modalCmp?.destroy();
    }

    modalCmp = modal.confirm({
        width: 680,
        closable: true,
        className: `${styles.company_edit_modal} modal-no-btns`,
        title: workspace.id ? workspace.name : 'Новое пространство',
        content: <Provider store={store}>
            <WorkspaceEditForm
                workspace={workspace}
                onFinish={onFinish}
                onClose={closeModal}
            />
        </Provider>
    });
};
