import {useCallback, useMemo, useRef} from 'react';
import {Button} from 'antd';
import {PlusOutlined} from '@ant-design/icons';

import {RemoteTable} from 'components/table/remote-table';
import {Workspace, WorkspaceService} from 'backend/services/printdown';
import {useLoading} from 'hooks/use-loading';
import {showError, showMessage} from 'utils/notifications';
import {workspaceColumns} from './columns';
import {showWorkspaceEditModal} from './workspace-modal';
import './styles.scss';

const rowClassName = (record: Workspace) => record.active ? 'active' : '';

export const WorkspacesList = () => {
    const [loading, showLoading, hideLoading] = useLoading();
    const tableRef = useRef(null);

    const reloadTable = useCallback(() => {
        // @ts-ignore
        tableRef.current?.reloadTable();
    }, [tableRef.current]);

    const onAddClick = useCallback(() => {
        showWorkspaceEditModal({
            onFinish: reloadTable
        });
    }, [reloadTable]);

    const onEditClick = useCallback((record: Workspace) => {
        showWorkspaceEditModal({
            onFinish: reloadTable,
            workspace: record
        });
    }, [reloadTable]);

    const onDeleteClick = useCallback((record: Workspace) => {
        showLoading();
        WorkspaceService.deleteWorkspace({
            workspaceId: record.id || 0
        })
            .then(() => {
                hideLoading();
                showMessage('Пространство удалено');
                reloadTable();
            })
            .catch(e => {
                showError('Не удалось удалить пространство', e);
                hideLoading();
            })
    }, [reloadTable]);


    const tableColumns = useMemo(() => workspaceColumns({
        onDeleteClick
    }), [ onDeleteClick]);

    return (
        <div className='workspaces-list'>
            <RemoteTable
                toolbar={<Button
                    type='link'
                    onClick={onAddClick}
                > <PlusOutlined/>Добавить пространство</Button>}
                ref={tableRef}
                loadDataFn={WorkspaceService.getAllWorkspaces}
                columns={tableColumns}
                rowClassName={rowClassName}
                onRow={(record: Workspace) => ({
                    onClick: () => {
                        if (record.active) {
                            onEditClick(record);
                        }
                    }
                })}
            />
        </div>
    )
}
