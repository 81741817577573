import React from 'react';
import {Button, Popconfirm, Tooltip} from 'antd';
import {ColumnsType} from 'antd/lib/table';
import {EditOutlined, DeleteOutlined, MailOutlined, AimOutlined} from '@ant-design/icons';
import {
    AvailableProjectResponse,
    Project,
    ProjectAndRole,
    RoleResponse,
    UserResponse
} from 'backend/services/printdown';
import {Link} from "react-router-dom";


export const userColumns = ({
                                onEditClick,
                                onDeleteClick,
                                sendInvitation,
                                isSuperAdmin
                            }: any): ColumnsType<UserResponse> => [
    {
        title: 'ФИО',
        dataIndex: 'name',
    },
    {
        title: 'Почта',
        dataIndex: 'email'
    },
    {
        title: 'Роль',
        dataIndex: 'role',
        render: (role: RoleResponse) => role?.roleName
    },
    {
        title: 'Проекты',
        dataIndex: 'projects',
        render: (projects: AvailableProjectResponse[] = []) => {
            const projectsToShow = projects.slice(0, 3);
            const restProjects = Math.max(0, projects.length - projectsToShow.length);

            return <div>
                {
                    projectsToShow.map(({id, name}, index) => (
                            <Link
                                to={`/projects/${id}`}
                                key={id} style={{marginRight: 5, whiteSpace: 'nowrap'}}
                                state={{openedFrom: '/users'}}
                                onClick={(ev) => {
                                    ev.stopPropagation();
                                }}>
                                {name}{index < projectsToShow.length - 1 ? ', ' : ''}
                            </Link>
                        )
                    )}
                {restProjects > 0 && <span>ещё {restProjects}</span>}
            </div>
        }

    },
    {
        dataIndex: 'actions',
        width: 160,
        render: (value: any, record: UserResponse) => (
            <>

                <Popconfirm
                    title='Отправить приглашение на почту?'
                    onCancel={(ev: React.MouseEvent<HTMLElement> | undefined) => {
                        ev?.preventDefault();
                    }}
                    onConfirm={(ev: React.MouseEvent<HTMLElement> | undefined) => {
                        ev?.preventDefault();
                        sendInvitation(record);
                    }}
                >
                    <Tooltip title='Пригласить в ДокДак'>
                        <Button
                            size='small'
                            onClick={(ev: React.MouseEvent<HTMLElement> | undefined) => {
                                ev?.preventDefault();
                            }}
                        >
                            <MailOutlined/>
                        </Button>
                    </Tooltip>
                </Popconfirm>
                <Tooltip title='Редактировать'>
                    <Button
                        size='small'
                        onClick={(ev: React.MouseEvent<HTMLElement> | undefined) => {
                            ev?.preventDefault();
                            onEditClick(record);
                        }}
                    ><EditOutlined/></Button>
                </Tooltip>

                <Popconfirm
                    title={isSuperAdmin ? 'Отвязать пользователя от пространства?' : 'Удалить пользователя'}
                    onCancel={(ev: React.MouseEvent<HTMLElement> | undefined) => {
                        ev?.preventDefault();
                    }}
                    onConfirm={(ev: React.MouseEvent<HTMLElement> | undefined) => {
                        ev?.preventDefault();
                        onDeleteClick(record);
                    }}
                >
                    <Tooltip title={isSuperAdmin ? 'Отвязать от пространства' : 'Удалить'}>
                        <Button
                            // style={{ marginLeft: 10 }}
                            size='small'
                        ><DeleteOutlined/></Button>
                    </Tooltip>
                </Popconfirm>
                {
                    isSuperAdmin && <Popconfirm
                        title='Удалить пользователя?'
                        onCancel={(ev: React.MouseEvent<HTMLElement> | undefined) => {
                            ev?.preventDefault();
                        }}
                        onConfirm={(ev: React.MouseEvent<HTMLElement> | undefined) => {
                            ev?.preventDefault();
                            onDeleteClick(record, true);
                        }}
                    >
                        <Tooltip title='Удалить'>
                            <Button
                                className='kill-btn'
                                // style={{ marginLeft: 10 }}
                                size='small'
                            ><AimOutlined/></Button>
                        </Tooltip>
                    </Popconfirm>
                }
            </>
        )
    }
].map(column => ({
    ...column,
    className: column.dataIndex
}));
