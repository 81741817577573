import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { IUserData } from 'utils/types';

export const EMPTY_USER: IUserData = { id: -1, userName: '', token: '', roles: [], isAdmin: false, workspaceId: 0, workspaceName: '', workspaces: [],isSuperAdmin: false};

export interface AuthStoreState {
	companyId: number | null,
	companyName: string,
	projectId: number | null,
	workspaceId: number | null,
	workspaceName: string,
	user: IUserData,
	isCheckingToken: boolean,
	isLoadingUser: boolean,
	isLoggingIn: boolean,
	isUserLoggedIn: boolean,
}

const authSlice = createSlice<AuthStoreState, SliceCaseReducers<AuthStoreState>>({
	name: 'auth',
	initialState: {
		user: EMPTY_USER,
		companyName: '',
		workspaceId: null,
		workspaceName: '',
		companyId: null,
		projectId: null,
		isCheckingToken: true,
		isLoadingUser: false,
		isLoggingIn: false,
		isUserLoggedIn: false,
	},
	reducers: {
		loginStarted: (state: AuthStoreState) => {
			state.isLoggingIn = true;
		},
		loginSuccess: (state: AuthStoreState, { payload }: PayloadAction<IUserData>) => {
			state.isLoggingIn = false;
			state.isUserLoggedIn = true;
			state.isCheckingToken = false;
			state.user = payload;
			state.workspaceId = payload.workspaceId || 0;
			state.workspaceName = payload.workspaceName || '';
		},
		loginError: (state: AuthStoreState, { payload }: PayloadAction<any>) => {
			state.isLoggingIn = false;
			state.isUserLoggedIn = false;
			state.isCheckingToken = false;
		},
		logout: (state: AuthStoreState) => {
			state.isUserLoggedIn = false;
			state.isCheckingToken = false;
			state.user = EMPTY_USER
			state.projectId = null;
			state.companyId = null;
			state.workspaceId = null;
		},
		workspaceChanged: (state: AuthStoreState, { payload: {id, name} }: PayloadAction<{id: number, name: string}>) => {
			state.user.workspaceId = id;
			state.user.workspaceName = name;
		},
		companyChanged: (state: AuthStoreState, { payload: {id, name} }: PayloadAction<{id: number, name: string}>) => {
			state.companyId = id;
			state.companyName = name;
		},
		selectedProjectChanged: (state: AuthStoreState, { payload: projectId }: PayloadAction<number>) => {
			state.projectId = projectId;
		}
	},
})

export const { loginStarted, loginSuccess, loginError, logout, companyChanged, selectedProjectChanged,workspaceChanged } = authSlice.actions;

export default authSlice.reducer;
