import { createSlice, PayloadAction, SliceCaseReducers, } from '@reduxjs/toolkit';
import { Company, ContractorResponse, ContractorRole, Project } from 'backend/services/printdown';
import {TableFilters, TableState} from 'store/types';
import { loadProjectsThunk } from 'store/reducers/projects/thunks';
import { loadOrganizationsThunk } from 'store/reducers/organizations/thunks';
import { loadRolesThunk } from 'store/reducers/roles/thunks';
import { loadContractorsThunk } from 'store/reducers/contractors/thunks';
import {ProjectsStoreState} from "../projects";
import {DefaultPageSize} from "../../../components/table/constants";


const contractorsSlice = createSlice<TableState<ContractorResponse>, SliceCaseReducers<TableState<ContractorResponse>>>({
	name: 'roles',
	initialState: {
		isLoading: false,
		total: 0,
		page: 0,
		pageSize: DefaultPageSize,
		data: [],
		filters: {}
	},
	reducers: {
		contractorChanged: () => {
		},
		contractorPaginationChanged: (state: TableState<ContractorResponse>, { payload: { pageNum, pageSize } }: PayloadAction<{
			pageNum: number,
			pageSize: number
		}>) => {
			state.page = pageNum;
			state.pageSize = pageSize || state.pageSize;
		},
		contractorFiltersChanged: (state: TableState<ContractorResponse>, { payload: newFilters }: PayloadAction<TableFilters>) => {
			state.filters = {
				// ...state.filters,
				...newFilters
			};

			state.page = 0;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(loadContractorsThunk.pending, (state, action) => {
				state.isLoading = true;
			})
			.addCase(loadContractorsThunk.fulfilled, (state, { payload }) => {
				state.isLoading = false;
				// @ts-ignore
				const { content = [], totalElements = 0 } = payload;
				state.data = content;
				state.total = totalElements
			})
			.addCase(loadContractorsThunk.rejected, (state, action) => {
				state.isLoading = false;
			})
	}
})


export const {
	contractorChanged,contractorPaginationChanged,contractorFiltersChanged
} = contractorsSlice.actions;
export default contractorsSlice.reducer;
