import React, {useCallback, useMemo, useState} from 'react';
import {Badge, Button, Upload} from "antd";
import {RcFile} from "antd/es/upload";
import {
    EditOutlined,
    CheckOutlined,
    CloseOutlined,
    LoadingOutlined,
    PlusOutlined,
    StarFilled,
    StarTwoTone
} from "@ant-design/icons";
import {Dayjs} from "dayjs";
import {PassportFormValues} from "backend/services/printdown";
import Loading from "components/loading";
import {useLoading} from "hooks/use-loading";
import {uploadFilesToDocument} from "utils/services";
import {getRandomId} from "utils/utils";
import {PassportFields} from "./form";
import {recognizePassport, savePassport} from "./services";
import {PassportDocRequest, PassportDocValues} from "./types";
import {convertPassportDatesToMoment, EmptyRcFile} from "./utils";
import styles from './styles.module.scss';

export interface PassportFormProps {
    contractorId: number,
    disabled?: boolean;
    onSuccessSave: (props?: { loadPassportFiles: 'CHILD' | 'MAIN' }) => void,
    values: PassportDocRequest,
}

const PassportForm = ({values: initialValues, disabled = false, contractorId, onSuccessSave}: PassportFormProps) => {
    const [isEdit, startEdit, cancelEdit] = useLoading();
    const [loading, showLoading, hideLoading] = useLoading();
    const [isRecognizing, showIsRecognizing, hideIsRecognizing] = useLoading();
    const [values, setValues] = useState<PassportDocValues>(convertPassportDatesToMoment(initialValues));
    const [prevValues] = useState<PassportFormValues>(convertPassportDatesToMoment(initialValues));
    const [photo, setPhoto] = useState<RcFile>(EmptyRcFile);
    const [passportWasRecognized, setPassportWasRecognized] = useState(false);

    const isFilledPassport = useMemo(() => values?.documentId && values.documentId > 0, [values?.documentId]);

    const valuesChangeId = useMemo(() => getRandomId(), [
        values.sex, values.address, values.number, values.birthPlace,
        values.issue, values.issueDate?.toString(), values.birthDate?.toString(),
        values.firstName, values.lastName, values.middleName, values.unitCode
    ])

    const onChangeField = useCallback((fieldName: string, value: string | Dayjs) => {
        setValues(prev => ({
            ...prev,
            [fieldName as keyof PassportFormValues]: value
        }))
    }, []);

    const recognizeDoc = useCallback((photo: RcFile) => {
        showIsRecognizing();
        recognizePassport(photo, (isSuccess, recognizedValues) => {
            hideIsRecognizing();
            if (recognizedValues) {
                setPassportWasRecognized(true);
                setValues({
                    ...recognizedValues,
                    documentId: values.documentId || 0,
                    documentTypeId: values.documentTypeId || 0,
                    documentTypeCode: values.documentTypeCode || '',
                });
                startEdit();
            }

        });
    }, [values.documentTypeId, values.documentTypeCode]);

    const cancelEditing = useCallback(() => {
        setValues(prevValues);
        cancelEdit();
    }, [prevValues.number]);

    const saveChanges = useCallback(() => {
        showLoading();
        savePassport(values, contractorId, (isSuccess, createdDoc) => {
            cancelEdit();
            // @ts-ignore
            if (isSuccess && createdDoc?.documentId) {
                if (passportWasRecognized) {
                    uploadFilesToDocument(createdDoc.documentId || 0, values.documentTypeCode || '', [photo], (isSuccess) => {
                        hideLoading();
                        onSuccessSave({
                            loadPassportFiles: values.documentTypeCode === 'PASSPORT' ? 'MAIN' : 'CHILD'
                        });
                    });
                } else {
                    hideLoading();
                    onSuccessSave();
                }
            } else {
                hideLoading();
            }
        });
    }, [valuesChangeId, values?.documentId, values?.documentTypeId, isFilledPassport, passportWasRecognized]);


    const fieldProps = useMemo(() => ({
        disabled: isRecognizing,
        readOnly: !isEdit,
        onChange: onChangeField,
        required: true
    }), [isRecognizing, isEdit, onChangeField]);

    return (
        <div className={`${styles.passport_form} passport-form ${isEdit ? '' : 'readonly'}`}>
            <div className='buttons'>

                {isEdit && <>
                    <Button type='link' style={{marginRight: 10}} onClick={saveChanges}>
                        {loading ? <LoadingOutlined/> : <CheckOutlined/>} Сохранить
                    </Button>
                    <Button type='link' onClick={cancelEditing}>
                        <CloseOutlined/> Отмена
                    </Button>
                </>}
                {!isEdit && <>
                    <Button type='link' onClick={startEdit}>
                        <EditOutlined/> {isFilledPassport ? 'Редактировать' : 'Заполнить вручную'}
                    </Button>
                    <Upload
                        fileList={photo.uid ? [photo] : []}
                        showUploadList={false}
                        beforeUpload={(newFile, newFileList) => {
                            setPhoto(newFile);
                            recognizeDoc(newFile);
                            return false;
                        }}
                        onRemove={() => {
                            setPhoto(EmptyRcFile);
                        }}
                    >
                        <Button
                            size='small'
                            type='link'
                            className='recognize-btn'
                            style={{padding: 0, marginRight: 16}}
                        >
                            <StarFilled />
                            Распознать по фотографии
                    </Button>
                    </Upload>

                </>}
            </div>
            {isRecognizing && <Loading text='Распознаём паспорт'/>}
            {loading && <Loading text='Сохраняем данные паспорта'/>}
            <PassportFields values={values} fieldProps={fieldProps}/>
        </div>
    );
}

export default PassportForm;
