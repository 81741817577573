import {useCallback, useEffect, useState} from 'react';
import {useLoading} from "./use-loading";
import {ServerListResponse} from "../utils/types";
import {showError} from "../utils/notifications";

export interface ArrayData <T> {
    data: T[],
    total: number
}


export function useRemoteData<T>({loader, searchParams = {}, errorMsg = 'Не удалось загрузить данные'}: {
    errorMsg?: string,
    searchParams?: any,
    loader: (params: any) => Promise<ServerListResponse<T>>
}): {data: T[], total: number, loading: boolean, loadData: (params: any) => void } {
    const [loading, showLoading, hideLoading] = useLoading();
    const [data, setData] = useState<ArrayData<T>>({data: [], total: 0});

    const loadData = useCallback(() => {
        showLoading();
        loader(searchParams)
            .then(response => {
                hideLoading();
                if (Array.isArray(response)) {
                    setData({
                        data: response,
                        total: response.length
                    })
                } else {
                    const {content = [], totalElements = 0} = response;
                    setData({
                        data: content,
                        total: totalElements
                    })
                }
            })
            .catch(e => {
                showError(errorMsg);
            });
    }, [JSON.stringify(searchParams)]);

    useEffect(() => {
        loadData();
    },[]);

    return {data: data.data, total: data.total, loading, loadData};
}
