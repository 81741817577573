import React from 'react';
import {Select} from 'antd';

export const IS_DEBUG = process.env.NODE_ENV === 'development';
export const SERVER_DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_FORMAT = 'DD.MM.YYYY';
export const GENDER_OPTIONS = [
    <Select.Option
        key='М'
        value='М'
    >М</Select.Option>,
    <Select.Option
        key='Ж'
        value='Ж'
    >Ж</Select.Option>
]

export const INN_REGEX = /^\d{12}$/;
export const SNILS_REGEX = /^\d{3}-\d{3}-\d{3}\s\d{2}$/;

export const NUMBER_REGEX = /^[-,0-9]+$/;
export const PASSPORT_SERIES_NUMBER_REGEX = /^(\d{4}|\d{2}\s\d{2})\s\d{6}$/;
export const ACCOUNT_REGEX = /^\d{20}$/;


export enum DocumentsEnum {
    PASSPORT = 'PASSPORT',
    PASSPORT_CHILD = 'PASSPORT_CHILD',
    INN = 'INN',
    SNILS = 'SNILS',
    BANK_DETAILS = 'BANK_DETAILS',
    BIRTH_CERTIFICATE = 'BIRTH_CERTIFICATE',
    OGRNIP = 'OGRNIP',
    NPD = 'NPD',
    USN = 'USN'
}
