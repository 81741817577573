import React, { useCallback, useMemo, useState } from 'react';
import { Provider, useSelector } from 'react-redux';
import { Button, Modal, Select } from 'antd';
import { LoadingOutlined, SaveOutlined } from '@ant-design/icons';

import {
	ContractorResponse, ContractorService,UpdateAttachedContractorRequest
} from 'backend/services/printdown';
import Loading from 'components/loading';
import { useLoading } from 'hooks/use-loading';
import { showError, showMessage } from 'utils/notifications';
import { filterOption } from 'utils/utils';
import './styles.scss';


import store, { StoreState } from 'store';

interface ContractorModalProps {
	projectId: number,
	contractor: ContractorResponse;
	onClose: () => void;
	onFinish: (isSuccess: boolean) => void;
}

export const ProjectContractorEditForm = ({ onFinish, onClose, projectId, contractor }: ContractorModalProps) => {
	const { all: availableRoles = [] } = useSelector((state: StoreState) => state.roles);
	const [loading, showLoading, hideLoading] = useLoading();
	/*@ts-ignore*/
	const [roleId, setRoleId] = useState<number>(contractor.roleId || 0)
	const roleOptions = useMemo(() => availableRoles.map(({ id, name }) => <Select.Option
		key={id}
		value={id}
	>{name}</Select.Option>), [availableRoles.length]);

	console.log(availableRoles);
	const editAttachedContractor = useCallback(() => {
		showLoading();
		const valuesToSave: UpdateAttachedContractorRequest = {
			name: contractor.name,
			telegram: contractor.telegram,
			email: contractor.email,
			phone: contractor.phone,
			newRoleId: roleId
		}

		ContractorService.updateAttachedContractor({
			/*@ts-ignore*/
			projectId: projectId,
			contractorId: contractor.id || 0,
			/*@ts-ignore*/
			roleId: contractor.roleId || 0,
			/*@ts-ignore*/
			body: valuesToSave
		})
			.then(() => {
				hideLoading();
				onClose();
				showMessage('Изменения сохранены');
				onFinish(true);
			})
			.catch(e => {
				hideLoading();
				showError('Не удалось сохранить изменения подрядчика', e);
				onFinish(false);
			})
	}, [roleId, projectId]);

	return <div className={`contractor-form ${!roleId ? 'invalid' : ''}`}>
		{loading && <Loading />}
		<div
			className='field roleId'
			key='roleId'
		>
			<div className='field-label'>Роль в проекте:</div>
			<Select
				value={roleId || undefined}
				onChange={setRoleId}
				className='contractor-select'
				showSearch
				filterOption={filterOption}
				popupMatchSelectWidth={false}
			>{roleOptions}</Select>
		</div>
		<div className='modal-buttons'>
			<Button
				type='primary'
				disabled={!roleId}
				onClick={editAttachedContractor}
			>
				{loading ? <LoadingOutlined /> : <SaveOutlined />}
				Сохранить изменения
			</Button>
			<Button onClick={onClose}>Отмена</Button>
		</div>
	</div>
}
