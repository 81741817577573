import React from 'react';
import InputMask from 'react-input-mask';
import {DatePicker, Input, Select, Typography} from 'antd';
import ruRU from 'antd/lib/date-picker/locale/ru_RU';
import dayjs, {Dayjs} from 'dayjs';
import {PassportRequest} from "backend/services/printdown";
import styles from './styles.module.scss';
import {DATE_FORMAT, SERVER_DATE_FORMAT} from "../../../../../utils/constants";


export type ValueType = string | Dayjs;

interface TextFieldProps {
    allowEmpty?: boolean;
    disabled?: boolean;
    hint?: string;
    label: string;
    mask?: string;
    maxLength?: number;
    name: string;
    // fieldsValidity: Record<keyof Passport, boolean>;
    onChange: (fieldName: string, value: ValueType) => void;
    placeholder?: string,
    readOnly?: boolean,
    regex?: RegExp,
    required?: boolean;
    rows?: number;
    type?: 'input' | 'date' | 'select',
    value: ValueType;
    options?: React.ReactNode[]
}

const FormField = (props: TextFieldProps) => {
    const {
        label, name, onChange, value, placeholder = '', readOnly = false, regex, maxLength = 0,
        type = 'input', rows = 1, disabled = false, allowEmpty = false, options = [],
        required = true, hint = '', mask = ''
    } = props;
    // @ts-ignore
    let isValidValue = !required || (regex ? regex.test(value) : !!value);
    if (name.toLowerCase().endsWith('date') && required) {
        // @ts-ignore
        isValidValue = value?.isValid();
    }

    const isValidInput = allowEmpty || isValidValue;
    const textFieldProps = {
        allowClear: true,
        placeholder: placeholder || label,
        value: value as string,
        disabled,
        readOnly,
        // @ts-ignore
        onChange: ({target: {value}}) => {
            onChange(name as keyof PassportRequest, value);
        }
    };

    if (maxLength) {
        // @ts-ignore
        textFieldProps.maxLength = maxLength;
    }

    return <div
        className={`${styles.form_field} form-field ${name} ${required ? 'required' : ''} ${!isValidInput ? 'invalid' : ''} ${readOnly ? 'readonly' : ''}`}>
        <div className={`${styles.field} field form-field-input`}>
            <div className={styles.label}>{label}:</div>
            <div className={styles.input}>
                {readOnly && <>
                    <Typography.Text className={`field readonly ${!value ? 'empty' : ''}`} copyable={!!value}>
                        {/*@ts-ignore*/}
                        {type === 'date' ? (value ? value.format(DATE_FORMAT) : 'не указано') : (value || 'не указано')}
                    </Typography.Text>
                </>}
                {!readOnly && <>
                    {type === 'input' && rows > 1 && <Input.TextArea
                        rows={rows}
                        {...textFieldProps}
                    />}
                    {type === 'input' && rows <= 1 && (!!mask ? <InputMask
                        // className={isValidInput ? '' : 'invalid'}
                        mask={mask}
                        value={value as string}
                        disabled={disabled}
                        readOnly={readOnly}
                        // @ts-ignore
                        onChange={({target: {value: newValue}}) => {
                            onChange(name as keyof PassportRequest, newValue as string);
                        }}
                    >
                        {/*@ts-ignore*/}
                        {(inputProps) => <Input
                            {...inputProps}
                            allowClear

                            placeholder={placeholder || label}
                        />}

                    </InputMask> : <Input{...textFieldProps} />)}
                    {type === 'date' && <DatePicker
                        allowClear
                        // @ts-ignore
                        readOnly={readOnly}
                        locale={ruRU}
                        disabled={disabled}
                        format='DD.MM.YYYY'
                        placeholder={label}
                        // @ts-ignore
                        value={value}
                        onChange={(date) => {
                            onChange(name as keyof PassportRequest, date as Dayjs);
                        }}
                    />}
                    {type === 'select' && <Select
                        // @ts-ignore
                        value={value}
                        disabled={disabled}
                        onChange={(value) => {
                            // @ts-ignore
                            onChange(name as keyof PassportRequest, value as string)
                        }}
                    >{options}</Select>}
                </>}
            </div>
        </div>

        {!!hint && !readOnly && <div className={styles.hint}>{hint}</div>}
    </div>
}

export default FormField;
