import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Button, Input, List} from 'antd';
import {EditOutlined, PlusOutlined, SearchOutlined} from '@ant-design/icons';
import debounce from "lodash/debounce";

import {
    ContractorRole,
    ContractorRoleService,
} from 'backend/services/printdown';
import {useLoading} from 'hooks/use-loading';
import {getWorkspaceId} from 'store/reducers/auth/selectors';
import {showError, showMessage} from 'utils/notifications';
import {IPagination} from "utils/types";
import {showRoleEditModal} from './role-modal';

import './styles.scss';



export const RolesList = () => {
    const workspaceId = useSelector(getWorkspaceId);
    const [searchString, setSearchString] = useState('');
    const [pagination, setPagination] = useState<IPagination>({pageNum: 0, pageSize: 20});
    const [roles, setRoles] = useState<ContractorRole[]>([]);
    const [loading, showLoading, hideLoading] = useLoading();

    const onChangeSearchString = useCallback(({target: {value}}: React.ChangeEvent<HTMLInputElement>) => {
        setSearchString(value);
    }, []);

    const loadRoles = useCallback((searchStr = '') => {
        showLoading();
        // @ts-ignore
        ContractorRoleService.getContractorRoleByCompanyId({ workspaceId, pageNum: 0, pageSize: 1000, name: searchStr})
            .then(({content = []}) => {
                hideLoading();
                setRoles(content);
            })
            .catch(e => {
                showError('Не удалось загрузить роли', e);
                hideLoading();
            })
    }, [workspaceId]);


    const onChangeRole = useCallback(() => {
        // @ts-ignore
        loadRoles(searchString);
    }, [searchString]);

    const onAddClick = useCallback(() => {
        showRoleEditModal({
            onFinish: onChangeRole,
            workspaceId
        });
    }, [onChangeRole, workspaceId]);

    const onEditClick = useCallback((record: ContractorRole) => {
        showRoleEditModal({
            onFinish: onChangeRole,
            workspaceId,
            role: record
        });

    }, [onChangeRole, workspaceId]);

    const onDeleteClick = useCallback((record: ContractorRole) => {
        showLoading();
        ContractorRoleService.deleteContractorRole({
            roleId: record.id || -1
        })
            .then(() => {
                hideLoading();
                showMessage('Роль удалена');
                onChangeRole();
            })
            .catch(e => {
                showError('Не удалось удалить роль', e);
                hideLoading();
            })
    }, [onChangeRole, workspaceId]);

    const delayedSearch = useCallback(
        debounce((searchStr) => {
            setPagination(prev => ({
                ...prev,
                pageNum: 0,

            }));

            loadRoles(searchStr);
        }, 300),
        []
    );

    useEffect(() => {
        delayedSearch(searchString);
    }, [searchString]);

    useEffect(() => {
        loadRoles();
    }, []);

    return (
        <div className='roles-list'>
            <Input
                allowClear
                prefix={<SearchOutlined/>}
                style={{width: 280, marginBottom: 8, marginTop: 2}}
                placeholder='Поиск роли'
                value={searchString}
                onChange={onChangeSearchString}
            />
            <div>
                <Button
                    type='link'
                    className='role-add-btn'
                    onClick={onAddClick}
                > <PlusOutlined/>Добавить роль</Button>
            </div>
            <List
                size='small'
                // style={{width: 600}}
                loading={ loading}
                // bordered={true}
                dataSource={roles}
                pagination={{
                    simple: true,
                    pageSize: 20,
                    current: pagination.pageNum + 1,
                    position: 'top',
                    align: 'end',
                    total: roles.length,
                    onChange: (newPageNum) => {
                        setPagination({
                            pageNum: newPageNum - 1,
                            pageSize: 20
                        });
                    }
                }}
                renderItem={(role: ContractorRole) => (
                    <List.Item key={role.id}>
                        <div className='role-name'>{role.name}</div>
                        <div className='actions'>
                            <Button
                                size='small'
                                onClick={(ev) => {
                                    ev.stopPropagation();
                                    onEditClick(role);
                                }}
                            ><EditOutlined/></Button>
                            {/*<Popconfirm*/}
                            {/*    title='Удалить роль?'*/}
                            {/*    cancelText='Отмена'*/}
                            {/*    onConfirm={() => {*/}
                            {/*        onDeleteClick(role);*/}
                            {/*    }}*/}
                            {/*>*/}
                            {/*    <Button size='small'><DeleteOutlined/></Button>*/}
                            {/*</Popconfirm>*/}
                        </div>
                    </List.Item>
                )}
            />
        </div>
    );
}
