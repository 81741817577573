import React from 'react';
import Spin, { SpinProps } from 'antd/lib/spin';
import styles from './styles.module.scss';

interface ILoadingProps extends SpinProps {
	text?: string;
}

const Loading = ({ size, text  = ''}: ILoadingProps): JSX.Element => (
	<div
		role='button'
		onKeyDown={() => {}}
		className={styles.loadingMask}
		onClick={(ev) => {
			ev.preventDefault();
		}}
	>
		<Spin
			className='spin'
			{...{ size }}
			tip={text || ''}
		><div className="content" /></Spin>
	</div>
);

export default Loading;
