import React from 'react';
// import { Person, PiplService } from 'backend/services/pipl';
import DebounceSelect, {ISelectProps, ISelectOption} from 'components/autocomplete';
import {UserResponse, UserService, UsersService} from "../../../../../backend/services/printdown";
import {fioRenderer} from "../../../../../utils/utils";
// import { SelectProps } from 'antd/es/select';
// import { ISelectOption } from 'utils/types';
// import { shortFioRender } from 'utils/utils';


export async function fetchUserList(searchStr: string): Promise<ISelectOption[]> {
    return UsersService.findUsersByEmail({email: searchStr})
        .then((data: UserResponse) => {
            return data.id ? [{
                label: fioRenderer(data?.name || ''),
                value: data.id || 0,
                dropdownValue: data.name,
                fullrecord: JSON.stringify({
                    id: data.id,
                    name: data.name,
                    email: data.email
                })
            }] : [{
                label: 'Нет совпадений',
                value: 'NOT_FOUND'
            }]
        });
}

export const UserSelect = (props: Omit<ISelectProps, 'loadData'>) => (
    <DebounceSelect {...props} loadData={fetchUserList} isDisabledOption={({value}) => value === 'NOT_FOUND'}/>
);

