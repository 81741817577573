import React from 'react';
import {ColumnsType} from 'antd/lib/table';
import {LoginResponse, RoleResponse} from 'backend/services/printdown';
import {dateTimeRenderer} from "../../../../utils/utils";


export const logColumns: ColumnsType<LoginResponse> = [
    {
        title: 'Дата входа',
        dataIndex: 'createDate',
        render: (date: string) => dateTimeRenderer(date)
    },
    {
        title: 'Пользователь',
        dataIndex: 'userName',
        render: (userName: string, {userId = 0}: LoginResponse) => `${userId || ''} - ${userName || ''}`
    },
    {
        title: 'Логин',
        dataIndex: 'login'
    },
    {
        title: 'Статус',
        dataIndex: 'status',
        render: (state: string) => <span className={state}>{state === 'SUCCESS' ? 'Успешно' : 'Ошибка'}</span>
    },
    {
        title: 'Описание',
        dataIndex: 'description',
        render: (description: string, {status}: LoginResponse) => status !== 'SUCCESS' ? description : ''
    },
].map(column => ({
    ...column,
    className: column.dataIndex
}));
