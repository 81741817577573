import Icon from '@ant-design/icons';
import React from 'react';


const PassportSVG = () => <svg width="1em" height="1em" viewBox="0 0 1000 1000" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
	<path fillRule="evenodd" clipRule="evenodd" d="M0 748.593C0 805.703 46.297 852 103.407 852H896.593C953.703 852 1000 805.703 1000 748.593V251.407C1000 194.297 953.703 148 896.593 148H103.407C46.297 148 0 194.297 0 251.407V748.593ZM64 748.593V251.407C64 229.643 81.643 212 103.407 212C277.538 212 722.462 212 896.593 212C918.357 212 936 229.643 936 251.407V748.593C936 770.357 918.357 788 896.593 788H103.407C81.643 788 64 770.357 64 748.593ZM214.498 568.454C157.117 602.479 118.923 663.796 118.923 733.432H182.923C182.923 661.765 243.921 604.193 318.33 604.193C392.739 604.193 453.737 661.765 453.737 733.432H517.737C517.737 663.796 479.543 602.479 422.162 568.454C461.705 537.545 487.143 489.41 487.143 435.38C487.143 342.21 411.5 266.568 318.33 266.568C225.16 266.568 149.517 342.21 149.517 435.38C149.517 489.41 174.954 537.545 214.498 568.454ZM582.619 596.49H823.608V532.49H582.619V596.49ZM318.33 330.568C376.178 330.568 423.143 377.533 423.143 435.38C423.143 493.228 376.178 540.193 318.33 540.193C260.482 540.193 213.517 493.228 213.517 435.38C213.517 377.533 260.482 330.568 318.33 330.568ZM763.898 467.51H883.317V403.51H763.898V467.51ZM583.064 467.51H717.396V403.51H583.064V467.51Z" fill="currentColor"/>
</svg>

export const PassportIcon = (props: any) => <Icon className='passport-icon' component={PassportSVG} {...props} />;


