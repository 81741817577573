import dayjs from "dayjs";

export const sortDates = (dateStr1 = '', dateStr2 = '') => {
    const date1 = dayjs(dateStr1);
    const date2 = dayjs(dateStr2);
    if (!date1.isValid() || !date2.isValid()) {
        return date1.isValid() ? -1 : 1;
    }

    return date1.unix() - date2.unix();
}

export const sortStrings = (str1 = '', str2 = '') => str1.localeCompare(str2);
