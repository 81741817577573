import {Document, DocumentResponse, DocumentService, DocumentsRequest} from "../../../backend/services/printdown";
import {showError} from "../../../utils/notifications";
import {ActionCallbackWithData} from "../../../utils/types";

export const createDocument = (contractorId: number, docData: any, onFinish: (isSuccess: boolean, doc?: Document) => void) => {
    DocumentService.addDocument({
        contractorId,
        body: docData as DocumentsRequest
    })
        .then((createdDoc) => {
            onFinish(true, createdDoc);
        })
        .catch(e => {
            showError('Не удалось создать документ', e);
            onFinish(false);
        })
}

export const loadDocumentsByContractorId = (contractorId: number,onFinish: ActionCallbackWithData<DocumentResponse[]>) => {
    DocumentService.getAllDocuments2({
        contractorId: contractorId
    })
        .then((data: DocumentResponse[]) => {
            const sortedDocs = data.sort((doc1, doc2) => (doc1.documentTypeId || 0) - (doc2.documentTypeId || 0));
           onFinish(true, sortedDocs)
        })
        .catch(e => {
            onFinish(false, []);
            showError('Не удалось загрузить документы подрядчика', e);
        });
}
