import React from 'react';
import { OptionProps } from 'rc-select/lib/Option';
import { Select } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { saveAs } from 'file-saver';
import axios from 'axios';
import { showError } from 'utils/notifications';
import {Dictionary, DictionaryItem} from 'store/reducers/dictionaries/types';
import {ActionFinishCallback} from "./types";


const timezone = require('dayjs/plugin/timezone')
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc)
dayjs.extend(timezone);

export const getRandomId = () =>
	dayjs().unix() + Math.round(Math.random() * 10000) + Math.round(Math.random() * 100);


export const filterOption = (input: string, option: OptionProps | undefined) => {
	if (option && option.children) {
		const value = String(option.children).toLowerCase();
		return value.indexOf(input.toLowerCase()) >= 0;
	}
	return false;
}

export const dateRenderer = (date: Dayjs | string | number | Date, format: string = 'DD.MM.YYYY') => {
	//@ts-ignore
	let dateMoment: Dayjs = typeof date === 'number' ? dayjs.unix(date) : dayjs(date);

	//@ts-ignore
	return dateMoment && dateMoment.isValid() ? dateMoment.format(format) : '';
}

export const convertDateForServer = (date?: Dayjs | string | number | Date) => date ? dateRenderer(date, 'YYYY-MM-DD') : '';

export const dateTimeRenderer = (date: Dayjs | string | number) => {
	return <>
		<span className='date'>{dateRenderer(date, 'DD.MM.YYYY')}</span>
		&nbsp;
		<span className='time'>{dateRenderer(date, 'HH:mm')}</span>
	</>
}

export const summRenderer = (amount: number | string) => {
	if (typeof amount !== 'number') {
		return '';
	}
	let amountNumber = amount;


	const formatter = new Intl.NumberFormat('ru-RU', {
		style: 'decimal',
		minimumFractionDigits: 2,
		currency: 'RUB',
		// currencyDisplay: 'symbol'
	});

	let formattedString = formatter.format(amountNumber);
	return formattedString
};

export const parseUTF8FileName = (fileNameStr: string) => {
	let result = 'file.txt';
	let fileName = fileNameStr.replace('attachment; filename="', '');
	fileName = fileName.slice(0, fileName.length - 1);
	return decodeURI(fileName);
};


export const downloadFile = (url: string, params: any, onFinish: (isSuccess: boolean) => void) => {
	axios.post(url, params, {
		responseType: 'blob'
	})
		.then(response => {
			const blob = new Blob([response.data]);
			let loadFileName = 'Файл.txt';
			if (response.headers && Object.prototype.hasOwnProperty.call(response.headers, 'content-disposition')) {
				const fileNameStr = response.headers['content-disposition'];
				if (fileNameStr) {
					loadFileName = parseUTF8FileName(fileNameStr);
				}
			}

			saveAs(blob, loadFileName);
			onFinish(true);
		})
		.catch(e => {
			showError('Не удалось скачать файл', e);
			onFinish(false);
		})
}

export const createDictionaryOptions = (dictionary: DictionaryItem[] = []): React.ReactNode[] => dictionary.map(({
	                                                                                                           name,
	                                                                                                           description
                                                                                                           }) =>
	<Select.Option
		key={name}
		value={name}
	>{description}</Select.Option>);

export const sortByAlphabet = (str1: string, str2: string) =>str1.toLowerCase().localeCompare(str2.toLowerCase());

export const abbreviateFullName = (fullName: string) => {
	const parts = fullName.split(' ');
	if (parts.length < 2) {
		// Если не хватает частей (например, только фамилия или имя),
		// возвращаем исходную строку
		return fullName;
	}


	const lastName = parts[0];
	const firstName = parts[1][0].toUpperCase(); // Берем первую букву имени
	const patronymic = parts.length > 2 ?  `${parts[2][0].toUpperCase()}.` : ''; // Если есть отчество, берем первую букву отчества

	return `${lastName} ${firstName}. ${patronymic}`;
}

export const downloadFileFromResponse = (response: any, onFinish: ActionFinishCallback) => {
	const blob = new Blob([response.data]);
	let loadFileName = 'file.txt';
	//
	// // eslint-disable-next-line max-len
	if (response.headers && Object.prototype.hasOwnProperty.call(response.headers, 'content-disposition')) {
		const fileNameStr = response.headers['content-disposition'];

		// const parsedFileNames = FileNameRegex.exec(fileNameStr);
		// const [parsedFileName] = parsedFileNames || [];
		if (fileNameStr) {
			loadFileName = parseUTF8FileName(fileNameStr);
		}
	}

	saveAs(blob, loadFileName);
	onFinish(true);
}

export const stringToColor = (str: string) => {
	let hash = 0;
	str.split('').forEach(char => {
		hash = char.charCodeAt(0) + ((hash << 5) - hash)
	})
	let colour = '#'
	for (let i = 0; i < 3; i++) {
		const value = (hash >> (i * 8)) & 0xff
		colour += value.toString(16).padStart(2, '0')
	}
	return colour
}


export const fioRenderer = (fullName: string): string => {
	const nameParts = fullName.length ? fullName.split(/\s/) : [];
	if (nameParts.length <= 1) {
		return fullName;
	}
	const [lastName, firstName] = nameParts;
	const secondName = nameParts.length > 2 ? nameParts[2] : ''

	return [lastName, firstName, secondName].join(' ');
}
