import { App } from 'antd';
import type { MessageInstance } from 'antd/es/message/interface';
import type { ModalStaticFunctions } from 'antd/es/modal/confirm';
import type { NotificationInstance } from 'antd/es/notification/interface';

let message: MessageInstance;
let notificationsProvider: NotificationInstance;
let modal: Omit<ModalStaticFunctions, 'warn'>;

export default () => {
    console.log('%c Init notifications provider', 'color: blue')
    const staticFunction = App.useApp();
    message = staticFunction.message;
    modal = staticFunction.modal;
    notificationsProvider = staticFunction.notification;
    return null;
};

export { message, modal, notificationsProvider };
