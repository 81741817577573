import Icon from '@ant-design/icons';
import React from 'react';


const InnSVG = () => <svg width="1em" height="1em" viewBox="0 0 1000 1000" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
	<path fillRule="evenodd" clipRule="evenodd" d="M435.402 30.5976L113.705 186.993C62.8416 211.721 30.5566 263.314 30.5566 319.87V680.128C30.5566 736.684 62.8416 788.277 113.705 813.005C201.464 855.67 355.819 930.711 435.402 969.4C476.186 989.228 523.816 989.228 564.6 969.4C644.183 930.711 798.538 855.67 886.297 813.005C937.16 788.277 969.445 736.684 969.445 680.128C969.445 680.128 969.445 319.946 969.445 319.87C969.445 263.314 937.16 211.721 886.297 186.993C798.538 144.328 644.183 69.2866 564.6 30.5976C523.816 10.7696 476.186 10.7696 435.402 30.5976ZM463.384 88.1556C486.502 76.9166 513.5 76.9166 536.618 88.1556C616.2 126.846 770.555 201.887 858.315 244.552C887.145 258.568 905.445 287.813 905.445 319.87V680.128C905.445 712.185 887.145 741.43 858.315 755.446C770.555 798.111 616.2 873.152 536.618 911.842C513.5 923.081 486.502 923.081 463.384 911.842C383.802 873.152 229.447 798.111 141.687 755.446C112.857 741.43 94.5566 712.185 94.5566 680.128V319.87C94.5566 287.813 112.857 258.568 141.687 244.552L463.384 88.1556ZM288.04 379.542H284.147L210.517 503.427V379.505H146.517V620.493H210.517V620.456H215.412L288.04 498.257V620.493H352.04V379.505H288.04V379.542ZM783.901 467.999H706.378V379.505H642.378V620.493H706.378V531.999H783.901V620.493H847.901V379.505H783.901V467.999ZM533.179 467.999H455.655V379.505H391.655V620.493H455.655V531.999H533.179V620.493H597.179V379.505H533.179V467.999Z" fill="currentColor"/>
</svg>


export const InnIcon = (props: any) => <Icon className='inn-icon' component={InnSVG} {...props} />;


