import React, {useCallback, useState} from 'react';
import {Button, Typography, Upload, UploadProps} from "antd";
import {RcFile} from "antd/es/upload";
import {useLoading} from "../../hooks/use-loading";
import {Action} from "redux";
import {ActionFinishCallback} from "../../utils/types";
import {UploadOutlined, LoadingOutlined, PlusOutlined} from "@ant-design/icons";

export const FileUpload = ({label, doUpload, selectText = 'загрузить файл', ...uploadProps}: UploadProps & {
    label?: string,
    selectText?: string,
    doUpload: (files: RcFile[], onFinish?: ActionFinishCallback) => void
}) => {
    const [loading, showLoading, hideLoading] = useLoading();
    // const [files, setFiles] = useState<RcFile[]>([]);

    const onUploadBtnClick = useCallback((files: RcFile[]) => {
        if (!files.length) {
            return;
        }
        showLoading();
        doUpload(files, (isSuccess) => {
            hideLoading();
            // if (isSuccess) {
            //     setFiles([]);
            // }

        });
    }, []);

    return (
        <div className='file-upload' style={{position: 'relative'}}>
            {!!label && <div className='label'>
                <Typography.Text strong>{label}</Typography.Text>
            </div>}

            <div className='upload-field'>
                <Upload
                    {...uploadProps}
                    showUploadList={false}
                    // fileList={files}
                    beforeUpload={(newFile, newFileList) => {
                        // setFiles(prev => {
                        //     const newFiles = [...prev];
                        //     newFileList.forEach((newFile) => {
                        //         const isExistFile = !!newFiles.find(file => file.uid === newFile.uid);
                        //
                        //         if (!isExistFile) {
                        //             newFiles.splice(0, 0, newFile);
                        //         }
                        //     })
                        //
                        //     return newFiles;
                        // });

                        onUploadBtnClick(newFileList);
                        return false;
                    }}
                    // onRemove={(removedFile) => {
                    //     setFiles(prev => {
                    //         const newFiles = [...prev];
                    //         const removedFileIndex = prev.findIndex((file) => file.uid === removedFile.uid);
                    //         newFiles.splice(removedFileIndex, 1);
                    //         return newFiles;
                    //     })
                    // }}
                >
                    <Button
                        size='small'
                        type='link'
                        style={{padding: 0}}
                    ><PlusOutlined/>{selectText}</Button>
                </Upload>
            </div>
            {/*{!!files.length && <Button*/}
            {/*    size='small'*/}
            {/*    type='link'*/}
            {/*    style={{position: 'absolute', top: 0, left: 140}}*/}
            {/*    onClick={onUploadBtnClick}*/}
            {/*>{loading ? <LoadingOutlined/> : <UploadOutlined/>}загрузить файлы</Button>}*/}
            {/*<Button size='small' onClick={onUploadBtnClick} disabled={!files.length || loading}>*/}
            {/*    {loading ? <LoadingOutlined/> :*/}
            {/*        <UploadOutlined/>}Загрузить выбранные файлы</Button>*/}
        </div>
    )
}
