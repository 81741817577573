import React from 'react';
import { Button, Popconfirm } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Company } from 'backend/services/printdown';


export const organizationColumns = ({ onEditClick, onDeleteClick }: any): ColumnsType<Company> => [
	{
		title: 'Наименование',
		dataIndex: 'name',
	},
	{
		title: 'ИНН',
		dataIndex: 'inn'
	},
	{
		title: 'КПП',
		dataIndex: 'kpp'
	},
	{
		title: 'CEO',
		dataIndex: 'owner'
	},
	{
		title: 'Адрес',
		dataIndex: 'address'
	},
	{
		dataIndex: 'actions',
		render: (value: any, record: Company) => (
			<>
				<Button
					size='small'
					onClick={(ev: React.MouseEvent<HTMLElement> | undefined) => {
						ev?.preventDefault();
						onEditClick(record);
					}}
				><EditOutlined /></Button>
				{/*<Popconfirm*/}
				{/*	title='Удалить организацию?'*/}
				{/*	onCancel={(ev: React.MouseEvent<HTMLElement> | undefined) => {*/}
				{/*		ev?.preventDefault();*/}
				{/*	}}*/}
				{/*	onConfirm={(ev: React.MouseEvent<HTMLElement> | undefined) => {*/}
				{/*		ev?.preventDefault();*/}
				{/*		onDeleteClick(record);*/}
				{/*	}}*/}
				{/*>*/}
				{/*	<Button*/}
				{/*		style={{ marginLeft: 10 }}*/}
				{/*		size='small'*/}
				{/*	><DeleteOutlined /></Button>*/}
				{/*</Popconfirm>*/}
			</>
		)
	}
].map(column => ({
	...column,
	className: column.dataIndex
}));
