import React, {useCallback, useMemo, useRef} from 'react';
import { useSelector} from 'react-redux';
import {Button} from 'antd';
import {PlusOutlined} from '@ant-design/icons';

import {Company, CompanyService} from 'backend/services/printdown';
import Loading from "components/loading";
import {RemoteTable} from "components/table/remote-table";
import {useLoading} from 'hooks/use-loading';
import {getWorkspaceId} from "store/reducers/auth/selectors";
import {showError, showMessage} from 'utils/notifications';
import {organizationColumns} from 'views/admin/organizations/columns';
import {showCompanyEditModal} from './organization-modal';
import './styles.scss';

export const OrganizationsList = () => {
    const workspaceId = useSelector(getWorkspaceId);
    const [loading, showLoading, hideLoading] = useLoading();
    const tableRef = useRef(null);


    const reloadTable = useCallback(() => {
        // @ts-ignore
        tableRef.current?.reloadTable();
    }, [tableRef.current]);

    const onOrganizationChange = useCallback(() => {
        // @ts-ignore
        reloadTable();
    }, [reloadTable]);

    const onAddClick = useCallback(() => {
        showCompanyEditModal({
            onFinish: onOrganizationChange
        });
    }, [onOrganizationChange]);

    const onEditClick = useCallback((record: Company) => {
        showCompanyEditModal({
            onFinish: onOrganizationChange,
            company: record
        });

    }, [onOrganizationChange]);

    const onDeleteClick = useCallback((record: Company) => {
        showLoading();
        CompanyService.deleteCompany({
            companyId: record.id || -1
        })
            .then(() => {
                hideLoading();
                showMessage('Организация удалена');
                onOrganizationChange();
            })
            .catch(e => {
                showError('Не удалось удалить организацию', e);
                hideLoading();
            })
    }, [onOrganizationChange]);

    const tableColumns = useMemo(() => organizationColumns({
        onEditClick,
        onDeleteClick
    }), [onEditClick, onDeleteClick]);

    return (
        <div className='organizations-list'>
            {loading && <Loading />}
            <RemoteTable
                ref={tableRef}
                pagination={false}
                loadDataFn={CompanyService.getAllCompanies}
                columns={tableColumns}
                responseDataConverter={(data) => ({
                    data,
                    total: data.length
                })}
                requestParamsConverter={() => ({workspaceId})}
                toolbar={
                    <Button
                        type='link'
                        onClick={onAddClick}
                    > <PlusOutlined/>Добавить организацию</Button>
                }
            />

        </div>
    )
}
