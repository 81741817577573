import React, {useCallback, useMemo, useState} from "react";
import {Button, Input, Typography} from "antd";
import {CheckOutlined, CloseOutlined, EditOutlined, LoadingOutlined,} from "@ant-design/icons";
import {
    BirthCertificateRequest,
    Document,
    DocumentService,
    DocumentsRequest,
    PassportFormValues, PassportRequest,
} from "backend/services/printdown";
import {useLoading} from "hooks/use-loading";
import {showError} from "utils/notifications";
import {ActionCallback} from "utils/types";

import './styles.scss';
import {getRandomId} from "../../../utils/utils";
import FormField from "../passport-form/form/form-field";
import dayjs, {Dayjs} from "dayjs";
import {SERVER_DATE_FORMAT} from "../../../utils/constants";


export interface BirthCertificateFormValues extends Omit<BirthCertificateRequest, 'issueDate'> {
    issueDate: Dayjs | null,
}


export interface BirthCertificateValues extends BirthCertificateFormValues {
    documentId?: number,
    documentTypeId?: number,
    documentTypeCode?: string,
};

export const BirthCertificateForm = ({contractorId, values: prevValues, onSuccessSave}: {
    contractorId: number,
    values: BirthCertificateRequest & Document,
    onSuccessSave: ActionCallback
}) => {
    const [isEdit, startEdit, cancelEdit] = useLoading();
    const [loading, showLoading, hideLoading] = useLoading();
    const [values, setValues] = useState<BirthCertificateValues>({
        ...prevValues,
        // @ts-ignore
        issueDate: prevValues.issueDate ? dayjs(prevValues.issueDate) : null,
    });

    const isEditExistedDocument = useMemo(() => !!values.documentId, [values?.documentId]);


    const valuesChangeId = useMemo(() => getRandomId(), [
        values.number, values.serial, values.issue, values.issueDate
    ]);
    const isValidForm = useMemo(() => values.number && values.serial && values.issue && values.issueDate, [valuesChangeId]);

    const onChangeField = useCallback((fieldName: string, value: string | Dayjs) => {
        setValues(prev => ({
            ...prev,
            [fieldName as keyof BirthCertificateValues]: value
        }))
    }, []);

    const cancelEditing = useCallback(() => {
        setValues({
            ...prevValues,
            // @ts-ignore
            issueDate: prevValues.issueDate ? dayjs(prevValues.issueDate) : null,
        });
        cancelEdit();
    }, [prevValues.number, prevValues.serial, prevValues.issue, prevValues.issueDate]);
    const saveChanges = useCallback(() => {
        showLoading();

        const dataToSave: BirthCertificateRequest = {
            ...values,
            // @ts-ignore
            issueDate: values.issueDate ? values.issueDate.format(SERVER_DATE_FORMAT) : ''
        }

        const valuesToSave: DocumentsRequest = {
            documentTypeId: values.documentTypeId,
            birthCertificateRequest: dataToSave
        };

        const promise = isEditExistedDocument ? DocumentService.updateDocument({
            documentId: values?.documentId || 0,
            body: valuesToSave
        }) : DocumentService.addDocument({
            contractorId,
            body: valuesToSave
        });

        promise.then(() => {
            hideLoading();
            cancelEdit();
            onSuccessSave();
        }).catch(e => {
            hideLoading();
            showError(`Не удалось сохранить св-во о рождении`, e);
            cancelEditing();
        })
    }, [valuesChangeId, values?.documentId, values?.documentTypeCode, isEditExistedDocument]);

    const fieldProps = {
        readOnly: !isEdit,
        onChange: onChangeField,
        required: true
    }

    return (
        <div className={`birth-certificate-form ${!isValidForm ? 'invalid' : ''}`}>
            <div className='buttons'>
                {!isEdit && <Button type='link'
                                    onClick={startEdit}><EditOutlined/> {isEditExistedDocument ? 'Редактировать' : 'Заполнить'}
                </Button>}
                {isEdit && <>
                    <Button type='link' style={{marginRight: 10}} disabled={!isValidForm}
                            onClick={saveChanges}>{loading ? <LoadingOutlined/> : <CheckOutlined/>} Сохранить</Button>
                    <Button type='link' onClick={cancelEditing}><CloseOutlined/> Отмена</Button>
                </>}
            </div>
            <FormField
                {...fieldProps}
                label='Серия'
                name='serial'
                value={values.serial as string}
            />
            <FormField
                {...fieldProps}
                label='Номер'
                name='number'
                value={values.number as string}
            />
            <FormField
                {...fieldProps}
                label='Кем выдан'
                name='issue'
                value={values.issue as string}
            />
            <FormField
                type='date'
                name='issueDate'
                label='Дата выдачи'
                {...fieldProps}
                value={values.issueDate as Dayjs}
            />
        </div>

    )
}
