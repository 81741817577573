import {Dispatch} from "react";
import {loadProjectsThunk} from "../reducers/projects/thunks";
import {loadOrganizationsThunk} from "../reducers/organizations/thunks";
import {ALL_ROLES_PAGE_SIZE, loadRolesThunk} from "../reducers/roles/thunks";
import {loadContractorsThunk} from "../reducers/contractors/thunks";
import {StoreState} from "../index";
import {loadDictionaries} from "../reducers/dictionaries";


// export const loadProjectsInStore = (state: StoreState, dispatch: Dispatch<any>) => {
//     const {user: {id, workspaceId}} = state.auth;
//     // const {filters, sort} = state.projects;
//     dispatch(loadProjectsThunk({workspaceId: workspaceId || 0, userId: id}));
// }
//
// export const loadOrganizationsInStore = (state: StoreState, dispatch: Dispatch<any>) => {
//     const {user: {workspaceId}} = state.auth;
//     dispatch(loadOrganizationsThunk({workspaceId}));
// }

// export const loadRolesByCompanyInStore = (state: StoreState, dispatch: Dispatch<any>) => {
//     const {user: {workspaceId}} = state.auth;
//     const {page, pageSize, search = ''} = state.roles;
//     // console.log(`%cRoles  thunk for params workspaceId=[${workspaceId}], page=${page}, pageSize=${pageSize}, search=${search}`, 'color: blue');
//     dispatch(loadRolesThunk({workspaceId, pageNum: page, pageSize, search: search || ''}));
// }

export const loadAllRolesInStore = (state: StoreState, dispatch: Dispatch<any>) => {
    const {user: {workspaceId}} = state.auth;
    dispatch(loadRolesThunk({workspaceId, pageNum: 0, pageSize: ALL_ROLES_PAGE_SIZE}));
}
//
//
// export const loadContractorsOfWorkspaceInStore = (state: StoreState, dispatch: Dispatch<any>) => {
//     const {user: {workspaceId}} = state.auth;
//     // const {page, pageSize, filters = {}} = state.contractors;
//     dispatch(loadContractorsThunk({workspaceId, page}));
// }


export const loadWorkspaceData = (listenerApi: any) => {
    const state = listenerApi.getState();
    const {dispatch} = listenerApi;
    loadDictionaries(listenerApi.dispatch);
    // loadContractorsOfWorkspaceInStore(state, dispatch);
    // loadOrganizationsInStore(state, dispatch);
    loadAllRolesInStore(state, dispatch);
}
