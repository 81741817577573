import {StoreState} from 'store/index';
import {DictionaryType} from 'store/reducers/dictionaries/types';

export const getDictionary = (name: DictionaryType) => (state: StoreState) => state.dictionary[name] || [];

// @ts-ignore
export const getDocumentTypesDictionary = (employmentType: string) => (state: StoreState) => {
    const docTypes = state.dictionary.documentTypes || {};
    // @ts-ignore
    return employmentType ? (docTypes[employmentType] || []) : [];
}

