import {configureStore, createListenerMiddleware, isAnyOf, PayloadAction} from '@reduxjs/toolkit';
import storage from 'reduxjs-toolkit-persist/lib/storage'
import { persistReducer, persistStore} from 'redux-persist';
import {redirect} from 'react-router'
import authSlice, {companyChanged, loginError, loginSuccess, workspaceChanged} from 'store/reducers/auth';
import projectsSlice, {projectChanged, projectFiltersChanged, projectSortChanged} from 'store/reducers/projects';
import {loadProjectsThunk} from 'store/reducers/projects/thunks';
import {loadOrganizationsThunk} from 'store/reducers/organizations/thunks';
import organizationSlice, {organizationChanged} from 'store/reducers/organizations';
import {Dispatch} from 'react';
import {ALL_ROLES_PAGE_SIZE, loadRolesThunk} from 'store/reducers/roles/thunks';
import rolesSlice, {roleChanged, rolePaginationChanged, roleSearchChanged} from 'store/reducers/roles';
import {loadContractorsThunk} from 'store/reducers/contractors/thunks';
import contractorsSlice, {
    contractorChanged,
    contractorFiltersChanged,
    contractorPaginationChanged
} from 'store/reducers/contractors';
import dictionarySlice, {loadDictionaries} from 'store/reducers/dictionaries';
import printdownBackend from "../backend/axios";
import {
    authMiddleware,
    contractorsMiddleware,
    organizationsUpdateMiddleware,
    projectsUpdateMiddleware,
    rolesMiddleware
} from "./middleware";

const persistConfig = {
    key: 'root',
    storage: storage,
    whitelist: ['user', 'isUserLoggedIn'],
}

const persistedAuthReducer = persistReducer(persistConfig, authSlice)




const appStore = configureStore({
    reducer: {
        auth: persistedAuthReducer,
        // projects: projectsSlice,
        // organizations: organizationSlice,
        roles: rolesSlice,
        // contractors: contractorsSlice,
        dictionary: dictionarySlice
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false
        }).prepend(
            rolesMiddleware.middleware,
            // projectsUpdateMiddleware.middleware,
            // organizationsUpdateMiddleware.middleware,
            // contractorsMiddleware.middleware,
            authMiddleware.middleware
        ),
    devTools: process.env.NODE_ENV !== 'production',
})

export type StoreState = ReturnType<typeof appStore.getState>

export const persistor = persistStore(appStore)
export default appStore;
