import React, {useCallback, useMemo, useRef} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from "react-router-dom";
import {Select} from 'antd';
import {
    ContractorResponse,
    ContractorService,
    Project,
    ProjectService
} from 'backend/services/printdown';

import Loading from "components/loading";
import {RemoteTable} from "components/table/remote-table";
import {useEmploymentOptions} from 'hooks/use-employment-options';
import {useRemoteData} from "hooks/use-remote-data";
import {useLoading} from 'hooks/use-loading';
import {StoreState} from 'store';
import {getUserPermissions, getWorkspaceId} from 'store/reducers/auth/selectors';
import {showError, showMessage} from 'utils/notifications';
import {contractorColumns} from 'views/contractors/list/columns';
import {getContractorFilters} from 'views/contractors/list/filters';

import './styles.scss';
import {LocalTable} from "../../../components/table/local-table";
import {PaginationConfig} from "../../../components/table/constants";


export const ContractorsOfWorkspaceList = ({projectId: initialProjectId = 0}: { projectId?: number }) => {
    const {isAdmin, isSuperAdmin} = useSelector(getUserPermissions);
    const workspaceId = useSelector(getWorkspaceId);
    const navigate = useNavigate();

    const {data: projects, loading: isLoadingProjects} = useRemoteData<Project>({
        // @ts-ignore
        loader: ProjectService.getAllProjects,
        searchParams: {workspaceId}
    });
    //
    // const {data: contractorsOfWP, loading: isLoadingContractors} = useRemoteData<Project>({
    //     // @ts-ignore
    //     loader: ProjectService.getAllProjects,
    //     searchParams: {workspaceId}
    // });


    const [loading, showLoading, hideLoading] = useLoading();
    const tableRef = useRef(null);
    const [employmentTypeOptions] = useEmploymentOptions();

    const reloadTable = useCallback(() => {
        // @ts-ignore
        tableRef.current?.reloadTable();
    }, [tableRef.current]);


    const projectOptions = useMemo(() => projects.map(({id, name}: Project) => <Select.Option
        key={id}
        value={id}
    >{name}</Select.Option>), [projects.length]);

    const onEditClick = useCallback((record: ContractorResponse) => {
        //редактирование контрактора  доступно только из "списка"
        // из проектов можно менять только роль
        navigate(`/contractors/${record.id}`, {
            state: {contractorName: record.name, openedFrom: '/contractors', contractorType: record.type}
        })
    }, [reloadTable]);

    const onDeleteClick = useCallback((record: ContractorResponse) => {
        showLoading();
        ContractorService.deleteContractor({
            contractorId: record.id || 0
        })
            .then(() => {
                hideLoading();
                showMessage(`Подрядчик "${record.name}" удалён`);
                // @ts-ignore
                reloadTable();
            })
            .catch(e => {
                showError(`Не удалось удалить подрядчика "${record.name}"`, e);
                hideLoading();
            })
    }, [reloadTable]);

    const onRow = useCallback((record: ContractorResponse) => ({
        onClick: (ev: any) => {
            ev.stopPropagation();
            navigate(`/contractors/${record.id}`, {
                state: {
                    contractorName: record.name,
                    openedFrom: '/contractors',
                    contractorType: record.type
                }
            })
        }
    }), []);

    const tableColumns = useMemo(() => {
        return contractorColumns({
            onEditClick,
            onDeleteClick: isAdmin || isSuperAdmin ? onDeleteClick : null
        })
    }, [onEditClick, onDeleteClick, isAdmin, isSuperAdmin]);

    const tableFilters = useMemo(() => getContractorFilters({
        projects: projectOptions,
        employmentTypeOptions
    }), [projectOptions.length, employmentTypeOptions.length]);

    return (
        <div className='contractors-list'>
            {loading && <Loading/>}
            <RemoteTable
                ref={tableRef}
                columns={tableColumns}
                onRow={onRow}
                filters={tableFilters}
                loadDataFn={ContractorService.findBy1}
                requestParamsConverter={({pageNum, pageSize, ...filters}) => ({
                    ...filters,
                    workspaceId,
                    page: 0,
                    size: 1000,
                    sort:'createDate,desc'
                })}
                pagination={{...PaginationConfig}}
            />
        </div>
    )
}
