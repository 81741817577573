import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Input, Select, Typography} from 'antd';
import {ContractorResponse} from 'backend/services/printdown';
import {useEmploymentOptions} from 'hooks/use-employment-options';
import './style.scss';


export const CONTRACTOR_FIELDS: Array<{ dataIndex: keyof ContractorResponse, title: string, required?: boolean, rows?: number }> = [
    {dataIndex: 'name', title: 'ФИО'},
    {dataIndex: 'type', title: 'Тип'},
    {dataIndex: 'phone', title: 'Телефон'},
    {dataIndex: 'telegram', title: 'Телеграм'},
    {dataIndex: 'email', title: 'Email'},
    {dataIndex: 'description', title: 'Описание', required: false, rows: 2},
]

export const isValidContractor = (values: any) => CONTRACTOR_FIELDS
    .every(({dataIndex, required = true}) => !required || !!values[dataIndex]);


interface ContractorFormProps {
    contractor?: ContractorResponse;
    disabled?: boolean;
    onChange: (props: { values: ContractorResponse, isValid: boolean }) => void;
    readOnly?: boolean
}

export const ContractorForm = ({
                                   contractor = {id: 0},
                                   onChange,
                                   disabled = false,
                                   readOnly = false
                               }: ContractorFormProps) => {
    const [employmentTypes, employmentTypesMap] = useEmploymentOptions();
    const [values, setValues] = useState<ContractorResponse>({
        ...contractor
    });

    const isEdit = useMemo(() => parseInt(String(values.id), 10) > 0, [contractor.id])

    useEffect(() => {
        const isValidForm = isValidContractor(values);
        onChange({
            values,
            isValid: isValidForm
        })
    }, [
        values.name, values.type, values.email, values.phone,
        values.description, values.telegram])

    return (
        <div className={`contractor-fields ${readOnly ? 'readonly' : ''}`}>
            {CONTRACTOR_FIELDS.map(({dataIndex, title, required = true, rows = 0}) => {
                    const fieldValue = (values[dataIndex] || '') as string;
                    return (
                        <div
                            className={`field ${dataIndex} ${required && !fieldValue ? 'invalid' : ''}`}
                            key={dataIndex}
                        >
                            <div className='field-label'>{title}</div>
                            {readOnly && <Typography.Text copyable={!!fieldValue && dataIndex !== 'type'}>
                                {dataIndex === 'type' ? (employmentTypesMap[fieldValue] || fieldValue) : fieldValue || ' - '}</Typography.Text>}
                            {!readOnly && (dataIndex === 'type' ? <Select
                                        disabled={disabled || isEdit}
                                        popupMatchSelectWidth={false}
                                        value={values.type}
                                        onChange={(newType) => {
                                            setValues(prev => ({
                                                ...prev,
                                                type: newType
                                            }))
                                        }}
                                    >
                                        {employmentTypes}
                                    </Select> : (
                                    rows > 1 ? <Input.TextArea
                                        rows={rows}
                                        allowClear
                                        disabled={disabled}
                                        value={fieldValue}
                                        onChange={({target: {value}}) => {
                                            setValues(prev => ({
                                                ...prev,
                                                [dataIndex]: value
                                            }))
                                        }}
                                    /> : <Input
                                        allowClear
                                        disabled={disabled}
                                        value={fieldValue}
                                        onChange={({target: {value}}) => {
                                            setValues(prev => ({
                                                ...prev,
                                                [dataIndex]: value
                                            }))
                                        }}
                                    />

                                )

                            )}
                        </div>
                    )
                }
            )}
        </div>
    )
}
