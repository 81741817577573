import React, {useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import {Avatar, Dropdown, Layout} from 'antd';
import {UserOutlined} from '@ant-design/icons';
import { Workspace} from 'backend/services/printdown';
import {AdminIcon} from 'icons/admin';
import {StoreState} from 'store';
import {companyChanged, logout, workspaceChanged} from 'store/reducers/auth';
import {DuckIcon} from "../../icons/duck";
import {MagicIcon} from "../../icons/magic";
import './style.scss';

const Header = (): JSX.Element => {
    const {
        user,
        isUserLoggedIn
    } = useSelector((state: StoreState) => state.auth);
    // const {
    //     data: organizations = [],
    //     isLoading: isLoadingOrganizations
    // } = useSelector((state: StoreState) => state.organizations);

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const displayName = useMemo(() => {
        const nameParts = user.name ? user.name.split(' ') : [];
        return nameParts.length > 1 ? nameParts[1] : user.name;
    }, [user.name]);

    // useEffect(() => {
    //     if (!isLoadingOrganizations && organizations.length) {
    //         dispatch(companyChanged({id: organizations[0].id, name: organizations[0].name}));
    //         if (!location.pathname.replace('/', '')) {
    //             navigate('/projects');
    //         }
    //     }
    // }, [organizations.length, isLoadingOrganizations]);

    return (
        <Layout.Header style={{paddingInline: 25}}>
            <div className='logo'>
                <DuckIcon
                    style={{
                        color: 'rgb(178, 239, 139)',
                        marginRight: 10,
                        fontSize: '28px'
                    }}
                />
                <div>ДокДак</div>
            </div>
            {
                isUserLoggedIn && <div className='user-info'>
                    {!!user.workspaceName && (user.workspaces || []).length > 1 ?
                        <Dropdown
                            className='workspace-selector'
                            placement='bottomRight'
                            menu={{
                                className: 'workspaces-menu',
                                onClick: ({item, key, keyPath, domEvent}) => {
                                    const [workspaceIdStr, workspaceName = ''] = key.split(' - ');
                                    const workspaceId = parseInt(workspaceIdStr, 10);
                                    if (workspaceId) {
                                        dispatch(workspaceChanged({id: workspaceId, name: workspaceName}));
                                        navigate('/projects');
                                    }
                                },
                                items: (user.workspaces || []).map((workspace: Workspace) => ({
                                    key: `${workspace.id} - ${workspace.name}`,
                                    label: <div className='workspace-item'>
                                        <Avatar style={{
                                            backgroundColor: workspace.color || 'gray',
                                            color: 'white'
                                        }}>{workspace.name ? workspace.name[0].toUpperCase() : '?'}</Avatar>
                                        {workspace.name}
                                    </div>
                                }))
                            }}>
                            <span className='workspace-name'>{user.workspaceName}</span>
                        </Dropdown> : <span className='workspace-name'>{user.workspaceName}</span>
                    }
                    <Dropdown
                        className='user-name'
                        menu={{
                            items: [
                                {
                                    key: 'logout',
                                    label: 'Выход',
                                    onClick: () => {
                                        // @ts-ignore
                                        dispatch(logout());
                                    }
                                },
                            ]
                        }}>
                        <a onClick={(e) => e.preventDefault()}>
                            {user.isSuperAdmin ? <MagicIcon style={{color: 'rgb(217,7,245)', fontSize: '24px'}}/> :
                                (
                                    user.isAdmin ? <AdminIcon style={{color: 'rgb(254,138,12)'}}/> : <UserOutlined/>
                                )}
                            <span className='userName'>{displayName}</span>
                        </a>
                    </Dropdown>
                </div>
            }
        </Layout.Header>
    )
}

export default Header;
