import React, {useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState} from 'react';
import {Table as AntTable} from 'antd';
import debounce from 'lodash/debounce';

import {FilterFieldsConfig} from 'components/table/filter-form/types';
import {showError} from 'utils/notifications';
import {IPagination} from 'utils/types';
import FilterForm, {FilterValuesType} from '../filter-form';

import {DefaultPageSize, PaginationConfig} from "../constants";
import {LocalTable} from "../local-table";
import {PaginationProps} from "antd/lib";


interface ITableProps {
    columns: any[],
    className?: string;
    toolbar?: JSX.Element;
    title?: string;
    filters?: FilterFieldsConfig;
    onRow?: any,
    pagination?: boolean | PaginationProps;
    rowClassName?: (record: any) => string,
    rowKey?: string,
    requestParamsConverter?: (filters: any) => any,
    responseDataConverter?: (response: any) => any,
    loadDataFn?: (requestParams: any) => Promise<{ content: any[], totalElements: number }>,
    size?: 'small' | 'default'
}


export const RemoteTable = React.forwardRef((props: ITableProps, ref) => {
    const {
        loadDataFn,
        columns,
        toolbar,
        title = '',
        className,
        requestParamsConverter,
        responseDataConverter = null,
        filters = [],
        size = 'small',
        ...tableProps
    } = props;

    const [data, setData] = useState<any[]>([]);
    const [total, setTotal] = useState<number>(0);
    const [pagination, setPagination] = useState<IPagination>({pageNum: 0, pageSize: DefaultPageSize});
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedFilters, setSelectedFilters] = useState<FilterValuesType>({});

    const localTableRef = useRef(null);
    const filterFormRef = useRef(null);

    const onChangeFilters = useCallback((filters: any) => {
        setSelectedFilters(filters);

        setPagination(prev => ({
            pageNum: 0,
            pageSize: prev.pageSize
        }))
    }, []);

    const onPaginationChange = useCallback((newPagination: IPagination) => {
        setPagination(prev => ({
            pageSize: newPagination.pageSize,
            pageNum: newPagination.pageSize !== prev.pageSize ? 0 : newPagination.pageNum
        }))
    }, []);

    const loadData = useCallback(({filters, pagination}: { filters: any, pagination: IPagination }) => {
        setLoading(true);
        let requestParams: FilterValuesType & IPagination = {
            ...pagination,
            ...filters
        }
        if (requestParamsConverter) {
            requestParams = requestParamsConverter(requestParams);
        }

        console.log(`%c LOAD REMOTE DATA`, 'color:red');
        console.log(requestParams);

        // @ts-ignore
        loadDataFn(requestParams)
            .then((responseData: any) => {
                if (responseDataConverter) {
                    const {data, total} = responseDataConverter(responseData);
                    setData(data);
                    setTotal(total);
                } else {
                    setData(responseData.content || []);
                    setTotal(responseData.totalElements || 0);
                }

                setLoading(false);
            })
            .catch(e => {
                showError('Не удалось загрузить данные для таблицы', e);
                setLoading(false);
            });

    }, [requestParamsConverter, responseDataConverter, loadDataFn]);

    const delayedSearch = useCallback(
        debounce((params) => loadData(params), 300),
        [loadData]
    );


    const reloadTable = () => {
        loadData({filters: selectedFilters, pagination});
    }

    const doSearch = ({filters: formFilters = null, pagination: selectedPagination = null}: {
        filters?: any,
        pagination?: IPagination | null
    }) => {
        loadData({filters: formFilters || selectedFilters, pagination: selectedPagination || pagination});
    }

    const resetTable = () => {
        // @ts-ignore
        if (filterFormRef.current && filterFormRef.current.clearValues) {
            // @ts-ignore
            filterFormRef.current.clearValues();
        }
    }

    const getFilters = () => {
        // @ts-ignore
        if (filterFormRef.current && filterFormRef.current.getSelectedFilters) {
            // @ts-ignore
            return filterFormRef.current.getSelectedFilters();
        } else {
            return {};
        }
    }

    const setFilterValues = (newFilters:any) => {
        // @ts-ignore
        if (localTableRef.current && localTableRef.current.setFilterValues) {
            // @ts-ignore
            localTableRef.current.setFilterValues(newFilters);
        }
    }

    useImperativeHandle(ref, () => ({
        reloadTable,
        resetTable,
        getFilters,
        setFilterValues,
    }), [reloadTable, resetTable, getFilters,setFilterValues]);

    useEffect(() => {
        delayedSearch({filters: selectedFilters, pagination});
    }, [JSON.stringify(selectedFilters), pagination.pageSize, pagination.pageNum]);

    return (
            <LocalTable
                ref={localTableRef}
                toolbar={toolbar}
                className='remote-table'
                filters={filters}
                onChangeFilters={onChangeFilters}
                columns={columns}
                dataSource={data}
                doSearch={doSearch}
                loading={loading}
                onChangePagination={onPaginationChange}
                pageNum={pagination.pageNum}
                pageSize={pagination.pageSize}
                total={total}
                {...tableProps}
            />
    )
});

