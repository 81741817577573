import React, {useCallback, useEffect, useImperativeHandle, useState} from "react";
import {Button, Divider, Image, Popconfirm, Typography} from 'antd';
import {useLoading} from "../../../hooks/use-loading";
import {File, FileResponse, FileService} from "../../../backend/services/printdown";
import {showError} from "../../../utils/notifications";
import {DeleteOutlined, FileDoneOutlined, DownloadOutlined} from "@ant-design/icons";
import {FileUpload} from "../../file-upload";
import {RcFile} from "antd/es/upload";
import {ActionFinishCallback} from "../../../utils/types";
import {uploadFilesToDocument} from "../../../utils/services";
import './styles.scss';
import Loading from "../../loading";

export const getImageUrl = (content: string | string[] = []) => content ? `data:image/jpeg;base64,${content}` : '';

interface FileListProps {
    documentId?: number,
    documentTypeCode: string,
    beforeUpload?: (onFinish: (props: { documentId: number, isSuccess: boolean }) => void) => void
}

export const FileList = React.forwardRef(({documentId = 0, documentTypeCode, beforeUpload}: FileListProps, ref) => {
    const [loading, showLoading, hideLoading] = useLoading();
    const [files, setFiles] = useState<File[]>([]);
    //todo брать файлы из стора

    const loadFiles = useCallback((newDocumentId?: number) => {
        if (!documentId && !newDocumentId) {
            return;
        }
        showLoading();
        FileService.getFile({
            documentId: newDocumentId || documentId
        })
            .then((loadedFiles: any) => {
                hideLoading();
                setFiles(loadedFiles);
            })
            .catch((e: any) => {
                showError('Не удалось загрузить файлы документа', e);
                hideLoading();
            })
    }, [documentId]);

    const downloadFile = useCallback((file: FileResponse) => {
        const downloadUrl = `data:${file.contentType},${file.content}`;
        const link = document.createElement('a');
        link.href = downloadUrl;

        link.setAttribute('download', file.filename || 'file.txt');
        document.body.appendChild(link);
        link.click();
    }, []);

    const uploadFile = useCallback((documentId: number, docType: string, files: RcFile[], onFinish?: ActionFinishCallback) => {
        showLoading();
        const formData = new FormData();
        formData.append('file', files[0]);
        uploadFilesToDocument(documentId, docType, files, (isSuccess) => {
            hideLoading();
            if (onFinish) {
                onFinish(isSuccess);
            }
            loadFiles(documentId);
        });
    }, [loadFiles]);

    const onUploadBtnClick = useCallback((documentId: number, docType: string, files: RcFile[], onFinish?: ActionFinishCallback) => {
        if (!documentId && beforeUpload) {
            beforeUpload(({documentId: createdDocumentId, isSuccess: isSuccessBeforeUpload}) => {
                if (createdDocumentId > 0) {
                    uploadFile(createdDocumentId, docType, files, onFinish);
                } else if (onFinish) {
                    onFinish(false);
                }

            })
        } else {
            uploadFile(documentId, docType, files, onFinish);
        }
    }, [uploadFile])

    const deleteFile = useCallback((fileId: number) => {
        if (!fileId) {
            return;
        }
        showLoading();
        FileService.deleteFile({
            fileId
        })
            .then(() => {
                hideLoading();
                loadFiles();
            })
            .catch((e: any) => {
                showError('Не удалось удалить файл', e);
                hideLoading();
            })
    }, [loadFiles]);

    useImperativeHandle(ref, () => ({
        loadFiles
    }), [loadFiles]);

    useEffect(() => {
        loadFiles();
    }, []);

    return (
        <div className='document-file-list'>
            <Typography.Text strong>Фото документа</Typography.Text>
            <FileUpload
                // selectText='добавить файл'
                // @ts-ignore
                doUpload={(files: RcFile[], onFinish: ActionFinishCallback) => {
                    onUploadBtnClick(documentId || 0, documentTypeCode || '', files, onFinish);
                }}/>

            <div className='loaded-files' style={{minHeight: 64, minWidth: 64}}>
                {loading && <Loading/>}
                {
                    // @ts-ignore
                    files.map((file) => <div
                        key={file.s3ObjectKey}>
                        <div className='file-wrapper'>
                            <div className='image-container'>
                                {file.contentType?.startsWith('image/') ? <Image
                                    height={72}
                                    width={72}
                                    style={{objectFit: 'cover'}}
                                    // @ts-ignore
                                    src={getImageUrl(file.content)}
                                /> : <FileDoneOutlined/>}
                            </div>
                            <div className='file-name'>{file.filename}</div>
                            <div className='buttons'>
                                <Button size='small' className='download-btn' onClick={() => {
                                    // @ts-ignore
                                    downloadFile(file);
                                }}><DownloadOutlined /></Button>
                                <Popconfirm title='Удалить файл?' onConfirm={
                                    () => {
                                        deleteFile(file.fileId || 0)
                                    }
                                }>
                                    <Button size='small' className='delete-btn'><DeleteOutlined/></Button>
                                </Popconfirm>
                            </div>
                        </div>
                        <Divider style={{margin: '6px 0'}}/>
                    </div>)}
            </div>
        </div>
    )
});
