import {ActionFinishCallback} from "./types";
import {RcFile} from "antd/es/upload";
import printdownBackend from "../backend/axios";
import {showError, showMessage} from "./notifications";

export const uploadFilesToDocument = (documentId: number, docType: string, files: RcFile[], onFinish?: ActionFinishCallback) => {
   //todo отправлять все файлы
    const formData = new FormData();
    formData.append('file', files[0]);
    printdownBackend.post(`/documents/${documentId}/files`, formData, {
        params: {
            type: docType
        }
    })
        .then(resp => {
            showMessage('Файл загружен');
            if (onFinish) {
                onFinish(true);
            }
        })
        .catch(e => {
            showError('Не удалось загрузить файлы', e);
            if (onFinish) {
                onFinish(false);
            }
        });
}
