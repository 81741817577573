import React, {useCallback, useEffect, useMemo, useState} from "react";
import {
    ContractorResponse,
    ContractorService,
    DocumentResponse,
    EnumContractorType
} from "../../../../backend/services/printdown";
import {Button} from "antd";
import {getRandomId} from "../../../../utils/utils";
import {CONTRACTOR_FIELDS, ContractorForm} from "./contractor-form";
import {useLoading} from "../../../../hooks/use-loading";
import {showError, showMessage} from "../../../../utils/notifications";
import Loading from "../../../../components/loading";
import './style.scss';
import {CheckOutlined, CloseOutlined, EditOutlined, LoadingOutlined} from "@ant-design/icons";
import {ContractorPrintRequisites} from "./print-requisites";

export const isValidContractor = (values: any) => CONTRACTOR_FIELDS
    .every(({dataIndex, required = true}) => !required || !!values[dataIndex]);


export const ContractorMainInfo = ({contractorId, allDocs, onLoadContractor}: { contractorId: number, allDocs: DocumentResponse[],onLoadContractor : (value: ContractorResponse) => void }) => {
    const [loading, showLoading, hideLoading] = useLoading();
    const [isEdit, startEdit, cancelEdit] = useLoading();
    const [isValidForm, setFormValid] = useState(false);
    const [prevValues, setPrevValues] = useState<ContractorResponse>({id: contractorId});
    const [values, setValues] = useState<ContractorResponse>({
        id: contractorId
    });

    const valuesChangeId = useMemo(() => getRandomId(), [
        values.name, values.type, values.email, values.phone,
        values.description, values.telegram
    ]);

    const loadContractor = useCallback(() => {
        showLoading();

        ContractorService.getContractorById({
            contractorId
        })
            .then(data => {
                setValues({...data});
                setPrevValues({...data});
                hideLoading();
                cancelEdit();
                setFormValid(isValidContractor(data));
                onLoadContractor({...data});
            })
            .catch(e => {
                hideLoading();
                showError('Не удалось загрузить данные подрядчика', e);
            });
    }, [contractorId]);

    const saveChanges = useCallback(() => {
        showLoading();
        const {id, ...dataToSave} = values;
        ContractorService.updateContractor({
            contractorId,
            body: dataToSave
        })
            .then(resp => {
                hideLoading();
                showMessage('Данные подрядчика сохранены');
                loadContractor();
            })
            .catch(e => {
                hideLoading();
                showError('Не удалось загрузить данные подрядчика', e);
            })
    }, [valuesChangeId, loadContractor]);

    useEffect(() => {
        loadContractor();
    }, []);

    return (
        <div className='contractor-main-details'>
            {loading ? <Loading/> :
                <div className='contractor-main-info'>
                    <div className='contractor-main-form'>
                        <div className='buttons'>
                            {!isEdit &&
                                <Button type='link' style={{padding: 0}}
                                        onClick={startEdit}><EditOutlined/>Редактировать</Button>}
                            {isEdit && <>
                                <Button type='link' style={{marginRight: 16, padding: 0}} disabled={!isValidForm}
                                        onClick={saveChanges}>{loading ? <LoadingOutlined/> :
                                    <CheckOutlined/>} Сохранить изменения</Button>
                                <Button type='link' style={{padding: 0}}
                                        onClick={cancelEdit}><CloseOutlined/> Отмена</Button>
                            </>}
                        </div>

                        <ContractorForm
                            readOnly={!isEdit}
                            contractor={values}
                            onChange={({values: newValues, isValid}) => {
                                setValues(newValues);
                                setFormValid(isValid)
                            }}/>
                    </div>
                    {(values.type === EnumContractorType.INDIVIDUAL || values.type === EnumContractorType.SELF_EMPLOYED || values.type === EnumContractorType.PHYSICAL) && (
                        <ContractorPrintRequisites contractor={prevValues} documents={allDocs}/>
                    )}
                </div>
            }
        </div>

    )
}
