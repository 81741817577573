import {useCallback, useMemo, useState} from 'react';
import {Provider, useSelector} from 'react-redux';
import {Button, Col, Input, Row} from 'antd';
import {LoadingOutlined, SaveOutlined, StarTwoTone} from '@ant-design/icons';
import dayjs from 'dayjs';

import {Company, CompanyRequest, CompanyService} from 'backend/services/printdown';
import Loading from 'components/loading';
import {modal} from "global/NotificationsProvider";

import store from 'store';
import {getWorkspaceId} from "store/reducers/auth/selectors";
import {useLoading} from 'hooks/use-loading';
import {ACCOUNT_REGEX, IS_DEBUG} from 'utils/constants';
import {showError, showMessage} from 'utils/notifications';
import {getRandomId} from 'utils/utils';
import styles from './styles.module.scss';

interface CompanyModalProps {
    company: Company;
    onClose: () => void;
    onFinish: (isSuccess: boolean) => void;
}


const getTestCompany = (): Company => {
    const dateTime = dayjs().format('HH:mm_DD.MM');
    return {
        name: `Организация ${dateTime}`,
        address: 'Белгород, ул. Зелёная',
        inn: '1111111',
        kpp: '2222222',
        ogrn: '333333',
        bankBik: '44444444',
        bankAccount: '1111 2222 3333 4444 5555',
        bankName: 'Альфа-Банк',
        bankCorrespondentAccount: '9999 8888 7777 6666 5555',
        phone: '+7 (499) 111 11 11',
        email: '123@mail.ru',
        owner: `Тестовый_${dateTime} Владелец`
    }
}

const COMPANY_FIELDS: Array<{
    dataIndex: keyof Company,
    title: string,
    required?: boolean,
    span?: number,
    regex?: RegExp
}> = [
    {dataIndex: 'name', title: 'Наименование', required: true},
    {dataIndex: 'address', title: 'Адрес'},
    {dataIndex: 'inn', title: 'ИНН', required: true, span: 8},
    {dataIndex: 'kpp', title: 'КПП', span: 8},
    {dataIndex: 'ogrn', title: 'ОГРН', span: 8},
    {dataIndex: 'bankAccount', title: 'Расч. счёт', span: 8, regex: ACCOUNT_REGEX},
    {dataIndex: 'bankBik', title: 'БИК', span: 8},
    {dataIndex: 'bankCorrespondentAccount', title: 'Корр. счёт', span: 8, regex: ACCOUNT_REGEX},
    {dataIndex: 'bankName', title: 'Наименование банка'},
    {dataIndex: 'phone', title: 'Телефон', span: 6},
    {dataIndex: 'email', title: 'Email', span: 8},
    {dataIndex: 'owner', title: 'Директор', span: 10}
]

const CompanyEditForm = ({onFinish, onClose, company = {id: 0}}: CompanyModalProps) => {
  const workspaceId = useSelector(getWorkspaceId);
    const [values, setValues] = useState<Company>(company);
    const [loading, showLoading, hideLoading] = useLoading();

    const isEdit = !!company.id;

    const valuesChangeId = useMemo(() => getRandomId(), [
        values.name, values.email, values.owner, values.phone, values.ogrn,
        values.address, values.inn, values.kpp, values.bankAccount,
        values.bankBik, values.bankName, values.bankCorrespondentAccount
    ])

    const isValidForm = useMemo(() => {
        return COMPANY_FIELDS.every(({dataIndex, required = false}) => !required || !!values[dataIndex])
    }, [valuesChangeId]);

    const fillAsTest = useCallback(() => {
        setValues(getTestCompany());
    }, []);


    const editCompany = useCallback(() => {
        showLoading();
        const {id, ...newCompany} = values;
        const requestData = newCompany as CompanyRequest;
        // requestData.correspondentAccount = newCompany.bankCorrespondentAccount;

        CompanyService.updateCompany({
            companyId: company.id || -1,
            body: requestData
        })
            .then(() => {
                hideLoading();
                onClose();
                showMessage('Изменения сохранены');
                onFinish(true);
            })
            .catch(e => {
                hideLoading();
                showError('Не удалось сохранить изменения организации', e);
                onFinish(false);
            })
    }, [valuesChangeId]);

    const createNewCompany = useCallback(() => {
        showLoading();
        const {id, ...newCompany} = values;
        const requestData = newCompany as CompanyRequest;
        // requestData.correspondentAccount = newCompany.bankCorrespondentAccount;

        CompanyService.createCompany({
            workspaceId,
            body: requestData
        })
            .then(() => {
                hideLoading();
                onClose();
                showMessage('Организация создана');
                onFinish(true);
            })
            .catch(e => {
                hideLoading();
                showError('Не удалось создать организацию', e);
                onFinish(false);
            })
    }, [valuesChangeId]);


    return <div className={`company-form ${!isValidForm ? 'invalid' : ''}`}>
        {loading && <Loading/>}
        <Row gutter={8}>
            {COMPANY_FIELDS.map(({dataIndex, title, span = 24, required = false, regex = undefined}) => {
                    const value = values[dataIndex];
                    const isValidRegex = !regex || regex.test(String(value));
                    const isValidValue = required ? isValidRegex : (!value || isValidRegex);
                    return <Col span={span} key={dataIndex}>
                        <div
                            className={`field ${dataIndex} ${required ? 'required' : ''} ${!isValidValue ? 'invalid' : ''}`}>
                            <div className='field-label'>{title}:</div>
                            {dataIndex === 'address' ? <Input.TextArea
                                    rows={2}
                                    value={(value || '') as string}
                                    onChange={({target: {value: newValue}}) => {
                                        setValues(prev => ({
                                            ...prev,
                                            [dataIndex]: newValue
                                        }))
                                    }}
                                /> :
                                <Input
                                    allowClear
                                    value={(value || '') as string}
                                    onChange={({target: {value: newValue}}) => {
                                        setValues(prev => ({
                                            ...prev,
                                            [dataIndex]: newValue
                                        }))
                                    }}
                                />
                            }
                        </div>
                    </Col>
                }
            )}
        </Row>
        <div className='modal-buttons'>
            {
                IS_DEBUG && <Button
                    onClick={fillAsTest}
                ><StarTwoTone twoToneColor='magenta'/></Button>
            }
            <Button
                type='primary'
                disabled={!isValidForm}
                onClick={isEdit ? editCompany : createNewCompany}
            >
                {loading ? <LoadingOutlined/> : <SaveOutlined/>}
                {isEdit ? 'Сохранить изменения' : 'Создать организацию'}</Button>
            <Button onClick={onClose}>Отмена</Button>
        </div>
    </div>
}

export const showCompanyEditModal = ({onFinish, company = {id: 0}}: {
    onFinish: (isSuccess: boolean) => void,

    company?: Company
}) => {
    let modalCmp: any = null;

    const closeModal = () => {
        modalCmp?.destroy();
    }

    modalCmp = modal.confirm({
        width: 860,
        closable: true,
        className: `${styles.company_edit_modal} modal-no-btns`,
        title: company.id ? company.name : 'Новая организация',
        content:
            // <ConfigProvider {...AppConfig}>
                <Provider store={store}>
                    <CompanyEditForm
                        company={company}
                        onFinish={onFinish}
                        onClose={closeModal}
                    />
                </Provider>
            // </ConfigProvider>
    });
};
