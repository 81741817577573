import Icon from '@ant-design/icons';
import React from 'react';


const SnilsSVG = () => <svg width="1em" height="1em" viewBox="0 0 1000 1000" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
	<path fillRule="evenodd" clipRule="evenodd" d="M0 748.593C0 805.703 46.297 852 103.407 852H896.593C953.703 852 1000 805.703 1000 748.593V251.407C1000 194.297 953.703 148 896.593 148H103.407C46.297 148 0 194.297 0 251.407V748.593ZM707.241 212H103.407C81.643 212 64 229.643 64 251.407V748.593C64 770.357 81.643 788 103.407 788H707.241V714.298C655.464 700.225 617.345 652.855 617.345 596.653C617.345 540.45 655.464 493.08 707.241 479.008V212ZM771.241 212V479.008C823.019 493.08 861.137 540.45 861.137 596.653C861.137 652.855 823.019 700.225 771.241 714.298V788H896.593C918.357 788 936 770.357 936 748.593V251.407C936 229.643 918.357 212 896.593 212H771.241ZM126.42 725.502H471.973V661.502H126.42V725.502ZM739.241 538.757C771.195 538.757 797.137 564.699 797.137 596.653C797.137 628.606 771.195 654.549 739.241 654.549C707.288 654.549 681.345 628.606 681.345 596.653C681.345 564.699 707.288 538.757 739.241 538.757ZM372.057 609.938H543.293V545.938H372.057V609.938ZM127.058 609.938H319.677V545.938H127.058V609.938ZM288.783 421.075C277.63 432.43 262.173 439.493 245.09 439.493C210.995 439.493 183.444 411.445 183.444 377.057C183.444 342.668 210.995 314.62 245.09 314.62C262.173 314.62 277.63 321.683 288.783 333.039L331.951 290.641C309.787 268.074 279.039 254.113 245.09 254.113C177.744 254.113 122.938 309.131 122.938 377.057C122.938 444.982 177.744 500 245.09 500C279.039 500 309.787 486.039 331.951 463.473L288.783 421.075Z" fill="currentColor"/>
</svg>


export const SnilsIcon = (props: any) => <Icon className='inn-icon' component={SnilsSVG} {...props} />;


