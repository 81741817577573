import FormField from "./form-field";
import {Dayjs} from "dayjs";
import {GENDER_OPTIONS, PASSPORT_SERIES_NUMBER_REGEX} from "../../../../utils/constants";
import React from "react";
import {PassportDocValues} from "../types";

export const PassportFields = ({values, fieldProps}: { values: PassportDocValues, fieldProps: any }) => (
    <div className='form'>
        <FormField
            name='number'
            maxLength={12}
            regex={PASSPORT_SERIES_NUMBER_REGEX}
            label='Серия и номер'
            hint='Серия и номер паспорта через пробел'
            {...fieldProps}
            value={values.number || ''}
        />
        <FormField
            rows={2}
            name='issue'
            label='Кем выдан'
            {...fieldProps}
            value={values.issue || ''}
        />
        <FormField
            type='date'
            name='issueDate'
            label='Дата выдачи'
            {...fieldProps}
            value={values.issueDate as Dayjs}
        />
        <FormField
            label='Код подразд. '
            placeholder='Код подразделения'
            maxLength={7}
            // regex={NUMBER_REGEX}
            name='unitCode'
            {...fieldProps}
            value={values.unitCode as string}
        />
        <div className='fio'>
            <FormField
                label='Фамилия'
                name='lastName'
                {...fieldProps}
                value={values.lastName as string}
            />
            <FormField
                label='Имя'
                name='firstName'
                {...fieldProps}
                value={values.firstName as string}
            />
            <FormField
                label='Отчество'
                name='middleName'
                allowEmpty
                {...fieldProps}
                required={false}
                value={values.middleName as string}
            />
        </div>
        <FormField
            type='select'
            name='sex'
            label='Пол'
            options={GENDER_OPTIONS}
            {...fieldProps}
            value={values.sex as string}
            required={false}
        />
        <FormField
            type='date'
            name='birthDate'
            label='Дата рождения'
            {...fieldProps}
            required={false}
            /*@ts-ignore*/
            value={values.birthDate as Dayjs}

        />
        <FormField
            rows={2}
            name='birthPlace'
            label='Место рождения'
            {...fieldProps}
            required={false}
            value={values.birthPlace as string}
        />
        <FormField
            rows={2}
            name='address'
            label='Адрес регистрации'
            {...fieldProps}
            value={values.address as string}
        />
    </div>
)
