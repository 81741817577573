import React, {useCallback, useMemo, useState} from 'react';
import {Provider, useSelector} from 'react-redux';
import {Button, Modal, Radio, Select} from 'antd';
import {LoadingOutlined, SaveOutlined} from '@ant-design/icons';
import {Company, CompanyService, ContractorResponse, ContractorService} from 'backend/services/printdown';
import Loading from 'components/loading';
import {useLoading} from 'hooks/use-loading';
import store, {StoreState} from 'store';
import {getCurrentUserId, getWorkspaceId} from 'store/reducers/auth/selectors';
import {showError, showMessage} from 'utils/notifications';
import {filterOption, getRandomId} from 'utils/utils';
import {ContractorForm} from 'views/contractors/details/main-info/contractor-form';
import './styles.scss';
import {useRemoteData} from "../../../../../hooks/use-remote-data";

interface AddContractorModalProps {
    onClose: () => void;
    onFinish: (isSuccess: boolean) => void;
    projectId: number,
}

export const ProjectContractorAddForm = ({onFinish, onClose, projectId}: AddContractorModalProps) => {
    const userId = useSelector(getCurrentUserId);
    const workspaceId = useSelector(getWorkspaceId);
    const {all: availableRoles = []} = useSelector((state: StoreState) => state.roles);
    const {data: allContractors, loading: isLoadingContractors} = useRemoteData<ContractorResponse>({
        // @ts-ignore
        loader: ContractorService.findBy1,
        searchParams: {workspaceId}
    })
    const [contractorUseType, setContractorUseType] = useState('new');

    const [loading, showLoading, hideLoading] = useLoading();
    const [selectedContractorId, setSelectedContractorId] = useState(0);
    const [roleId, setRoleId] = useState<number>(0)
    const [values, setValues] = useState<ContractorResponse>({
        id: 0
    });
    const [isValidForm, setValidForm] = useState(false);


    const valuesChangeId = useMemo(() => getRandomId(), [
        roleId, selectedContractorId, values.email, values.telegram,
        values.description, values.type, values.name, values.phone
    ])
    const contractorOptions = useMemo(() => allContractors
        .sort(({name: nameA = ''}, {name: nameB = ''}) => nameA.localeCompare(nameB))
        .map(({id, name}) => <Select.Option
            key={id}
            value={id}
        >{name}</Select.Option>), [allContractors.length]);

    const roleOptions = useMemo(() => availableRoles.map(({id, name}) => <Select.Option
        key={id}
        value={id}
    >{name}</Select.Option>), [availableRoles.length]);

    const editAttachedContractor = useCallback(() => {
        const {id, ...newContractor} = values;
        showLoading();
        const promise = contractorUseType === 'exist' ? ContractorService.attachContractor({
            projectId,
            contractorId: selectedContractorId,
            roleId
        }) : ContractorService.addContractor({
            projectId: projectId || 0,
            body: {
                ...newContractor,
                userId,
                roleId
            }
        })

        promise.then(() => {
            hideLoading();
            onClose();
            showMessage('Подрядчик добавлен в проект');
            onFinish(true);
        })
            .catch(e => {
                hideLoading();
                showError('Не удалось добавить подрядчика в проект', e);
                onFinish(false);
            })
    }, [valuesChangeId, selectedContractorId, contractorUseType]);

    return <div className={`contractor-form ${!roleId ? 'invalid' : ''}`}>
        {loading && <Loading/>}
        <div
            className='field roleId'
            key='roleId'
        >
            <div className='field-label'>Роль в проекте:</div>
            <Select
                className='contractor-select'
                value={roleId || null}
                onChange={setRoleId}
                showSearch
                filterOption={filterOption}
                popupMatchSelectWidth={false}
            >{roleOptions}</Select>
        </div>

        <Radio.Group
            style={{margin: '12px 0'}}
            value={contractorUseType}
            optionType='button'
            // buttonStyle='solid'
            onChange={({target: {value}}) => {
                setContractorUseType(value);
            }}
            options={[
                {label: 'Добавить нового', value: 'new'},
                {label: 'Выбрать из списка', value: 'exist'},
            ]}
        />
        {contractorUseType === 'new' ? (
            <div className='new-contractor-form'>
                <ContractorForm
                    onChange={({values: newContractor, isValid}) => {
                        setValues(newContractor as ContractorResponse);
                        setValidForm(isValid);
                    }}
                />
            </div>
        ) : <Select
            allowClear
            showSearch
            filterOption={filterOption}
            popupMatchSelectWidth={false}
            placeholder='Поиск подрядчиков'
            className='contractor-select'
            value={selectedContractorId || null}
            onChange={(contractorId) => {
                setSelectedContractorId(contractorId);
            }}
        >{contractorOptions}</Select>}
        <div className='modal-buttons'>
            <Button
                type='primary'
                disabled={!(roleId && (selectedContractorId || isValidForm))}
                onClick={editAttachedContractor}
            >
                {loading ? <LoadingOutlined/> : <SaveOutlined/>}
                Добавить подрядчика
            </Button>
            <Button onClick={onClose}>Отмена</Button>
        </div>
    </div>
}
