import React, {useContext } from 'react';
import Loading from 'components/loading';
// import AuthContext from 'context/auth-context';
// import LoginView from 'views/login';
import { Result } from 'antd';
import { useSelector } from 'react-redux';
import { StoreState } from 'store';
import Spin from 'antd/lib/spin';
import {userHasPermissions} from "../utils";
import AccessDeniedPage from 'views/auth/access-denied-page';
import LoginView from 'views/auth/login';
import {PermissionsConfig} from "../../utils/types";


const PrivatePage = ({  children, permissions = undefined, path = '' }: { children: React.ReactNode, path?: string,permissions?: PermissionsConfig }): JSX.Element => {
	const {isLoggingIn,isCheckingToken, isUserLoggedIn, user} = useSelector((state: StoreState) => state.auth);

	let element = <Loading />;

	if (!isCheckingToken) {
		if (!isUserLoggedIn) {
			element =  <LoginView />;
		}
		else {
			if (permissions) {

				const userHasAllRequiredRoles = userHasPermissions(permissions, user.roles);
				// @ts-ignore
				element = userHasAllRequiredRoles ? children : <AccessDeniedPage />
			}
			else {
				// @ts-ignore
				element = children
			}
		}
	}

	return element
}


export default PrivatePage;
